import { Stack } from "@mui/material";
import { AgentType } from "constants/auth.types";
import ButtonLink from "components/links/ButtonLink";
import { hasPermission } from "../../../../permissions";
import { AgentPermissionsEnum } from "constants/permissions.enums";
import PermissionDenied from "components/PermissionDenied";
import { PageHeader, PageSection, PageSubtitle, PageTitle } from "components/page/PageHeader";
import SettingsIcon from "@mui/icons-material/Settings";

const AgentSettings = ({ agent }: { agent: AgentType }) => {
  if (!hasPermission(agent, AgentPermissionsEnum.BROWSE_SETTINGS))
    return <PermissionDenied />;

  return (
    <>
      <PageHeader>
        <PageTitle
          text="Agent Settings"
          Icon={SettingsIcon}
        />
        <PageSubtitle text="Manage settings for this agent." />
      </PageHeader>

      <PageSection>
        <Stack direction="row" spacing={2}>
          <ButtonLink to={`/agent/${agent.id}/settings/access`}>Manage Access</ButtonLink>
          <ButtonLink
            to={`/auth/scope?account=${agent.account_id}&next=/account/${agent.account_id}/dashboard`}
          >
            Manage Account
          </ButtonLink>
          <ButtonLink
            to={`/agent/${agent.id}/settings/email-signatures`}
          >
            Email Signatures
          </ButtonLink>
        </Stack>
      </PageSection>
    </>
  );
};

export default AgentSettings;
