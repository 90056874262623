import { Button } from "@mui/material"
import ApiActionButton from "components/api-action-button/ApiActionButton";
import { ConfirmDangerDialogue } from "components/api-action-button/ConfirmDialog";
import { FactLabel, FactTextValue, ActionableSectionTitle } from "components/CardFact";
import PageNotFound from "components/errors/PageNotFound";
import ButtonLink from "components/links/ButtonLink";
import { MessageTemplatePermissionsEnum } from "constants/msg.enums";
import { MessagePreviewType, MessageTemplateType, MessageTemplateWithUsageType } from "constants/msg.types";
import { useDestroyOne, useGetOnce } from "hooks/api-hooks";
import { hasPermission } from "permissions";
import { createSearchParams, useNavigate } from "react-router-dom";

import { ActionablePageTitle, PageHeader, PageSection, PageSubtitle } from "components/page/PageHeader";
import EmailIcon from "@mui/icons-material/EmailOutlined"
import { useMessageBreadcrumbs } from "../../message-breadcrumbs";
import { AgentType } from "constants/auth.types";
import { LocaleDate } from "components/locale";
import { ActionItem } from "components/page/ActionMenu";


const MessageTemplateDeleteButton = ({ templateId, onDeleted, disabled }: { templateId: number, onDeleted: () => void, disabled: boolean }) => {

  const [destroy, destroying] = useDestroyOne(`message_template/${templateId}`);

  return (
    <ApiActionButton
      successMsg="Template deleted."
      onClick={destroy}
      loading={destroying}
      onSuccess={onDeleted}
      renderConfirmDialog={ConfirmDangerDialogue}
      renderButton={({ loading, ...props }) => (
        <Button sx={{ mr: 2 }} disabled={disabled} color="error" {...props}>
          {loading ? "Deleting..." : "Delete"}
        </Button>
      )}
    />
  )
}

const MessageTemplate = ({ template, onDeleted }: { template: MessageTemplateWithUsageType, onDeleted: () => void }) => {
  return (
    <>
      <FactLabel>Template Name:</FactLabel>
      <FactTextValue>{template.label}</FactTextValue>

      <FactLabel>Updated at:</FactLabel>
      <FactTextValue><LocaleDate value={template.updated_at} /></FactTextValue>

      <FactLabel>Usage Count:</FactLabel>
      <FactTextValue>{template.usage_count}</FactTextValue>

      <MessageTemplateDeleteButton key="delete" disabled={!hasPermission(template, MessageTemplatePermissionsEnum.DELETE)} templateId={template.id} onDeleted={onDeleted} />
    </>
  )
}

const MessageTemplatePreview = ({ template }: { template: MessageTemplateType }) => {
  const [preview, loading] = useGetOnce<MessagePreviewType>(
    `/message_template/${template.id}/preview`
  );


  return (
    <PageSection loading={loading}>
      {preview && (
        <section dangerouslySetInnerHTML={{ __html: preview.rendered.body }} />
      )}
      {!loading && !preview  && (
        "Unable to fetch preview"
      )}
    </PageSection>
  )
}

const useActions = (agentId: number, template: MessageTemplateType | null): ActionItem[] => {
  return [
    {
      id: 'clone',
      onClick: template ? `/agent/${agentId}/messages/templates/new?${createSearchParams({ cloneFromTemplateId: template.id.toString() })}` : '',
      text: 'Duplicate',
      disabled: !hasPermission(template, MessageTemplatePermissionsEnum.EDIT)
    }
  ]
}


const MessageTemplateRoute = ({ agent, messageTemplateId }: { agent: AgentType, messageTemplateId: number }) => {

  const [template, loading, error] = useGetOnce<MessageTemplateWithUsageType>(`message_template/${messageTemplateId}`)

  const navigate = useNavigate();

  const handleDeleted = () => {
    navigate('../..')
  };

  const trail = useMessageBreadcrumbs(agent.id);

  const actions = useActions(agent.id, template);

  if (error) return <PageNotFound />

  return (
    <>
      <PageHeader breadcrumbTrail={trail}>
        <ActionablePageTitle
          actions={actions}
          Icon={EmailIcon}
          defaultText="Message Template"
          text={template?.label}
        />
        <PageSubtitle text="Customise the look and feel of this email template" />
      </PageHeader>

      <PageSection loading={loading}>
        {template && <MessageTemplate template={template} onDeleted={handleDeleted} />}
      </PageSection>

      <PageSection loading={loading}>
        <ActionableSectionTitle
          actions={
            <ButtonLink
              variant="contained"
              key="edit"
              to={`/agent/${agent.id}/messages/templates/${messageTemplateId}/edit`}
              disabled={!hasPermission(template, MessageTemplatePermissionsEnum.EDIT)}
            >Edit</ButtonLink>
          }
        >Preview</ActionableSectionTitle>
        {template && <MessageTemplatePreview template={template} />}
      </PageSection>
    </>
  )
}

export default MessageTemplateRoute;