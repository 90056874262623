
export enum AccountMemberRoleEnum {
  MEMBER = 'account:member',
}


export enum AgentMemberRoleEnum {
  ADMIN = 'agent:admin',
  VIEWER = 'agent:viewer',
}

export const AgentMemberRoleLabels: Record<AgentMemberRoleEnum, string> = {
  [AgentMemberRoleEnum.ADMIN]: 'Agent Admin',
  [AgentMemberRoleEnum.VIEWER]: 'Agent Viewer',
}

export enum LicenseTypeEnum {
  TIER_ONE = 'tier_one',
  TIER_TWO = 'tier_two',
  TIER_THREE = 'tier_three',
}

export const LicenseTypeLabels: Record<LicenseTypeEnum, string> = {
  [LicenseTypeEnum.TIER_ONE]: 'Full Time Agent',
  [LicenseTypeEnum.TIER_TWO]: 'Big Deal Agent',
  [LicenseTypeEnum.TIER_THREE]: 'Team',
}