import { AgentType } from "constants/auth.types";
import { Route, Routes, useParams } from "react-router-dom";
import PageNotFound from "components/errors/PageNotFound";
import EmailTemplateDetails from "./EmailTemplateDetails";
import EmailTemplateEditLayout from "./EmailTemplateEditLayout";
import EmailTemplateEditSettings from "./EmailTemplateEditSettings";

const EmailTemplateRoutes = ({agent} : {agent: AgentType}) => {

  const params = useParams();
  const emailTemplateId = parseInt(params.emailTemplateId as string, 10);

  if (!emailTemplateId) return <PageNotFound />;

  return (
    <Routes>
<Route path="/layout" element={<EmailTemplateEditLayout agentId={agent.id} emailTemplateId={emailTemplateId}/>}/>
<Route path="/settings" element={<EmailTemplateEditSettings agentId={agent.id} emailTemplateId={emailTemplateId}/>}/>
  <Route path="" element={<EmailTemplateDetails agent={agent} emailTemplateId={emailTemplateId}/>}/>
  <Route path="*" element={<PageNotFound/>} />
    </Routes>
  )
}

export default EmailTemplateRoutes;