import { useState } from "react";
import { TextField } from "@mui/material";
import { ValueFieldProps } from "./types";


const formatCentsAsString = (cents: number): string => (cents / 100).toFixed(2);

const stringToCents = (str: string) => {
  try {
    return Math.round(parseFloat(str) * 100);
  } catch {
    return null;
  }
};

// Look for a valid currency amount anywhere in the string
const CURRENCY_REGEX = /([0-9]{0,12}\.?[0-9]{0,2})/;

export const MonetaryAmountField = ({
  value,
  onChange,
  name,
}: ValueFieldProps) => {
  const { int_value = null } = value;
  const [text, setText] = useState<string>(
    int_value !== null ? formatCentsAsString(int_value) : ""
  );

  const handleTextChange = (e: any) => {
    const newText = e.target.value as string;
    let newIntValue = null;

    const result = CURRENCY_REGEX.exec(newText);

    if (result) {
      // Found something that looks like a currency amount
      const substr = result[0];
      // Parse it 
      newIntValue = stringToCents(substr);
      // Update with just the matching parts
      setText(substr);  
    }
    // Always update int value
    onChange({ int_value: newIntValue });
  };

  return (
    <TextField
      color="secondary"
      variant="outlined"
      label="Monetary Amount"
      name={name}
      value={text}
      autoComplete="off"
      onChange={handleTextChange}
    />
  );
};
