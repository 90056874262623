import { Grid, Button} from "@mui/material";
import { useFormCreate, useGetOnce } from "hooks/api-hooks";
import { Form } from "components/form/Form";
import { EmailTemplateWithFilesType } from "constants/msg.types";
import { FieldTypeEnum, FormSubmitHandler } from "components/form/types";
import { FieldConfig } from "components/form/field-config";
import { useNavigate } from "react-router-dom";
import { useEmailTemplateBreadcrumbs } from "./EmailTemplateBreadcrumbs";
import { useState } from "react";
import { PageHeader, PageSection, PageTitle } from "components/page/PageHeader";
import { Paragraph, SectionTitle } from "components/CardFact";
import MailIcon from "@mui/icons-material/EmailOutlined";

const Guidance = () => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const renderTease = () => (
    <Paragraph>
      Customize your email signature to match you branding.
    </Paragraph>
  )

  const renderExpanded = () => (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Paragraph>
          Email signatures are composed of header and footer sections. Header section appears before the main message contents. Footer content appears after the main message contents. Each section may have text or image or both. If you want to show an image, specify the width the image as a percentage of the total width of the email.
        </Paragraph>
      </Grid>
      <Grid item xs={4}>
        <img alt="guidance" src="/email-signature.png" width="100%" />
      </Grid>
    </Grid>
  )


  return (
    <PageSection gutterBottom>
      <SectionTitle>Guidance</SectionTitle>
      {expanded ? renderExpanded() : renderTease()}

      <Button variant="outlined" onClick={() => setExpanded(!expanded)}>{!expanded ? 'Learn More' : 'Okay, got it!'}</Button>
    </PageSection>
  )
}

const getFields = (emailTemplateId: number): FieldConfig[] => [
  {
    name: "header_markdown",
    type: FieldTypeEnum.TEXTAREA,
    label: "Header Signature",
    help: "Optional. Markdown formatting supported",
    required: false,
    rows: 5,
  },
  {
    type: FieldTypeEnum.FILE,
    name: "header_image_file",
    accept: "image/*",
    endpoint: `/email_template/${emailTemplateId}/img/file`,
    label: "Header Image",
    help: "Optional header image",
    required: false,
    maxSizeMB: 20
  },
  {
    name: "header_image_width",
    type: FieldTypeEnum.INPUT,
    label: "Header Image Width (Percent)",
    help: "Enter a width between 0-100%. You can ignore this setting if you did not upload a header image.",
    required: false,
  },
  {
    name: "footer_markdown",
    type: FieldTypeEnum.TEXTAREA,
    label: "Footer Signature",
    help: "Optional. Markdown formatting supported. Leave this field blank if you want a full-width image or do not need a footer.",
    required: false,
    rows: 5,
  },
  {
    type: FieldTypeEnum.FILE,
    name: "footer_image_file",
    accept: "image/*",
    endpoint: `/email_template/${emailTemplateId}/img/file`,
    label: "Footer Image",
    help: "Optional footer image. If supplied, image will render to the left of header.",
    required: false,
    maxSizeMB: 20
  },
  {
    name: "footer_image_width",
    type: FieldTypeEnum.INPUT,
    label: "Footer Image Width (Percent)",
    help: "Enter a width between 0-100%. You can ignore this setting if you did not upload a footer image.",
    required: false,
  },
];

const EmailTemplateEditLayout = ({
  agentId,
  emailTemplateId,
}: {
  agentId: number,
  emailTemplateId: number;
}) => {
  const [emailTemplate, loading] = useGetOnce<EmailTemplateWithFilesType>(
    `/email_template/${emailTemplateId}`
  );

  const trail = useEmailTemplateBreadcrumbs(agentId, emailTemplateId);
  const navigate = useNavigate();
  const [save, saving] = useFormCreate(`/email_template/${emailTemplateId}/layout`);

  const onSubmit: FormSubmitHandler = (params, setFormErrors) => {
    save(params).then(() => navigate('..')).catch(setFormErrors);
  };

  return (
    <>
      <PageHeader breadcrumbTrail={trail}>
        <PageTitle Icon={MailIcon} text="Customise Email Signature" />
      </PageHeader>
      <Guidance />
      <PageSection loading={loading}>
        <SectionTitle>Configure</SectionTitle>
        {emailTemplate && (
          <Form
            fields={getFields(emailTemplateId)}
            onSubmit={onSubmit}
            saving={saving}
            initialValues={{
              header_markdown: emailTemplate.header_markdown,
              header_image_file: emailTemplate.header_image_file,
              header_image_width: `${emailTemplate.header_image_width}`,
              footer_markdown: emailTemplate.footer_markdown,
              footer_image_file: emailTemplate.footer_image_file,
              footer_image_width: `${emailTemplate.footer_image_width}`,
            }}
          />
        )}
      </PageSection>
    </>
  );
};

export default EmailTemplateEditLayout;
