import PermissionDenied from "components/PermissionDenied";
import { AgentType } from "constants/auth.types";
import { AgentPermissionsEnum } from "constants/permissions.enums";
import { hasPermission } from "permissions";
import SentMessagesList from "./SentMessagesList";
import { PageHeader, PageSubtitle, PageTitle } from "components/page/PageHeader";
import EmailIcon from "@mui/icons-material/EmailOutlined"
import { useMessageBreadcrumbs } from "../message-breadcrumbs";



const Sent = ({ agent }: { agent: AgentType }) => {

  const trail = useMessageBreadcrumbs(agent.id);

  if (!hasPermission(agent, AgentPermissionsEnum.BROWSE_MESSAGES)) return <PermissionDenied />

  return (
    <>
      <PageHeader breadcrumbTrail={trail}>
        <PageTitle
          Icon={EmailIcon}
          text="Sent Messages"
        />
        <PageSubtitle text="Browse all messages sent by AgentVA" />
      </PageHeader>

      <SentMessagesList agent={agent} />
    </>
  )
}

export default Sent;