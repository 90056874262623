import { TextField } from "@mui/material";
import { withFormControl } from "./FormComponent";
import { FieldConfigI, FieldProps, FieldTypeEnum } from "./types";

export interface InputFieldConfig extends FieldConfigI {
  type: FieldTypeEnum.INPUT;
  maxLength?: number
}

interface InputFieldProps extends FieldProps {
  value: string;
  placeholder: string;
  onChange: (arg0: Record<string, string>) => void;
  config: InputFieldConfig;
}

const InputField = ({ config, value, onChange, error }: InputFieldProps) => {
  return (
    <TextField
      color="secondary"
      variant="outlined"
      label={config.label}
      name={config.name}
      value={value || ""}
      autoComplete="off"
      placeholder={config.placeholder}
      error={error}
      inputProps={{maxLength: config.maxLength, required: config.required}}
      onChange={(e) => onChange({ [config.name]: e.target.value })}
    />
  );
};

export default withFormControl(InputField);
