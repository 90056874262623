export type FormErrors = Record<string, string[]>;

export interface FieldProps {
  error: boolean;
  onError: (arg0: FormErrors) => void;
}

export enum FieldTypeEnum {
  INPUT = "input",
  FILE = "file",
  PASSWORD = "password",
  TEXTAREA = "textarea",
  TEMPLATE = "template",
  SELECT = "select",
  CHECKBOX = "checkbox",
  RADIO = "radio",
  SEARCHABLE_SELECT = "searchable_select",
  VARIABLE_TYPE = "variable_type",
  DATE = "date",
  TIME = "time",
  EDITOR = "editor"
}

export type FormData = Record<string, any>;

export type SelectOption = {
  key?: string;
  value: string | number;
  disabled?: boolean;
  name: string | JSX.Element;
};

export interface FieldConfigI {
  name: string;
  placeholder?: string | undefined;
  label: string;
  help?: string;
  disabled?: boolean;
  required: boolean;
  sources?: string[];
  default?: any;
  hideIf?: (formData: FormData) => boolean; 
}

export const NULL_SELECTION = "__null__";

export type FormSubmitHandler = (
  values: FormData,
  setFormErrors: (formErrors: FormErrors) => void
) => void | Promise<FormData | void>;
