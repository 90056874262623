import { FormControlLabel, FormLabel, RadioGroup, Radio } from "@mui/material";
import { withFormControl } from "./FormComponent";
import { SelectOption, FieldProps, FieldConfigI, FieldTypeEnum } from "./types";

export interface RadioFieldConfig extends FieldConfigI {
  type: FieldTypeEnum.RADIO;
  options: SelectOption[];
}

interface RadioFieldProps extends FieldProps {
  value: string;
  onChange: (arg0: Record<string, string>) => void;
  config: RadioFieldConfig;
}
const RadioField = ({ value, onChange, config }: RadioFieldProps) => {
  return (
    <>
      <FormLabel htmlFor={config.name}>{config.label}</FormLabel>
      <RadioGroup
        row
        aria-label={config.label}
        name={config.name}
        value={value || ""}
        onChange={(e) => onChange({ [config.name]: e.target.value })}
      >
        {config.options.map((opt) => (
          <FormControlLabel
            key={opt.key || opt.value}
            value={`${opt.value}`}
            control={<Radio />}
            label={opt.name}
          />
        ))}
      </RadioGroup>
    </>
  );
};

export default withFormControl(RadioField);
