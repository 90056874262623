
const PageNotFound = () => {
  return (
    <>
      PageNotFound (Private)
    </>
  )
}

export default PageNotFound;
