import {
  Link as RouterLink,
  useSearchParams,
} from "react-router-dom";
import { useState } from "react";
import Link from "@mui/material/Link";
import InputIcon from "@mui/icons-material/Input";
import { useFormCreate } from "hooks/api-hooks";
import { Form } from "components/form/Form";
import { FieldTypeEnum, FormSubmitHandler } from "components/form/types";
import { FieldConfig } from "components/form/field-config";
import { Grid } from "@mui/material";
import { PageSection } from "components/page/PageHeader";
import { Paragraph, SectionTitle } from "components/CardFact";

const fields: FieldConfig[] = [
  {
    name: "email",
    type: FieldTypeEnum.INPUT,
    label: "Email Address",
    placeholder: "Email Address",
    required: true,
    help: "Enter the email address you normally use to log into your account",
  },
];

const PasswordRecoveryForm = ({ email, onSuccess }: { email: string, onSuccess: (arg0: string) => void }) => {

  const [save, saving] = useFormCreate(`password-recovery`)

  const handleSubmit: FormSubmitHandler = (formData, setFormErrors) => {
    save(formData).then(() => onSuccess(formData.email)).catch(setFormErrors);
  };


  return (
    <Grid container justifyContent="center" width={"100%"}>
      <Grid item xs={12} sm={10}>
        <PageSection>
          <SectionTitle>Password Recovery</SectionTitle>
          <Paragraph>
            Use this form if you need to reset your password.
            Enter the email address associated with your account and we will send you an email with a link to reset your password.
          </Paragraph>
          <Form
            fields={fields}
            onSubmit={handleSubmit}
            saving={saving}
            submitIcon={<InputIcon />}
            submitText="Start Password Recovery"
            initialValues={{ email }}
          />
          <Paragraph>
            Don't have an account?{" "}
            <Link component={RouterLink} to="/buy">
              Sign up here.
            </Link>{" "}
          </Paragraph>
        </PageSection>
      </Grid>
    </Grid>
  );
};

const Recovered = ({ email }: { email: string }) => {
  return (
    <Grid container justifyContent="center" width={"100%"}>
      <Grid item xs={12} sm={10}>
        <PageSection>
          <SectionTitle>Password Recovery</SectionTitle>
          <Paragraph>
            If <strong>{email}</strong> is associated with an AgentVA account you will
            receive a password reset email with instructions for how to reset your password.
          </Paragraph>
        </PageSection>
      </Grid>
    </Grid>
  )
}

const PasswordRecovery = () => {
  const [recovered, setRecovered] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") || "";

  return (
    <>
      {recovered !== null ? <Recovered email={recovered} /> : <PasswordRecoveryForm email={email} onSuccess={setRecovered} />}
    </>
  );
};

export default PasswordRecovery;
