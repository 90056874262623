import useNotify from "../notify";
import CopyIcon from "@mui/icons-material/CopyAll";

export const useCopyToClipboard = () => {
  const { notify } = useNotify();
  const copy = (text: string) => {
    if (text) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            notify({ severity: "success", msg: "Copied to clipboard!" });
          })
          .catch(() => {
            notify({
              severity: "error",
              msg: "Unabled to copy to clipboard.",
            });
          });
      } else {
        notify({
          severity: "error",
          msg: "Copy to clipboard not supported on this browser.",
        });
        alert(text);
      }
    }
  };
  return copy;
};

const CopyToClipboard = ({ text }: { text: string }) => {
  const copy = useCopyToClipboard();
  return <CopyIcon onClick={() => copy(text)} />;
};

export default CopyToClipboard;
