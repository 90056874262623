import useAuth from "../../auth";
import {Navigate} from "react-router-dom";
import Error from "components/Error";

export const getHomeRoute = (auth) => {
  if(!auth) return '/';
  if(auth.agent_id) return `/agent/${auth.agent_id}/dashboard`;
  if(auth.account_id) return `/account/${auth.account_id}/dashboard`;
  if(auth.user_id) return `/user/accounts`;
}

const HomeRedirect = () => {

  const {auth} = useAuth();

  const to = getHomeRoute(auth);

  if(to){
    console.info(`Redirecting to home route: ${to}`);
    return <Navigate to={to} replace/>
  }

  return <Error />
};

export default HomeRedirect;