
import { Typography } from "@mui/material";

const Unsubscribe = () => {

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Unsubscribe
      </Typography>
      <Typography variant="body1" gutterBottom>
        Coming soon. In the mean time, contact us at <a href="mailto:support@agentva.ca">support@agentva.ca</a>.
      </Typography>
    </>
  );
};

export default Unsubscribe;