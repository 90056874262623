import { Link } from "@mui/material";
import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";

const TextLink = ({ to, children, state }: { to: string; children: ReactNode, state?: any }) => (
  <Link to={to} component={RouterLink} state={state}>
    {children}
  </Link>
);

export default TextLink;
