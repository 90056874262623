import api from "api";
import { PageResult } from "constants/common.types";
import useThunkReducer, { MergeState, GetState, Dispatch } from "hooks/thunk-reducer";
import { useEffect } from "react";
import { SearchState } from "./search-state";
import { FormErrors } from 'components/form/types';
import {formErrorsFromResponse} from 'components/form/errors';
import { ResultItemId, BulkActionSelection, BulkAction } from "./types";


export type ReducerState = {
  searching: boolean,
  dirty: number,
  results: any[],
  errors: FormErrors,
  selected: ResultItemId[],
  count: number,
  actionSelection: BulkActionSelection | null
}
export type AdvancedSearchDispatch = Dispatch<ReducerState>;

export const initialState: ReducerState = {
  searching: false,
  selected: [],
  results: [],
  count: 0,
  dirty: 0,
  actionSelection: null,
  errors: {}
};


export const useAdvancedSearch = (endpoint: string, searchState: SearchState): [ReducerState, AdvancedSearchDispatch] => {

  const [state, dispatch] = useThunkReducer(initialState);
  const { dirty } = state;

  useEffect(() => {
    // Fetching data for table is triggered as side-effect of state changing
    dispatch(getPage(endpoint, searchState));
  }, [dirty, endpoint, searchState, dispatch]);

  return [state, dispatch];
};

type AdvancedSearchAction<R = void> = (
  mergeState: MergeState<ReducerState>,
  getState: GetState<ReducerState>
) => R;

export const getPage = (endpoint: string, searchState: SearchState): AdvancedSearchAction => async (mergeState, getState) => {
  mergeState({ searching: true });
  try {
    const { results, count } = (await api.get<PageResult<any>>(endpoint, { params: searchState })).data;
    mergeState({ searching: false, results, count, errors: {} });
  } catch(e: any) {
    const errors = formErrorsFromResponse(e.response)
    mergeState({ searching: false, errors })
  }
}

export const selectAll = (): AdvancedSearchAction => (mergeState, getState) => {
  mergeState({
    selected: getState().results.map(r => r.id)
  })
}

export const selectNone = (): AdvancedSearchAction => (mergeState, getState) => {
  mergeState({
    selected: []
  })
}

export const toggleSelection = (id: ResultItemId): AdvancedSearchAction => (mergeState, getState) => {
  // Toggle the selection state of the specified row
  const currentSelection = getState().selected;
  mergeState({
    selected: currentSelection.includes(id) ? currentSelection.filter(v => v !== id) : [...currentSelection, id]
  })
}


export const startBulkAction = (action: BulkAction): AdvancedSearchAction => (mergeState, getState) => {
  const { selected } = getState();
  mergeState({
    actionSelection: {
      action,
      selected: [...selected]
    },
  })
}


export const bulkActionCompleted = (actionedIds: ResultItemId[]): AdvancedSearchAction => (mergeState, getState) => {
  const { selected, dirty } = getState();
  const remainingSelection = selected.filter(id => !actionedIds.includes(id));
  mergeState({
    selected: remainingSelection,
    actionSelection: null,
    // Force a refresh ONLY if all selected items were actioned successfully
    dirty: remainingSelection.length ? dirty : dirty + 1
  })
}


export const refresh = (): AdvancedSearchAction => (mergeState, getState) => {
  mergeState({
    selected: [],
    actionSelection: null,
    dirty: getState().dirty + 1
  })
}

export default useAdvancedSearch;
