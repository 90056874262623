import { Stack, Typography, Box, Link, Paper, LinearProgress } from "@mui/material";
import BreadcrumbTrail from "components/BreadcrumbTrail";
import React, { useState } from "react";

import { SvgIconComponent } from "@mui/icons-material";
import { Trail } from "components/BreadcrumbTrail";
import ActionMenu, { ActionItem } from "./ActionMenu";

type PageTitleProps = {
  text: string | null | undefined,
  defaultText?: string | null,
  Icon?: SvgIconComponent | null,
}

export const PageTitle = ({ text, defaultText = '', Icon = null }: PageTitleProps) => {
  return (
    <Typography sx={{ mt: 1 }} component="div" variant="h5" display="flex" alignItems="center">
      {Icon && <Icon fontSize="large" sx={{ mr: 1 }} />}
      {text || defaultText}
    </Typography>
  )
}

export const ActionablePageTitle = ({
  actions = null,
  ...titleProps
}: PageTitleProps & {
  actions: ActionItem[] | null,
}) => {

  return (
    <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
      <PageTitle {...titleProps} />
      {actions && <ActionMenu popupId="pageActions" actions={actions} />}
    </Stack>
  )
}

export const PageSubtitle = ({
  text,
  defaultText = '',
  extra = undefined,
  expandExtraText = '...'
}: {
  text: string | null | undefined,
  defaultText?: string,
  extra?: React.ReactElement,
  expandExtraText?: string,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <>
      {!expanded && (
        <Typography sx={{ mt: 1 }} component="div" variant="body2">
          {text || defaultText}
          {extra && (
            <>&nbsp;
              <Link href="#" onClick={() => setExpanded(true)} >
                {expandExtraText}
              </Link>
            </>
          )}
        </Typography>
      )}
      {expanded && extra && (
        <Paper sx={{ p: 2, mt: 2, mb: 2 }}>
          {extra}
          <Typography variant="caption" textAlign="right" component="div">
            <Link href="#" onClick={() => setExpanded(false)} sx={{ textDecoration: 'none' }}>Close</Link>
          </Typography>
        </Paper>
      )}
    </>
  )
}


export const PageHeader = ({
  breadcrumbTrail = [],
  children
}: {
  breadcrumbTrail?: Trail,
  children: React.ReactNode
}) => {
  return (
    <Box sx={{ mb: 2 }}>
      {breadcrumbTrail.length > 0 && <BreadcrumbTrail trail={breadcrumbTrail} />}
      {children}
    </Box>
  )
}

export const PageSection = ({ children, loading = false, gutterBottom = true }: { children: React.ReactNode, loading?: boolean, gutterBottom?: boolean }) => {
  const pageSectionSx = { p: 2, mb: gutterBottom ? 2 : undefined };
  return (
    <Paper sx={pageSectionSx}>
      {loading && <LinearProgress />}
      {children}
    </Paper>
  )
}