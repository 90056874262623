import { Alert } from "@mui/material";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { AxiosResponse } from "axios";

type Notification = {
  msg: string;
  severity: "error" | "success";
};

type Notifier = (arg0: Notification) => void;

type ContextType = {
  notifications: Notification[];
  notify: Notifier;
  dismiss: () => void;
};

const NotifyContext = createContext<ContextType>({} as ContextType);

export const NotifyProvider = ({ children }: { children: JSX.Element }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const notify = useCallback((notification: Notification) => {
    setNotifications([notification]);
  }, []);

  const dismiss = useCallback(() => {
    setNotifications([]);
  }, []);

  const memoedValue = useMemo(
    () => ({
      notifications,
      notify,
      dismiss,
    }),
    [notifications, notify, dismiss]
  );

  return (
    <NotifyContext.Provider value={memoedValue}>
      {children}
    </NotifyContext.Provider>
  );
};

const useNotify = () => {
  return useContext(NotifyContext);
};

const ANCHOR: SnackbarOrigin = { horizontal: "center", vertical: "top" };

export const NotificationSnackbar = () => {
  const { notifications, dismiss } = useNotify();

  const handleClose = (e: React.SyntheticEvent | Event, reason?: string) => {
    if (reason !== "clickaway") {
      dismiss();
    }
  };

  const open = notifications.length > 0;

  return (
    <Snackbar
      open={open}
      autoHideDuration={2000}
      onClose={handleClose}
      anchorOrigin={ANCHOR}
    >
      <div>
        {notifications.map(({ severity, msg }, idx) => (
          <Alert key={idx} severity={severity}>
            {msg}
          </Alert>
        ))}
      </div>
    </Snackbar>
  );
};

export const withApiNotify = <T,>(
  notify: Notifier,
  success: string = "Saved!",
  failure: string = "Oops. Something went wrong."
): (arg0: Promise<AxiosResponse<T>>) => Promise<T> => {
  /*
  Wrap an Axios promise chain so that it shows a success or error
  notification based on response.
  */

  const wrapped = (p: Promise<AxiosResponse<T>>) => {
    return p.then((value) => {
      notify({ msg: success, severity: 'success' });
      return value.data;
    }).catch(e => {
      notify({ msg: failure, severity: 'error' });
      return Promise.reject(e);
    })
  }

  return wrapped;
}

export default useNotify;
