import { DatePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { withFormControl } from "./FormComponent";
import { FieldConfigI, FieldProps, FieldTypeEnum } from "./types";

export interface DateFieldConfig extends FieldConfigI {
  type: FieldTypeEnum.DATE;
}

interface DateFieldProps extends FieldProps {
  value: Dayjs | null;
  placeholder: string;
  onChange: (arg0: Record<string, Dayjs|null>) => void;
  config: DateFieldConfig;
}

const DateField = ({ config, value, onChange, error }: DateFieldProps) => {
  return (
    <DatePicker
      label={config.label}
      value={value ? dayjs(value) : null}
      onChange={(d) => onChange({ [config.name]: d && d.isValid() ? d : null })}
      renderInput={(params) => (
        <TextField
          error={error}
          variant="outlined"
          sx={{ width: "100%" }}
          {...params}
        />
      )}
    />
  );
};

export default withFormControl(DateField);
