import React from "react";
import {
  Routes,
  Route,
  useParams,
  useLocation,
  Navigate,
} from "react-router-dom";
import PageNotFound from "components/errors/PageNotFound";
import AccountDashboard from "./account-dashboard/AccountDashboard";
import useAuth from "../../../auth";
import Agents from "./Agents";

const LazyDealRoutes = React.lazy(() => import("./deal/Routes"))

const AccountRoutes = () => {
  const { auth } = useAuth();
  const params = useParams();
  const location = useLocation();
  const accountId = parseInt(params.accountId || '', 10);

  if (!accountId) return <PageNotFound />;

  if (!auth || auth.account_id !== accountId || auth.agent_id !== null) {
    const nextLocation = `${location.pathname}${location.search}`;
    return (
      <Navigate
        to={`/auth/scope?account=${accountId}&next=${encodeURIComponent(
          nextLocation
        )}`}
        state={{ replace: true }}
      />
    );
  }

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="agents" element={<Agents />} />
        <Route
          path="dashboard"
          element={<AccountDashboard accountId={accountId} />}
        />
        <Route
          path="deals/*"
          element={<LazyDealRoutes account={auth.account!} />}
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </React.Suspense>
  );
};

export default AccountRoutes;
