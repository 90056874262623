import { useState, useEffect } from "react";
import { useDebounce } from "@react-hook/debounce";
import { FieldProps, FieldTypeEnum, FieldConfigI } from "./types";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { withFormControl } from "./FormComponent";

export type SearchableOption = {
  name?: string;
  value: string | number;
  isNew?: boolean;
};

export interface SearchableSelectFieldConfig extends FieldConfigI {
  type: FieldTypeEnum.SEARCHABLE_SELECT;
  onSearch: (text: string) => Promise<SearchableOption[]>;
  onLookup: (value: string | number) => Promise<string>;
  readOnly?: boolean;
}

interface SearchableSelectFieldProps extends FieldProps {
  value: SearchableOption | null;
  onChange: (arg0: Record<string, SearchableOption | null>) => void;
  config: SearchableSelectFieldConfig;
}
const SearchableSelectField = ({
  value,
  onChange,
  config,
}: SearchableSelectFieldProps) => {
  const [options, setOptions] = useState<SearchableOption[]>([]);
  const [searchText, setSearchText] = useDebounce<string>("", 400, true);

  const handleChange = (e: any, newValue: string | SearchableOption | null) => {
    e.preventDefault();
    if (typeof newValue === "string") {
      if (!config.readOnly) {
        onChange({
          [config.name]: { isNew: true, value: newValue, name: newValue },
        });
      } else {
        onChange({ [config.name]: null });
      }
    } else {
      onChange({ [config.name]: newValue });
    }
  };

  const handleInputChange = (e: any, value: string, reason: string) => {
    if (reason !== "reset") setSearchText(value);
  };

  const loading = false;

  useEffect(() => {
    if (searchText) config.onSearch(searchText).then(setOptions);
  }, [searchText, config]);

  const handleFilter = (
    options: SearchableOption[],
    params: { inputValue: string }
  ) => {
    const filtered = options;
    if (config.readOnly) return filtered;

    const { inputValue } = params;

    if (inputValue) {
      if (!options.find(opt => opt.name === inputValue)) {
        // Suggest the creation of a new value
        filtered.push({ value: "__new__", name: inputValue, isNew: true });
      }
    }

    return filtered;
  };

  useEffect(() => {
    if (value && value.value && !value.name) {
      console.log(value)
      config
        .onLookup(value.value)
        .then((label) => onChange({ [config.name]: { ...value, name: label } }))
        .catch(() => onChange({ [config.name]: { ...value, name: "???" } }));
    }
  }, [value, onChange, config]);

  return (
    <Autocomplete
      disabled={config.disabled}
      value={value || ""}
      onChange={handleChange}
      filterOptions={handleFilter}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      loading={loading}
      onInputChange={handleInputChange}
      getOptionLabel={(option: SearchableOption) => option.name || ""}
      renderOption={(props, option) => (
        <li {...props} key={option.value}>
          {option.isNew ? <><strong>Add:</strong><i>{option.name}</i></> : option.name}
        </li>
      )}
      sx={{ width: "100%" }}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={config.placeholder}
          label={config.label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default withFormControl(SearchableSelectField);
