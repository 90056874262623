import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import {ColumnType, SelectionEnum, Ordering, SortDirectionEnum, NO_ORDERING} from './types';


type EnhancedTableHeadProps = {
  columns: ColumnType[];
  selection: SelectionEnum;
  onSelectionChange: (arg0: SelectionEnum) => void;
  ordering: string[];
  onOrderingChange: (arg0: string) => void;
};


const parseOrdering = (orderingParams: string[]) : Ordering => {
  const [firstParam] = orderingParams;
  if(firstParam){
    if(firstParam.startsWith('-')){
      const field = firstParam.substring(1);
      if(field) return [SortDirectionEnum.DESC, field];
    } else{
      return [SortDirectionEnum.ASC, firstParam]
    }
  }

  return NO_ORDERING;
}

export default function EnhancedTableHead({
  columns,
  selection,
  ordering,
  onSelectionChange,
  onOrderingChange,
}: EnhancedTableHeadProps) {
  const onSelectAllClick = () => {
    onSelectionChange(
      selection === SelectionEnum.NONE ? SelectionEnum.ALL : SelectionEnum.NONE
    );
  };

  const [sortDir, sortField] = parseOrdering(ordering);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={selection === SelectionEnum.SOME}
            checked={selection === SelectionEnum.ALL}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "Select all rows",
            }}
          />
        </TableCell>
        {columns.map((col) => {
          const isSorted = Boolean(sortDir && col.ordering === sortField);

          return (
            <TableCell
              key={col.id}
              align={col.numeric ? "right" : "left"}
              padding="normal"
              sortDirection={isSorted ? (sortDir as SortDirectionEnum) : false}
            >
              {col.ordering ? (
                <TableSortLabel
                  active={isSorted}
                  direction={sortDir || SortDirectionEnum.ASC}
                  onClick={() =>
                    onOrderingChange(
                      `${(sortDir === SortDirectionEnum.ASC) ? '-': ''}${col.ordering}`,
                    )
                  }
                >
                  {col.label}
                </TableSortLabel>
              ) : (
                col.label
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
