import { AxiosResponse } from 'axios';
import { FormErrors } from './types';

type ApiErrorResponse = {
  detail: {
    loc: string[],
    msg: string,
    type: string
  }[] | string
}


export const formErrorsFromResponse = (
  response: AxiosResponse<ApiErrorResponse>,
  defaultMsg: string = 'Oops! Something went wrong.'
  ): FormErrors => {

  const formErrors: FormErrors = {
    non_field_errors: [defaultMsg]
  };

  if (response) {
    const { detail = [] } = response.data;

    if (detail) {
      if (typeof detail === 'string') {
        formErrors.non_field_errors.push(detail)
      } else {
        detail.forEach(({ loc, msg, type }) => {
          if (loc) {
            const fieldName = loc[1] || loc[0];
            formErrors[fieldName] = [msg || type || 'Error'];
          } else {
            formErrors.non_field_errors.push(msg || type);
          }
        });
      }
    }
  }
  return formErrors
};

export const errorStringFromResponse = (response: AxiosResponse<ApiErrorResponse>, defaultMsg?: string): string => {
  const {non_field_errors, ...rest} = formErrorsFromResponse(response, defaultMsg);

  const errors = [...non_field_errors, ...Object.keys(rest).map(key => `[${key}] ${rest[key].join(' ')}`)];

  return errors.join(' ');
}