import { Grid, Card, CardContent, Stack, Divider, Box, Typography, styled, CardActions, } from "@mui/material";
import { AgentRouting } from "utils/routing";
import ButtonLink from "components/links/ButtonLink";
import { PageResult } from "constants/common.types";
import { ReminderWithLeadAndContactType } from "constants/lead.types";
import { compactNumber } from "./stats";
import { personName } from "utils/names";
import TextLink from "components/TextLink";



const StyledTable = styled("table")(({ theme }) => ({
  margin: theme.spacing(2),
  width: "100%"
}));

const StyledHeader = styled("thead")(({ theme }) => ({
  color: theme.palette.text.disabled
}));


const Reminders = ({ agentRouting, loading, reminders }: { agentRouting: AgentRouting, loading: boolean, reminders: PageResult<ReminderWithLeadAndContactType> | null }) => {

  const { count = 0, results = [] } = reminders || {}
  return (
    <Grid item xs={12} lg={6}>
      <Card elevation={2}>
        <CardContent>
          <Typography component="div" variant="h5">Lead Reminders</Typography>
          <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
            <Box sx={{ p: 2 }}>
              <Stack direction="column" alignItems="center">
                <Typography variant="h3">
                  {loading ? '-' : compactNumber(count)}
                </Typography>
                <Typography component="div" variant="caption" textAlign="center">Upcoming reminders</Typography>
              </Stack>
            </Box>
            <StyledTable>
              <colgroup>
                <col style={{ width: "50%" }} />
              </colgroup>
              <StyledHeader>
                <tr>
                  <th>
                    Contact Name
                  </th>
                  <th>
                    Lead Category
                  </th>
                </tr>
              </StyledHeader>
              <tbody>
                {results.map(v => (
                  <tr key={v.id}>
                    <td>
                      <TextLink
                        to={v.contact ? agentRouting.contactLeadTab(v.contact.id) : '#'}
                      >
                        {v.contact ? personName(v.contact) : '(unnamed)'}
                      </TextLink></td>
                    <td>{v.lead?.label || 'Lead'}</td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          </Stack>
        </CardContent>
        <CardActions>
          <ButtonLink to={agentRouting.leadReminders()}>View All</ButtonLink>
          <ButtonLink to={agentRouting.leadsDashboard()}>Manage Leads</ButtonLink>
        </CardActions>
      </Card>
    </Grid>
  )
}

export default Reminders