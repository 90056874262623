import React, { useState, useEffect, useCallback, useRef } from "react";
import api from "api";
import { PageResult } from "constants/common.types";
import {
  errorStringFromResponse,
  formErrorsFromResponse,
} from "components/form/errors";
import { PreSignedPostUpload, UploadedFile } from "constants/media.types";
import { default as axiosAnon } from "axios";
import useNotify from "notify";

export const useGetOnce = <T>(
  endpoint: string,
  params?: object
): [T | null, boolean, boolean] => {
  const [result, setResult] = React.useState<null | T>(null);
  const [error, setError] = React.useState<null | boolean | string>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get<T>(endpoint, { params })
      .then((r) => {
        setResult(r.data);
      })
      .catch((err) => {
        setResult(null);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [result, loading, !!error];
};

export const useGetOne = <T>(
  endpoint: string
): [T | null, (params?: any) => Promise<void | T>, boolean] => {
  const [result, setResult] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);

  const getOne = useCallback(
    (params = {}) => {
      setLoading(true);
      return api
        .get<T>(endpoint, {
          params,
        })
        .then((r) => {
          setResult(r.data);
          return r.data;
        })
        .catch((e) => {
          setResult(null);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [endpoint]
  );

  return [result, getOne, loading];
};

export const useFormCreate = <T>(
  endpoint: string
): [(a: any) => Promise<T>, boolean] => {
  const [saving, setSaving] = useState(false);

  const create = useCallback(
    (params) => {
      setSaving(true);
      return api
        .post<T>(endpoint, params)
        .then((r) => r.data)
        .catch((e) => {
          throw formErrorsFromResponse(e.response);
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [endpoint]
  );

  return [create, saving];
};

export const useFormUpdate = <T>(
  endpoint: string
): [(a: any) => Promise<T>, boolean] => {
  const [saving, setSaving] = useState(false);

  const create = useCallback(
    (params) => {
      setSaving(true);
      return api
        .patch<T>(endpoint, params)
        .then((r) => r.data)
        .catch((e) => {
          throw formErrorsFromResponse(e.response);
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [endpoint]
  );

  return [create, saving];
};

export const useDestroyOne = <T>(
  endpoint: string
): [(arg0?: object) => Promise<T>, boolean] => {
  const [destroying, setDestroying] = useState(false);

  const destroy = useCallback(
    (data?: object) => {
      setDestroying(true);
      return api
        .delete<T>(endpoint, { data })
        .then((r) => r.data)
        .catch((e) => {
          throw formErrorsFromResponse(e.response);
        })
        .finally(() => {
          setDestroying(false);
        });
    },
    [endpoint]
  );

  return [destroy, destroying];
};

const getPresignedPostUpload = (
  endpoint: string,
  filename: string,
  contentType: string,
  controller: AbortController
): Promise<PreSignedPostUpload> => {
  return api
    .post<PreSignedPostUpload>(
      endpoint,
      {
        content_type: contentType,
        original_filename: filename,
      },
      {
        signal: controller.signal,
      }
    )
    .then((r) => r.data)
    .catch((e) => {
      throw Error(
        errorStringFromResponse(e.response, "Failed to initialize upload")
      );
    });
};

const presignedPostUpload = (
  file: File,
  postUpload: PreSignedPostUpload,
  controller: AbortController
): Promise<null> => {
  let formData = new FormData();
  Object.keys(postUpload.fields).reduce((formData, key) => {
    formData.append(key, postUpload.fields[key]);
    return formData;
  }, formData);

  // File field *must* be the last field
  formData.append("file", file);
  return axiosAnon
    .post<any>(postUpload.url, formData, {
      signal: controller.signal,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((r) => r.data)
    .catch(() => {
      throw Error("Upload failed or aborted.");
    });
};

export const usePostUpload = (
  endpoint: string
): [(file: File) => Promise<UploadedFile>, boolean, () => void] => {
  const controllerRef = useRef<AbortController | null>(null);
  const [uploading, setUploading] = useState(false);

  const upload = useCallback(
    (file: File) => {
      setUploading(true);
      controllerRef.current = new AbortController();
      return getPresignedPostUpload(
        endpoint,
        file.name,
        file.type,
        controllerRef.current
      )
        .then((postUpload) => {
          if (!controllerRef.current)
            return Promise.reject(Error("Upload Cancelled."));
          return presignedPostUpload(file, postUpload, controllerRef.current)
            .then(() => {
              setUploading(false);
              return { path: postUpload.path, original_filename: file.name };
            })
            .finally(() => setUploading(false));
        })
        .finally(() => setUploading(false));
    },
    [endpoint]
  );

  const cancel = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
      setUploading(false);
    }
  };

  return [upload, uploading, cancel];
};

export const usePostAction = <T>(
  endpoint: string,
  opts: { successMsg?: string; errorMsg?: string }
): [(arg0?: object) => Promise<T>, boolean] => {
  const [saving, setSaving] = useState(false);
  const { notify } = useNotify();
  const { successMsg, errorMsg } = opts;

  const save = useCallback(
    (params) => {
      setSaving(true);
      return api
        .post<T>(endpoint, params)
        .then((r) => {
          if (successMsg) notify({ severity: "success", msg: successMsg });
          return r.data;
        })
        .catch((e) => {
          if (errorMsg) {
            notify({
              severity: "error",
              msg: errorStringFromResponse(e.response, errorMsg),
            });
          }
          throw e;
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [endpoint, successMsg, errorMsg, notify]
  );

  return [save, saving];
};


export const useGetMore = <T>(
  endpoint: string,
  pageSize: number = 25,
): [PageResult<T> | null, (params?: any) => void, boolean] => {
  const [page, setPage] = useState<PageResult<T> | null>(null);
  const [loading, setLoading] = useState(false);

  const getMore = useCallback(
    (params = {}) => {
      setLoading(true);
      api
        .get<PageResult<T>>(endpoint, {
          params: {
            ...params,
            page_size: pageSize,
            offset: page ? page.next : 0,
          },
        })
        .then((r) => {
          const {next, count, results} = r.data;
          setPage(s => s ? {results: [...s.results, ...results], next, count} : r.data);
        })
        .catch((e) => {
          setPage(null);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [endpoint, page, pageSize]
  );

  return [page, getMore, loading];
};