import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ColumnType } from "components/tabular-list/types";
import TabularList from "components/tabular-list/TabularList";
import useAdvancedSearch, { bulkActionCompleted, refresh } from "components/tabular-list/context";
import { LocaleDatetime } from "components/locale";
import { useFormCreate } from "hooks/api-hooks";
import { AgentType } from "constants/auth.types";
import CopyToClipboard from "components/CopyToClipboard";
import { BulkAction } from "components/tabular-list/types";
import BulkActionDialog from "components/bulk-action-dialog/BulkActionDialog";
import { createBulkActionHandler } from "components/bulk-action-dialog/handlers";
import axios from "../../../../api";
import { hasPermission } from "../../../../permissions";
import { AgentPermissionsEnum } from "constants/permissions.enums";
import { useSearchState } from "components/tabular-list/search-state";
import CalendarHelp from "./CalendarHelp";
import { ActionableSectionTitle, Paragraph } from "components/CardFact";

const columns: ColumnType[] = [
  {
    id: "name",
    numeric: false,
    label: "Calendar Name",
    render: ({ row }) => `${row.name}`,
  },
  {
    id: "created_at",
    numeric: false,
    label: "Created",
    ordering: "created_at",
    render: ({ row }) => <LocaleDatetime value={row.created_at} />,
  },
  {
    id: "link",
    numeric: false,
    label: "iCal Link",
    render: ({ row }) => <CopyToClipboard text={row.ical_url} />,
  },
];

const actions: BulkAction[] = [
  {
    name: "delete",
    label: "Delete",
    icon: <DeleteIcon />,
    visible: true,
  },
];

const DealCalendars = ({ agent }: { agent: AgentType }) => {
  const [searchState, updateSearchState] = useSearchState();
  const [state, dispatch] = useAdvancedSearch(`deal_calendar`, searchState);
  const { actionSelection } = state;

  const canCreate = hasPermission(
    agent,
    AgentPermissionsEnum.CREATE_DEAL_CALENDAR
  );

  const [create, saving] = useFormCreate(`/deal_calendar`);

  const onCreate = () => {
    create({ agent_id: agent.id, name: "My Deals" }).then(() => {
      dispatch(refresh());
    });
  };

  return (
    <>
      <ActionableSectionTitle
        actions={
          <Button
            onClick={onCreate}
            variant="contained"
            disabled={saving || !canCreate}
            sx={{ mb: 1 }}
          >
            New Calendar
          </Button>
        }
      >
        AgentVA in your Calendar
      </ActionableSectionTitle>
      <Paragraph>
        Publish key events from your deals and contacts to your favourite calendar
        application. Calendars are visible to anyone with the secret link, so
        keep it safe. Copy the iCal calendar link and subscribe to it from your
        calendar application.
      </Paragraph>


      <TabularList
        dispatch={dispatch}
        store={state}
        heading="Deal Calendar Feeds"
        columns={columns}
        actions={actions}
        searchState={searchState}
        onUpdateSearch={updateSearchState}
      />

      {actionSelection?.action?.name === "delete" && (
        <BulkActionDialog
          actionSelection={actionSelection}
          onClose={actionedIds => dispatch(bulkActionCompleted(actionedIds))}
          onConfirm={deleteHandler}
        />
      )}

      <CalendarHelp />
    </>
  );
};

const deleteHandler = createBulkActionHandler((item: string | number) => {
  return axios
    .delete(`deal_calendar/${item}`)
    .then(() => "Calendar deleted.")
    .catch(() => {
      throw Error("Unable to delete calendar.");
    });
});

export default DealCalendars;
