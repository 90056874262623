import { TextField } from "@mui/material";
import { ValueFieldProps } from "./types";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { Dayjs } from "dayjs";

const DateTimeValueField = ({ value, onChange, name }: ValueFieldProps) => {
  const { datetime_value } = value;
  const handleChange = (newValue: Dayjs | null) => {
    onChange({
      datetime_value:
        newValue && newValue.isValid() ? newValue.toISOString() : null,
    });
  };

  return (
    <DateTimePicker
      onChange={handleChange}
      label="Date & Time"
      value={datetime_value || null}
      renderInput={(params) => (
        <TextField
          name={name}
          variant="outlined"
          sx={{ width: "100%" }}
          {...params}
        />
      )}
    />
  );
};

export const AppointmentField = DateTimeValueField;
