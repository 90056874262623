import { Button,  Grid, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { CustomEditor } from "components/editor/types";
import React, { useState } from "react";
import { insertPlaceholder } from "components/editor/transforms";
import { ReactEditor } from "slate-react";
import SearchableSelect, { SearchableOption } from "components/form/SearchableSelect";
import { FieldConfig } from "components/form/field-config";
import { FieldTypeEnum } from "components/form/types";
import api from "api";
import { MessageTemplateVariable } from "constants/msg.types";
import { PageResult } from "constants/common.types";


const handleSearch = (text: string) => {
  return api
    .get<PageResult<MessageTemplateVariable>>("deal_template/variables", {
      params: { search: text, page_size: 100 },
    })
    .then((resp) =>
      resp.data.results.map((v) => ({
        value: v.alias,
        name: v.label,
      }))
    );
};


const handleLookup = (value: string | number): Promise<string> => {
  return Promise.resolve(`${value}`);
};



const fieldConfig : FieldConfig = {
  type: FieldTypeEnum.SEARCHABLE_SELECT,
  name: "alias",
  label: 'Variable name',
  help: "Variable names that exactly match labeled info within the deal will be automatically replaced when you create instance of this teamplate.",
  required: true,
  onSearch: handleSearch,
  onLookup: handleLookup,
  placeholder: 'Start typing to search...',
  readOnly: false,
}


const CustomVariable = ({ onInsert }: { onInsert: (arg0: string) => void }) => {

  const [value, setValue] = useState<SearchableOption|undefined>(undefined);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if(value && value.name) {
      onInsert(value.name);
    }
  }

  return (
    <ListItem>
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Typography gutterBottom variant="body1">
              Custom Deal Variable
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <SearchableSelect
              fieldConfig={fieldConfig}
              formData={{alias: value}}
              formErrors={{}}
              onError={()=>null}
              onChange={({alias}) => setValue(alias)}
            />
          </Grid>
          <Grid item xs={12} sm={4} sx={{ textAlign: "right" }}>
            <Button type="submit" size="small" disabled={!value} >Insert</Button>
          </Grid>
        </Grid>
      </form>
    </ListItem>
  )
}


const InsertPlaceholderModal = ({ editor, onClose }: { editor: CustomEditor | null, onClose: () => void }) => {

  const handleSelectBuiltin = (builtin: string) => {
    handleInsert(builtin)
  }

  const handleInsert = (alias: string) => {
    if (editor) {
      setTimeout(() => {
        insertPlaceholder(editor, alias);
        ReactEditor.focus(editor);
      })
      onClose();
    }
  }


  return (
    <Dialog open={!!editor} onClose={onClose} fullWidth >
      <DialogTitle>Message Variables</DialogTitle>
      <DialogContent>
        <List>
          <ListItemButton onClick={() => handleSelectBuiltin('recipient')}>
            <ListItemText primary="Recipient" secondary="The First/Last name of the contact receiving the message." />
          </ListItemButton>
          <ListItemButton onClick={() => handleSelectBuiltin('sender')}>
            <ListItemText primary="Sender" secondary="The First/Last name of the sender" />
          </ListItemButton>
          <ListItemButton onClick={() => handleSelectBuiltin('signature')}>
            <ListItemText primary="Agent" secondary="The Agent name associated with this account" />
          </ListItemButton>
          <CustomVariable onInsert={handleInsert} />
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default InsertPlaceholderModal;