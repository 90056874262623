import SaveIcon from "@mui/icons-material/Save";
import PermissionDenied from "components/PermissionDenied";
import { AgentPermissionsEnum } from "constants/permissions.enums";
import { useFormCreate } from "hooks/api-hooks";
import { hasPermission } from "permissions";
import { Form } from "components/form/Form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AgentType } from "constants/auth.types";
import { ApiEntityType } from "constants/base.types";
import { FieldTypeEnum, FormSubmitHandler } from "components/form/types";
import { FieldConfig } from "components/form/field-config";
import api from "api";
import { MessageTemplateType } from "constants/msg.types";
import { PageHeader, PageSection, PageSubtitle, PageTitle } from "components/page/PageHeader";
import EmailIcon from "@mui/icons-material/EmailOutlined"
import { useMessageBreadcrumbs } from "../../message-breadcrumbs";
import { SectionTitle } from "components/CardFact";


const handleSearch = (text: string) => {
  return api.get<MessageTemplateType[]>('message_template/autocomplete', { params: { search: text } }).then(resp => resp.data.map(v => ({
    value: v.id, name: v.label
  })))
}

const handleLookup = (value: string | number) => {
  return api.get<MessageTemplateType>(`message_template/${value}`).then(resp =>
    resp.data.label
  )
}

const FIELDS: FieldConfig[] = [
  {
    name: "label",
    type: FieldTypeEnum.INPUT,
    label: "Name",
    placeholder: "e.g. Welcome Email",
    required: true,
  },
  {
    name: "clone_from_template",
    type: FieldTypeEnum.SEARCHABLE_SELECT,
    label: "Copy an existing template (optional)",
    placeholder: "Specify an existing template to use it as starting point",
    required: false,
    help: "Leave blank to create an empty template.",
    onSearch: handleSearch,
    onLookup: handleLookup,
    readOnly: true
  },
]

const CreateMessageTemplateForm = ({
  onSuccess,
  cloneFromTemplateId,
}: {
  onSuccess: (dealStep: ApiEntityType) => void;
  cloneFromTemplateId: number | null;
}) => {
  const [save, saving] = useFormCreate<ApiEntityType>(`message_template`)

  const onSubmit: FormSubmitHandler = ({ clone_from_template, ...values }, setFormErrors) => {
    save({
      ...values,
      clone_from_template_id: clone_from_template ? clone_from_template.value : null,
    }).then(onSuccess).catch(setFormErrors);
  };

  return (
    <>
      <SectionTitle>Template Details</SectionTitle>
      <Form
        fields={FIELDS}
        onSubmit={onSubmit}
        saving={saving}
        submitIcon={<SaveIcon />}
        submitText="Save & Continue"
        initialValues={{ clone_from_template: { value: cloneFromTemplateId } }}
      />
    </>
  );
};

const NewTemplateRoute = ({
  agent,
}: {
  agent: AgentType;
}) => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const handleSuccess = (template: ApiEntityType) =>
    navigate(`../${template.id}`);

  const trail = useMessageBreadcrumbs(agent.id)

  if (!hasPermission(agent, AgentPermissionsEnum.CREATE_MESSAGE_TEMPLATE))
    return <PermissionDenied />;


  const param = searchParams.get('cloneFromTemplateId')
  const cloneFromTemplateId = param ? Number.parseInt(param) || null : null;

  return (
    <>
      <PageHeader breadcrumbTrail={trail}>
        <PageTitle
          Icon={EmailIcon}
          text="New Message Template"
        />
        <PageSubtitle text="Create a new message template" />
      </PageHeader>

      <PageSection>
        <CreateMessageTemplateForm
          onSuccess={handleSuccess}
          cloneFromTemplateId={cloneFromTemplateId}
        />
      </PageSection>
    </>
  );
};

export default NewTemplateRoute;
