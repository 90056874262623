import ButtonLink from "components/links/ButtonLink";
import TabularList from "components/tabular-list/TabularList";
import useAdvancedSearch from "components/tabular-list/context";
import { useSearchState } from "components/tabular-list/search-state";
import { AccountType } from "constants/auth.types";
import { PageHeader, PageSubtitle, PageTitle } from "components/page/PageHeader";
import AccountIcon from "@mui/icons-material/AccountBalance";
import { ColumnType } from "components/tabular-list/types";
import { ManageBilling } from "components/billing/ManageBilling";


const columns : ColumnType<AccountType>[] = [
  {
    id: 'name', 
    numeric: false, 
    label: 'Name', 
    ordering: 'name'
  },
  {
    id: 'actions', 
    numeric: false, 
    label: 'Actions', 
    render: ({row}) => (<ButtonLink disabled={!row.is_active} to={`/auth/scope?account=${row.id}`}>Login</ButtonLink>)
  },
  {
    id: 'status',
    numeric: false,
    label: 'Status',
    render: ({row}) => (row.is_active ? 'Active' : 'Inactive')
  },
  {
    id: 'actions',
    numeric: false,
    label: 'Actions',
    render: ({row}) => (<ManageBilling account={row} accountSubscription={row.subscription||null}/> )
  }
]

const Accounts = () => {

  const [searchState, updateSearchState] = useSearchState();
  const [state, dispatch] = useAdvancedSearch('account', searchState);

  return (
    <>
      <PageHeader>
        <PageTitle
          text="Accounts"
          Icon={AccountIcon}
        />
        <PageSubtitle text="View all the AgentVA accounts you belong to" />
      </PageHeader>

      <TabularList 
        dispatch={dispatch}
        store={state}
        heading="Accounts"  
        columns={columns}
        searchState={searchState}
        onUpdateSearch={updateSearchState}
      />
    </>
  )
};

export default Accounts;