import { Typography } from "@mui/material"
import { SectionTitle } from "components/CardFact";
import ExpandableHelp from "components/ExpandableHelp"

const CalendarHelp = () => {
  return <section>
    <SectionTitle>
      Setup Instructions
    </SectionTitle>
    <ExpandableHelp title="Add to your Google Calendar">
        <Typography gutterBottom>
          Open your Google Calendar.
        </Typography>
        <Typography gutterBottom component="ol">
            <li>In the bottom left corner, click the + beside Other Calendars.</li>
            <li>Select Other Calendars &gt; From URL.</li>
            <li>Paste the iCal address from AgentVA into the URL of Calendar box.</li>
            <li>Click Add Calendar.</li>
            <li>If your AgentVA Calendar is successfully added to your Google Calendar, you will see a "Calendar Created" message.</li>
        </Typography>
        <Typography variant="caption">
          Calendars cannot be added with the Google Calendar app on an Android device. This must be done from a computer.
        </Typography>
    </ExpandableHelp>
    <ExpandableHelp title="Add to iCalendar (iPhone, iPad)">
        <Typography>
          On an iPhone or iPad:
        </Typography>
        <Typography gutterBottom component="ol">
            <li>Open your Calendar app. Tap Calendars &gt; Add Calendar.</li>
            <li>Tap Add Subscription Calendar.</li>
            <li>Enter the iCal address from AgentVA.</li>
            <li>Click Subscribe.</li>
        </Typography>
    </ExpandableHelp>
  </section>
}

export default CalendarHelp;