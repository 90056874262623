import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { UnstyledLink } from "components/links/ButtonLink";
import { ReactNode } from "react";
import { NotificationSnackbar } from "../notify";

function PublicLayout({ children }: { children: ReactNode }) {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <UnstyledLink to="/">
            <Typography variant="h6" noWrap component="div">
              AgentVA - The Real Estate Agent's Virtual Assistant
            </Typography>
          </UnstyledLink>
        </Toolbar>
      </AppBar>
      <Box component="main" sx={{ p: 1, width: "100%" }}>
        <Toolbar />
        {children}
      </Box>
      <NotificationSnackbar />
    </Box>
  );
}

export default PublicLayout;
