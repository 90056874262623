import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

const config: Readonly<Record<string, string>> = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const LANGUAGES = [...navigator.languages];

export const LocaleDatetime = ({
  value,
}: {
  value: string | null | undefined;
}) => {
  if (!value) return null;
  const date = new Date(value);

  return (
    <time dateTime={value}>{date.toLocaleDateString(LANGUAGES, config)}</time>
  );
};

export const LocaleDate = ({ value }: { value: string | null | undefined }) => {
  if (!value) return null;
  const display = dayjs(value).utc().format("ll");

  return <time dateTime={value}>{display}</time>;
};

const getCurrencyFormatter = (currency: "USD" | "CAD") =>
  Intl.NumberFormat(LANGUAGES, {
    style: "currency",
    currencyDisplay: "symbol",
    currency,
  });

export const LocaleCurrency = ({
  cents,
  currency,
}: {
  cents: number;
  currency: "USD" | "CAD";
}) => {
  return <>{getCurrencyFormatter(currency).format(cents / 100)}</>;
};
