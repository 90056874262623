import { useFormUpdate, useGetOnce } from "hooks/api-hooks";
import { Form } from "components/form/Form";
import { EmailTemplateWithFilesType } from "constants/msg.types";
import { FieldTypeEnum, FormSubmitHandler } from "components/form/types";
import { FieldConfig } from "components/form/field-config";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/EmailOutlined"
import { useEmailTemplateBreadcrumbs } from "./EmailTemplateBreadcrumbs";
import { PageHeader, PageSection, PageSubtitle, PageTitle } from "components/page/PageHeader";
import { SectionTitle } from "components/CardFact";

const getFields = (): FieldConfig[] => [
  {
    name: "name",
    type: FieldTypeEnum.INPUT,
    label: "Template Name",
    required: true,
  },
  {
    name: "default",
    type: FieldTypeEnum.RADIO,
    label: "Use this as the default template?",
    options: [
      { name: "Yes", value: "true" },
      { name: "No", value: "false" },
    ],
    required: true,
  },
];

const EmailTemplateEditSettings = ({
  emailTemplateId,
  agentId
}: {
  agentId: number,
  emailTemplateId: number;
}) => {
  const [emailTemplate, loading] = useGetOnce<EmailTemplateWithFilesType>(
    `/email_template/${emailTemplateId}`
  );
  const navigate = useNavigate();

  const [save, saving] = useFormUpdate(`/email_template/${emailTemplateId}`);
  const trail = useEmailTemplateBreadcrumbs(agentId, emailTemplateId);

  const onSubmit: FormSubmitHandler = (params, setFormErrors) => {
    save(params)
      .then(() => navigate(`..`))
      .catch(setFormErrors);
  };

  return (
    <>
      <PageHeader breadcrumbTrail={trail}>
        <PageTitle
          defaultText="Email Signature"
          text={emailTemplate?.name}
          Icon={EmailIcon}
        />
        <PageSubtitle text="Change the settings for this email signature" />
      </PageHeader>
      <PageSection loading={loading}>
        <SectionTitle>Edit Settings</SectionTitle>

        {emailTemplate && (
          <Form
            fields={getFields()}
            onSubmit={onSubmit}
            saving={saving}
            initialValues={{
              name: emailTemplate.name,
              default: `${emailTemplate.default}`,
            }}
          />
        )}
      </PageSection>
    </>
  );
};

export default EmailTemplateEditSettings;
