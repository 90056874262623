import AppRoutes from "./routes/routes";
import { AuthProvider } from "./auth";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import { NotifyProvider } from "./notify";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {dark: '#3a70e2', main: '#3B82F6', light: '#3991ff'},
    secondary: {dark: '#9e00f2', main: '#ae3bf6', light: '#be64f8'},
    error: {dark: '#f5056c', main: '#f63b83', light: '#f8619a'},
    warning: {dark: '#f0882c', main: '#f6ae3b', light: '#f9c544'},
    info: {dark: '#00d9f3', main: '#513bf6', light: '#7deaf9'},
    success: {dark: '#4fe100', main: '#83F63B', light:'#a0f96a'}
  }
})

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AuthProvider>
        <NotifyProvider>
          <ThemeProvider theme={theme}>
            <AppRoutes />
          </ThemeProvider>
        </NotifyProvider>
      </AuthProvider>
    </LocalizationProvider>
  );
}

export default App;
