import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import {
  ColumnType,
  SelectionEnum,
  BulkAction,
} from "./types";
import { ReducerState, AdvancedSearchDispatch, startBulkAction, toggleSelection, selectNone, selectAll } from "./context";
import { SearchState } from "./search-state";

const DefaultCell = ({ col, row }: { col: ColumnType; row: any }) => (
  <>{row[col.id]}</>
);

const DEFAULT_ROWS_PER_PAGE_OPTIONS = [5, 10, 25];

type TabularListProps = {
  dispatch: AdvancedSearchDispatch;
  store: ReducerState;
  columns: ColumnType[];
  rowsPerPageOptions?: number[];
  heading: string;
  actions?: BulkAction[];
  rowKey?: (row: any) => string;
  searchState: SearchState,
  onUpdateSearch: (state: SearchState) => void
};

const idRowKey = (row: any) => `${row.id}`;

const parsePageFromOffset = (offset: string[], pageSize: number): number => {
  const [value] = offset;
  if (value) {
    const parsed = Number.parseInt(value, 10);
    if(parsed && pageSize) {
      return Math.floor(parsed/pageSize) 
    };
  }
  return 0
}

const parsePageSize = (pageSize: string[], pageSizeOptions: number[]): number => {
  const [value] = pageSize;
  if (value) {
    const pageSize = Number.parseInt(value, 10);
    if(pageSizeOptions.includes(pageSize)) return pageSize;
  }
  return pageSizeOptions[0] || DEFAULT_ROWS_PER_PAGE_OPTIONS[0];
}

export default function TabularList<T extends { id: number | string }>({
  dispatch,
  store,
  columns,
  rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE_OPTIONS,
  heading,
  actions = [],
  rowKey = idRowKey,
  searchState,
  onUpdateSearch
}: TabularListProps) {


  const { ordering = [], offset = [], page_size = [] } = searchState;

  const pageSize = parsePageSize(page_size, rowsPerPageOptions);
  const page = parsePageFromOffset(offset, pageSize);

  const onSelectionChange = (selection: SelectionEnum) => {
    if (selection === SelectionEnum.NONE) {
      dispatch(selectNone())
    } else {
      dispatch(selectAll());
    }
  };

  const onRowSelect = (event: React.SyntheticEvent, row: T) => {
    event.preventDefault();
    dispatch(toggleSelection(row.id));
  };

  const handleChangePage = (arg0: any, newPage: number) => {
    onUpdateSearch({offset: [`${newPage*pageSize}`]})
  };

  const handleChangeRowsPerPage = (event: React.SyntheticEvent) => {
    const newPageSize = parseInt((event.target as HTMLInputElement).value, 10);
    if(newPageSize){
      // Force reset offset back to beginning
      onUpdateSearch({page_size: [`${newPageSize}`], offset: ["0"]})
    }
  };

  const isSelected = (row: { id: string | number }) =>
    store.selected.includes(row.id);

  const selection =
    store.selected.length > 0
      ? store.selected.length === store.results.length
        ? SelectionEnum.ALL
        : SelectionEnum.SOME
      : SelectionEnum.NONE;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          loading={store.searching}
          heading={heading}
          numSelected={store.selected.length}
          actions={actions}
          onAction={(action) => dispatch(startBulkAction(action))}
        />

        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              ordering={ordering}
              onOrderingChange={ordering => onUpdateSearch({ ordering: ordering ? [ordering] : [] })}
              selection={selection}
              onSelectionChange={onSelectionChange}
              columns={columns}
            />
            <TableBody>
              {store.results.map((row) => {
                const isItemSelected = isSelected(row);

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={rowKey(row)}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        onClick={(event) => onRowSelect(event, row)}
                        color="primary"
                        checked={isItemSelected}
                      />
                    </TableCell>
                    {columns.map((col) => (
                      <TableCell
                        key={col.id}
                        align={col.numeric ? "right" : "left"}
                      >
                        {col.render ? (
                          col.render({ col, row })
                        ) : (
                          <DefaultCell row={row} col={col} />
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={store.count}
          rowsPerPage={pageSize}
          page={page} // API pagination 0-based. MUI 1-based
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
