import React from "react";
import ButtonLink from "./links/ButtonLink";
import {Typography} from "@mui/material";

const PermissionDenied = () => {
  return (
    <>
      <Typography variant="h2">Permission Denied</Typography>
      <Typography variant="body">
        Sorry, you don't have permission to view this page.
      </Typography>
      <ButtonLink to="/user/home">Return to dashboard</ButtonLink>
    </>
  )
}

export default PermissionDenied;
