import { Button, CircularProgress, ButtonProps } from "@mui/material";


export const ActiveButton = ({
  active,
  activeText = undefined,
  submitIcon,
  children,
  buttonProps = {}
}: {
  active: boolean,
  activeText?: string,
  submitIcon: JSX.Element;
  children: string,
  buttonProps: ButtonProps

}) => {
  return (
    <Button
      startIcon={active ? <CircularProgress size={15} /> : submitIcon}
      {...buttonProps}
    >
      {active && activeText ? activeText : children}
    </Button>
  );
};