import { Button, Stack } from "@mui/material";
import { AgentType } from "constants/auth.types";
import { useDestroyOne, useFormCreate, useGetOnce } from "hooks/api-hooks";
import { FactLabel, FactTextValue, ActionableSectionTitle } from "components/CardFact";
import { EmailTemplateType, MessagePreviewType } from "constants/msg.types";
import { hasPermission } from "permissions";
import { EmailTemplatePermissionsEnum } from "constants/permissions.enums";
import ButtonLink from "components/links/ButtonLink";
import { useEmailTemplateBreadcrumbs } from "./EmailTemplateBreadcrumbs";
import ApiActionButton from "components/api-action-button/ApiActionButton";
import ConfirmDialog from "components/api-action-button/ConfirmDialog";
import { useNavigate } from "react-router-dom";
import { PageHeader, PageSection, PageSubtitle, PageTitle } from "components/page/PageHeader";
import MailIcon from "@mui/icons-material/EmailOutlined";

const SendTestButton = ({ emailTemplate }: { emailTemplate: EmailTemplateType | null }) => {

  const canTest = hasPermission(emailTemplate, EmailTemplatePermissionsEnum.TEST);
  const [send, sending] = useFormCreate(`/email_template/${emailTemplate?.id}/test`);

  if (emailTemplate && canTest) return (
    <ApiActionButton
      loading={sending}
      onClick={() => send({})}
      renderButton={({ onClick, loading }) => <Button disabled={loading} onClick={onClick} variant="outlined">Send Test</Button>}
    />
  )

  return null
}

const ConfirmDelete = (props: any) => (
  <ConfirmDialog
    danger
    confirmText="Are you sure you want to delete this email signature? This action cannot be undone."
    okText="Delete"
    {...props}
  />
);

const DeleteButton = ({ emailTemplate, onDestroyed }: { emailTemplate: EmailTemplateType | null, onDestroyed: () => void }) => {

  const canDelete = hasPermission(emailTemplate, EmailTemplatePermissionsEnum.DELETE);
  const [destroy, destroying] = useDestroyOne(`/email_template/${emailTemplate?.id}`);


  if (emailTemplate && canDelete) return (
    <ApiActionButton
      renderConfirmDialog={ConfirmDelete}
      loading={destroying}
      onClick={destroy}
      successMsg="Signature deleted!"
      onSuccess={onDestroyed}
      renderButton={({ loading, ...props }) => <Button variant="outlined" color="error" disabled={loading} {...props}>Delete</Button>}
    />
  )
  return null;
}


const EmailTemplateDetails = ({ agent, emailTemplateId }: { agent: AgentType, emailTemplateId: number }) => {

  const [emailTemplate, loading] = useGetOnce<EmailTemplateType>(
    `/email_template/${emailTemplateId}`
  );
  const navigate = useNavigate();

  const canEdit = hasPermission(emailTemplate, EmailTemplatePermissionsEnum.EDIT);

  const handleDestroyed = () => {
    navigate('../../email-signatures');
  }

  let actions: React.ReactElement[] = [
    <SendTestButton key="send-test" emailTemplate={emailTemplate} />,
    <DeleteButton onDestroyed={handleDestroyed} key="delete" emailTemplate={emailTemplate} />,
  ];

  const trail = useEmailTemplateBreadcrumbs(agent.id, emailTemplateId);

  return (
    <>
      <PageHeader breadcrumbTrail={trail}>
        <PageTitle Icon={MailIcon} defaultText="Email Signature" text={emailTemplate?.name} />
        <PageSubtitle text="Customise this signature" />
      </PageHeader>

      <PageSection gutterBottom loading={loading}>
        <ActionableSectionTitle
          actions={
            <ButtonLink
              variant="contained"
              key="settings"
              to={`/agent/${agent.id}/settings/email-signatures/${emailTemplate?.id}/settings`}
              disabled={!canEdit}
            >
              Manage Settings
            </ButtonLink>
          }
        >
          Settings
        </ActionableSectionTitle>

        <FactLabel>Name</FactLabel>
        <FactTextValue emphasis>{emailTemplate?.name}</FactTextValue>

        <FactLabel>Use this as the default template for all emails?</FactLabel>
        <FactTextValue>
          {emailTemplate?.default ? "Yes" : "No"}
        </FactTextValue>

        <FactLabel>Actions</FactLabel>
        <Stack direction="row" spacing={2}>
          {actions}
        </Stack>
      </PageSection>

      {emailTemplate && <EmailTemplatePreview emailTemplate={emailTemplate} />}

    </>
  );
};

const EmailTemplatePreview = ({ emailTemplate }: { emailTemplate: EmailTemplateType }) => {

  const [preview, loading] = useGetOnce<MessagePreviewType>(
    `/email_template/${emailTemplate.id}/preview`
  );

  const canEdit = hasPermission(emailTemplate, EmailTemplatePermissionsEnum.EDIT);

  return <PageSection loading={loading}>
    <ActionableSectionTitle
      actions={
        <ButtonLink variant="contained" key="layout" to={`./layout`} disabled={!canEdit}>Edit Layout</ButtonLink>
      }
    >
      Preview
    </ActionableSectionTitle>
    {preview ? (
      <section dangerouslySetInnerHTML={{ __html: preview.rendered.body }} />
    ) : (
      "Unable to fetch preview"
    )}

  </PageSection>
}

export default EmailTemplateDetails;
