import {
  DealAttributeTypeEnum,
  DealAttributeTypeLabels,
} from "constants/deal.enums";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const TypeSelectField = ({
  name,
  value,
  onChange,
}: {
  name: string;
  value: DealAttributeTypeEnum|undefined;
  onChange: (arg0: DealAttributeTypeEnum) => void;
}) => {
  const handleChange = (e: any) => onChange(e.target.value);

  return (
    <FormControl fullWidth margin="normal" variant="outlined">
      <InputLabel id={`label-${name}`}>Type</InputLabel>
      <Select
        name={name}
        label="Type"
        value={value}
        multiple={false}
        onChange={handleChange}
      >
        {Object.values(DealAttributeTypeEnum).map((v) => (
          <MenuItem key={v} value={v}>
            {DealAttributeTypeLabels[v]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TypeSelectField;
