import {useEffect} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {useNavigate} from "react-router-dom";
import useAuth from "../auth";

const Logout = () => {
  const navigate = useNavigate();
  const {logout, auth} = useAuth();

  useEffect(() => {
    if(auth){
      logout().then(() => {
        navigate('/', {replace: true});
      })
    }
  }, [auth, navigate, logout]);

  return (
    <>
     <CircularProgress/> 
    </>
  )
}

export default Logout;
