import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import { AuthClaimsType } from "../auth";
import AgentMenu from "./AgentMenu";
import AccountMenu from "./AccountMenu";
import UserMenu from "./UserMenu";
import { UnstyledLink } from "components/links/ButtonLink";

const DrawerNavigation = ({ auth }: { auth: AuthClaimsType }) => {
  const renderMenu = () => {
    if (auth.agent_id) return <AgentMenu auth={auth} />;
    if (auth.account) return <AccountMenu auth={auth} />;
    return <UserMenu auth={auth} />;
  };

  return (
    <div>
      <Toolbar
        sx={{
          backgroundColor: "primary.main",
          color: "primary.contrastText",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6" noWrap component={UnstyledLink} to="/user/home">
          AgentVA
        </Typography>
        <Typography variant="caption" noWrap sx={{ fontStyle: "italic" }}>
          Agent Virtual Assistant
        </Typography>
      </Toolbar>
      <Divider />

      {renderMenu()}
    </div>
  );
};

export default DrawerNavigation;
