import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";

const ClickableMenuItem = ({
  label,
  to,
  icon,
  onClick,
}: {
  label: string;
  to: string;
  icon: any;
  onClick?: () => void;
}) => {
  return (
    <ListItem disablePadding key={label} onClick={onClick}>
      <ListItemButton to={to} component={to ? Link : "a"}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  );
};

export default ClickableMenuItem;
