

export type FirstAndLastName = {
  first_name: string,
  last_name: string
};

export const personName = (contact: FirstAndLastName, d: string = 'Unnamed Contact') => {
  if (contact && contact.first_name && contact.last_name)
    return `${contact.first_name} ${contact.last_name}`;
  return d;
}

export const firstAndLastNameFromString = (str: string | null | undefined): [string, string] => {
  if (!str) return ['', ''];

  const sep = /\s(.+)/;
  return [
    str.split(sep)[0].trim(),
    (str.split(sep)[1] || '').trim()
  ]
};