import { AgentType } from "constants/auth.types";
import EmailIcon from "@mui/icons-material/EmailOutlined"
import ButtonLink from "components/links/ButtonLink";
import { hasPermission } from "permissions";
import { AgentPermissionsEnum } from "constants/permissions.enums";
import { PageHeader, PageSection, PageSubtitle, PageTitle } from "components/page/PageHeader";

const MessageDashboard = ({ agent }: { agent: AgentType }) => {

  const canBrowseMessages = hasPermission(agent, AgentPermissionsEnum.BROWSE_MESSAGES)
  const canBrowseTemplates = hasPermission(agent, AgentPermissionsEnum.BROWSE_MESSAGE_TEMPLATES)

  return (
    <>
      <PageHeader>
        <PageTitle
          Icon={EmailIcon}
          text="Messages Dashboard"
        />
        <PageSubtitle text="Manage all aspects of message sending" />
      </PageHeader>

      <PageSection>
        <ButtonLink variant="outlined" disabled={!canBrowseMessages} to="./sent">View Sent Messages</ButtonLink>&nbsp;
        <ButtonLink variant="outlined" disabled={!canBrowseTemplates} to="./templates">Manage Templates</ButtonLink>
      </PageSection>
    </>
  )
    ;
}

export default MessageDashboard;