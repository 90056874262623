import React from "react";
import {
  SectionTitle,
  FactLabel,
  FactTextValue,
} from "components/CardFact";
import { useGetOnce } from "hooks/api-hooks";
import { AccountStatus } from "components/status";
import { AccountType } from "constants/auth.types";
import SubscriptionInfo from "./SubscriptionInfo";
import LicenseInfo from "./LicenseInfo";
import { PageHeader, PageSection, PageSubtitle, PageTitle } from "components/page/PageHeader";
import AccountIcon from "@mui/icons-material/AccountBalance";
import { LocaleDatetime } from "components/locale";

const AccountInfo = ({
  account,
  loading,
}: {
  account: AccountType | null;
  loading: boolean;
}) => {
  return (
    <PageSection loading={loading}>
      <SectionTitle>Account Info</SectionTitle>

      {account && (
        <React.Fragment>
          <FactLabel>Account Name:</FactLabel>
          <FactTextValue emphasis>{account.name}</FactTextValue>

          <FactLabel>Created On:</FactLabel>
          <FactTextValue><LocaleDatetime value={account.created_at} /></FactTextValue>

          <FactLabel>Account Status:</FactLabel>
          <FactTextValue>
            <AccountStatus value={account.is_active} />
          </FactTextValue>

        </React.Fragment>
      )}
    </PageSection>
  );
};

const AccountDashboard = ({ accountId }: { accountId: number }) => {
  const [account, loading] = useGetOnce<AccountType>(`account/${accountId}`);

  return (
    <>
      <PageHeader>
        <PageTitle
          text="Account Dashboard"
          Icon={AccountIcon}
        />
        <PageSubtitle text="Manage all aspects of your AgentVA account" />
      </PageHeader>


      <AccountInfo account={account} loading={loading} />
      <SubscriptionInfo account={account} />
      <LicenseInfo account={account} />
    </>
  );
};

export default AccountDashboard;
