import { Grid } from "@mui/material";
import { useAgentRouting } from "utils/routing";
import { AgentType } from "constants/auth.types";
import NewContactsTile from "./ContactStats";
import NewLeadsTile from "./LeadStats";
import ActiveDealsTile from "./DealStats";
import Reminders from "./Reminders";
import { useDashboardReducer, init } from "./reducer";
import { useEffect } from "react";



const Dashboard = ({agent} : {agent: AgentType}) => {
  const agentRouting = useAgentRouting(agent.id);

  const [store, dispatch] = useDashboardReducer();

  useEffect(() => {
    dispatch(init())
  }, [dispatch])

  return (
    <Grid container spacing={2} mt={2}>
      <NewContactsTile agentRouting={agentRouting} loading={store.contactLoading} stats={store.contactStats}/>
      <NewLeadsTile agentRouting={agentRouting} loading={store.leadLoading} stats={store.leadStats} />
      <ActiveDealsTile agentRouting={agentRouting} loading={store.dealLoading} stats={store.dealStats}/>
      <Reminders agentRouting={agentRouting} loading={store.leadRemindersLoading} reminders={store.leadReminders} />
    </Grid>
  )
}

export default Dashboard