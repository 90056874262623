import ButtonLink from "components/links/ButtonLink";
import { AgentType } from "constants/auth.types";
import { AgentPermissionsEnum } from "constants/permissions.enums";
import { hasPermission } from "permissions";
import MessageTemplatesList from "./MessageTemplatesList";
import { PageHeader, PageSection, PageSubtitle, PageTitle } from "components/page/PageHeader";
import EmailIcon from "@mui/icons-material/EmailOutlined"
import { useMessageBreadcrumbs } from "../message-breadcrumbs";



const MessageTemplates = ({ agent }: { agent: AgentType }) => {

  const canCreate = hasPermission(agent, AgentPermissionsEnum.CREATE_MESSAGE_TEMPLATE);

  const trail = useMessageBreadcrumbs(agent.id)
  return (
    <>
      <PageHeader breadcrumbTrail={trail}>
        <PageTitle
          Icon={EmailIcon}
          text="Message Templates"
        />
        <PageSubtitle text="Browse your library of email message templates" />
      </PageHeader>

      <PageSection>
        <ButtonLink variant="contained" to="./new" disabled={!canCreate}>New Template</ButtonLink>
      </PageSection>
      <MessageTemplatesList agent={agent} />
    </>
  )
}

export default MessageTemplates;