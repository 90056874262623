import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import InputIcon from "@mui/icons-material/Input";
import { useFormCreate } from "hooks/api-hooks";
import { Form } from "components/form/Form";
import { FieldTypeEnum, FormSubmitHandler } from "components/form/types";
import { FieldConfig } from "components/form/field-config";
import { PageSection, } from "components/page/PageHeader";
import { Paragraph, SectionTitle } from "components/CardFact";
import { Grid } from "@mui/material";

const fields: FieldConfig[] = [
  {
    name: "email",
    type: FieldTypeEnum.INPUT,
    label: "Email",
    placeholder: "email",
    required: true,
  },
  {
    name: "first_name",
    type: FieldTypeEnum.INPUT,
    label: "First Name",
    placeholder: "First Name",
    required: true,
  },
  {
    name: "last_name",
    type: FieldTypeEnum.INPUT,
    label: "Last Name",
    placeholder: "First Name",
    required: true,
  },
  {
    name: "name",
    type: FieldTypeEnum.INPUT,
    label: "Account Name",
    placeholder: "Agent Name",
    required: true,
  },
  {
    name: "password",
    type: FieldTypeEnum.PASSWORD,
    label: "Account Password",
    required: true,
  },
  {
    name: "registration_code",
    type: FieldTypeEnum.INPUT,
    label: "Early-access Registration Code",
    help: "Early access to AgenVA is by invitation only.",
    required: true,
  },
];

const RegistrationForm = ({
  onRegistered,
}: {
  onRegistered: (email: string) => void;
}) => {
  const [onRegister, registering] = useFormCreate("account");

  const handleSubmit: FormSubmitHandler = (formData, setFormErrors) => {
    onRegister(formData)
      .then(() => onRegistered(formData.email))
      .catch((errs) => {
        setFormErrors(errs);
      });
  };

  return (
    <Grid container justifyContent="center" width={"100%"}>
      <Grid item xs={12} sm={10}>
        <PageSection>
          <SectionTitle>Account Sign-up</SectionTitle>
          <Form
            fields={fields}
            onSubmit={handleSubmit}
            saving={registering}
            submitIcon={<InputIcon />}
            submitText="Register"
            initialValues={{}}
          />
          <Paragraph>
            Already have an account?{" "}
            <Link component={RouterLink} to="/login">
              Sign in here.
            </Link>{" "}
          </Paragraph>
        </PageSection>
      </Grid>
    </Grid>
  );
};

const Register = () => {
  const navigate = useNavigate();

  const handleRegistered = (email: string) => {
    navigate(`/login?email=${encodeURIComponent(email)}`);
  };

  return (
    <>
      <RegistrationForm onRegistered={handleRegistered} />
    </>
  );
};

export default Register;
