
import { Trail } from "components/BreadcrumbTrail";

export const useMessageBreadcrumbs = (
  agentId: number,
  templateId?: number|null
): Trail => {
  return [
    { to: `/agent/${agentId}/messages`, label: "Dashboard" },
    { to: `/agent/${agentId}/messages/templates`, label: "Templates" },
    templateId ? { to: `/agent/${agentId}/messages/templates/${templateId}`, label: "Template" } : null,
  ];
}
