import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { Typography } from "@mui/material";
import TextLink from "components/TextLink";
import useAuth from "../../auth";
import { Navigate } from "react-router-dom";

const ContactForm = () => {
  return (
    <section>
      <Typography variant="h5" gutterBottom>
        Trial Sign-Up
      </Typography>
      <Typography mb={2} gutterBottom variant="body1">
        Ready to get started?{" "}
        <TextLink to="/buy">Start your free trial today.</TextLink>
      </Typography>

      <Typography variant="h5" gutterBottom>
        Contact Us
      </Typography>
      <form action="/api/v1/sales/contact" method="POST">
        <input
          type="hidden"
          name="redirect_url"
          value="https://agentva.ca?success"
        />
        <label htmlFor="email">Email</label>
        <br />
        <input id="email" type="email" name="email" required />
        <br />
        <label htmlFor="phone_number">Phone Number</label>
        <br />
        <input
          id="phone_number"
          type="tel"
          name="phone_number"
          maxLength={50}
        />
        <br />

        <label htmlFor="full_name">Name</label>
        <br />
        <input id="full_name" type="text" name="full_name" maxLength={150} />

        <br />
        <label htmlFor="message">Message</label>
        <br />
        <textarea id="message" name="message" maxLength={1000} />
        <br />
        <button type="reset">Clear</button>
        <button type="submit">Submit</button>
      </form>
      <p>
        Already have an account?{" "}
        <Link component={RouterLink} to="/login">
          Sign in here.
        </Link>
      </p>
    </section>
  );
};

const ContactSales = () => {
  const { auth } = useAuth();
  if (auth) {
    return <Navigate to="/user/home" />;
  }
  return <ContactForm />;
};

export default ContactSales;
