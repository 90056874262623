import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSearchParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import useAuth from "../../auth";
import { getHomeRoute } from "./HomeRedirect";


const resolveNextRediect = (auth, next) => {
    if(next) return decodeURIComponent(next);
    return getHomeRoute(auth);
}

const isScopeMatch = (auth, account_id, agent_id) => {
  if(!auth) return false;
  if(agent_id) return agent_id === auth.agent_id;
  if(account_id) return (account_id === auth.account_id && !auth.agent_id);
  return !(auth.account_id || auth.agent_id)
}

const ChangeAuthScope = () => {

  const {auth, getAuth, isLoading} = useAuth();
  const [searchParams]  = useSearchParams();
  const [error, setError] = useState('');
  const navigate = useNavigate();


  const account_id = parseInt(searchParams.get('account'), 10) || null
  const agent_id = parseInt(searchParams.get('agent'), 10) || null
  const next = searchParams.get('next')


  useEffect(() => {
    console.info(`Changing auth scope to Account (${account_id}), Agent (${agent_id})`);

    if(auth === false) {
      // TODO: Preserve 'next' url and pass along account/agent ID scope
      navigate('/');
      return
    }

    if(isScopeMatch(auth, account_id, agent_id)) {
      const to = resolveNextRediect(auth, next);
      if(to) navigate(to, {replace: true});
      return
    } 

    const scope = {account_id, agent_id}
    getAuth(scope).catch(err => {
      if(err.response.status === 403){
        setError('You do not have access to view this page.');
      } else if(err.response.status === 401){
        setError('You must be logged in to view this page');
      } else {
        setError('Oops! Something went wrong.')
      }
    })
  }, [agent_id, account_id, navigate, next, getAuth, auth])

  return (
    <>
      <Typography variant="h3" gutterBottom>
        Logging in to your account...
      </Typography>
      {isLoading && <CircularProgress />}
      {error}
    </>
  )
};

export default ChangeAuthScope;