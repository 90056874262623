
import React from 'react';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import PageNotFound from "components/errors/PageNotFound";
import Accounts from './Accounts';
import HomeRedirect from '../HomeRedirect';
import useAuth from "../../../auth";

const UserRoutes = () => {

  const {auth} = useAuth();
  const location = useLocation();

  if(!auth){
    const nextLocation = `${location.pathname}${location.search}`;
    return (
    <Navigate 
      to={`/auth/scope?account=&next=${encodeURIComponent(nextLocation)}`} 
      state={{replace: true}}
    />
    )
  }

  return (
      <Routes>
        <Route path='home' element={<HomeRedirect />} />
        <Route path='accounts' element={<Accounts />} />
        <Route path="*" element={<PageNotFound />}/>
      </Routes>
  );
}

export default UserRoutes;
