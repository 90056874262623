import api from "api";
import { PageResult } from "constants/common.types";
import { ReminderWithLeadAndContactType } from "constants/lead.types";
import useThunkReducer, {
  Dispatch,
  MergeState,
  GetState,
} from "hooks/thunk-reducer";

export type Trend = {
  amount: number;
  percent: number;
};

export type ContactGrowthStats = {
  weekly_trend: Trend;
  monthly_trend: Trend;
  total: number;
};

export type LeadGrowthStats = {
  weekly_trend: Trend;
  monthly_trend: Trend;
  total: number;
};

export type DealGrowthStats = {
  yearly_trend: Trend;
  monthly_trend: Trend;
  active_total: number;
  closed_total: number;
};

export type ReducerState = {
  contactLoading: boolean;
  contactStats: null | ContactGrowthStats;
  leadLoading: boolean;
  leadStats: null | LeadGrowthStats;
  dealLoading: boolean;
  dealStats: null | DealGrowthStats;
  leadRemindersLoading: boolean;
  leadReminders: null | PageResult<ReminderWithLeadAndContactType>;
};

const getInitialState = (): ReducerState => {
  return {
    contactLoading: false,
    contactStats: null,
    leadLoading: false,
    leadStats: null,
    dealLoading: false,
    dealStats: null,
    leadRemindersLoading: false,
    leadReminders: null,
  };
};

export type DashboardDispatch = Dispatch<ReducerState>;

type DashboardAction<R = void> = (
  mergeState: MergeState<ReducerState>,
  getState: GetState<ReducerState>
) => R;

export const useDashboardReducer = () => useThunkReducer(getInitialState());

export const init =
  (): DashboardAction<Promise<void>> => (mergeState, getState) => {
    return Promise.all([
      getContactStats()(mergeState, getState),
      getLeadStats()(mergeState, getState),
      getDealStats()(mergeState, getState),
      getLeadReminders()(mergeState, getState),
    ]).then();
  };

export const getContactStats =
  (): DashboardAction<Promise<void>> => async (mergeState, getState) => {
    mergeState({ contactLoading: true });

    try {
      const contactStats = await api.get<ContactGrowthStats>(
        `contact/stats/growth`
      );
      mergeState({ contactLoading: false, contactStats: contactStats.data });
    } catch {
      mergeState({ contactLoading: false });
    }
  };

export const getLeadStats =
  (): DashboardAction<Promise<void>> => async (mergeState, getState) => {
    mergeState({ leadLoading: true });

    try {
      const stats = await api.get<LeadGrowthStats>(`lead_contact/stats/growth`);
      mergeState({ leadLoading: false, leadStats: stats.data });
    } catch {
      mergeState({ leadLoading: false });
    }
  };

export const getDealStats =
  (): DashboardAction<Promise<void>> => async (mergeState, getState) => {
    mergeState({ dealLoading: true });

    try {
      const stats = await api.get<DealGrowthStats>(`deal/stats/growth`);
      mergeState({ dealLoading: false, dealStats: stats.data });
    } catch {
      mergeState({ dealLoading: false });
    }
  };

export const getLeadReminders =
  (): DashboardAction<Promise<void>> => async (mergeState, getState) => {
    mergeState({ leadRemindersLoading: true });
    try {
      const resp = await api.get<PageResult<ReminderWithLeadAndContactType>>(
        `lead_contact_reminder`, 
        {params: {
          ordering: 'remind_date',
          page_size: 2
        }});
      mergeState({ leadRemindersLoading: false, leadReminders: resp.data });
    } catch {
      mergeState({ leadRemindersLoading: false});
    }
  };
