import { PageHeader, PageSubtitle, PageTitle } from "components/page/PageHeader";
import DashboardIcon from "@mui/icons-material/Apps"
import { AgentType } from "constants/auth.types";
import Dashboard from "./dashboard/Dashboard";



const AgentDashboard = ({agent} : {agent: AgentType}) => {
  return (
    <>
      <PageHeader>
        <PageTitle
          text="Agent Dashboard"
          Icon={DashboardIcon}
        />
        <PageSubtitle text="Stay organized. Close deals. Profit." />
        <Dashboard agent={agent} />
      </PageHeader>
    </>
  )
};

export default AgentDashboard;