import { Typography } from "@mui/material"
import TrendingUpIcon from "@mui/icons-material/TrendingUp"
import TrendingDownIcon from "@mui/icons-material/TrendingDown"
import NoTrendIcon from "@mui/icons-material/Remove"
import { Trend } from "./reducer"

const compactFormatter = Intl.NumberFormat('en', { notation: 'compact' })
export const compactNumber = (value: number | null | undefined) => {
  if (value === null || value === undefined) return '-'
  return compactFormatter.format(value)
}

const percentFormatter = Intl.NumberFormat('en', { style: 'percent', signDisplay: 'exceptZero' })
export const signedPercent = (value: number | null | undefined) => {
  if (value === null || value === undefined) return '-'
  return percentFormatter.format(value / 100)
}

export const TrendDetails = ({ trend, loading }: { trend: Trend, loading: boolean }) => {
  if(loading) return <Typography component="span" color="text.disabled">- -</Typography>
  return (
    <>
      <Typography component="span">
        {signedPercent(trend.percent)}
      </Typography>
      &nbsp;&nbsp;
      <Typography component="span" color="text.disabled">
        ({compactNumber(trend.amount)})
      </Typography>
    </>
  )
}

export const TrendArrow = ({ diff }: { diff: number }) => {
  if (diff === 0) return <NoTrendIcon htmlColor="gray" />
  if (diff > 0) return <TrendingUpIcon htmlColor="green" />
  return <TrendingDownIcon htmlColor="red" />
}