import { AuthClaimsType } from "../auth";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ClickableMenuItem from "./ClickableMenuItem";
import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import AvatarIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { AgentMemberRoleLabels } from "constants/auth.enums";

const AgentMenu = ({ auth }: { auth: AuthClaimsType }) => {
  if (auth.agent === null) return null;

  return (
    <List>
      <ListItem>
        <ListItemText
          primaryTypographyProps={{ variant: "button" }}
          primary={auth.agent.name}
          secondary="Agent"
        />
      </ListItem>
      <Divider />
      <ClickableMenuItem
        label="Dashboard"
        to={`/agent/${auth.agent_id}/dashboard`}
        icon={<HomeIcon />}
      />
      <ClickableMenuItem
        label="Leads"
        to={`/agent/${auth.agent_id}/leads`}
        icon={<FilterAltIcon />}
      />
      <ClickableMenuItem
        label="Contacts"
        to={`/agent/${auth && auth.agent_id}/contacts`}
        icon={<GroupIcon />}
      />
      <ClickableMenuItem
        label="Deals"
        to={`/agent/${auth && auth.agent_id}/deals`}
        icon={<MonetizationOnIcon />}
      />
      <ClickableMenuItem
        label="Messages"
        to={`/agent/${auth && auth.agent_id}/messages`}
        icon={<EmailIcon />}
      />
      <ClickableMenuItem
        label="Settings"
        to={`/agent/${auth && auth.agent_id}/settings`}
        icon={<SettingsIcon />}
      />
      <Divider />
      <ListItem>
        <ListItemText
          primaryTypographyProps={{ variant: "button" }}
          primary={
            <>{auth.agent_role && AgentMemberRoleLabels[auth.agent_role]}</>
          }
          secondary="User Role"
        />
      </ListItem>
      <Divider />
      <ClickableMenuItem
        label="My Profile"
        to={`/agent/${auth && auth.agent_id}/profile`}
        icon={<AvatarIcon />}
      />
      <Divider />
      <ListItem>
        <ListItemText
          sx={{ wordWrap: "break-word" }}
          primaryTypographyProps={{ variant: "button" }}
          primary={auth.email}
          secondary="Signed in as"
        />
      </ListItem>
      <Divider />
      <ClickableMenuItem label="Logout" to={`/logout`} icon={<LogoutIcon />} />
    </List>
  );
};

export default AgentMenu;
