
import { AgentType } from "constants/auth.types";
import TabularList from "components/tabular-list/TabularList";
import {
  BulkAction,
  ColumnType,
} from "components/tabular-list/types";
import TextLink from "components/TextLink";
import useAdvancedSearch from "components/tabular-list/context";
import { MessageTemplateWithUsageType } from "constants/msg.types";
import { LocaleDate } from "components/locale";
import { useSearchState } from "components/tabular-list/search-state";


const columns: ColumnType[] = [
  {
    id: "Name",
    label: "Name",
    ordering: "label",
    render: ({ row }: { row: MessageTemplateWithUsageType }) => (
      <TextLink to={`./${row.id}`}>{row.label}
      </TextLink>
    )
  },
  {
    id: "date",
    label: "Created On",
    ordering: "created_at",
    render: ({ row }: { row: MessageTemplateWithUsageType }) => <LocaleDate value={row.created_at} />,
  },
  {
    id: "usage_count",
    label: "Usage Count",
    numeric: true,
    render: ({ row }: { row: MessageTemplateWithUsageType }) => `${row.usage_count}`
  },
];

const actions: BulkAction[] = [];

const MessageTemplatesList = (agent : {agent: AgentType}) => {

  const [searchState, updateSearchState] = useSearchState();
  const [ store, dispatch ] = useAdvancedSearch("message_template", searchState);

  return  (
    <section>

      <TabularList
        dispatch={dispatch}
        store={store}
        heading="My Message Templates"
        columns={columns}
        actions={actions}
        searchState={searchState}
        onUpdateSearch={updateSearchState}
      />

    </section>
  )
}

export default MessageTemplatesList;