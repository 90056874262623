import { useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import { Form } from "components/form/Form";
import { useFormCreate } from "hooks/api-hooks";
import { AgentPermissionsEnum } from "constants/permissions.enums";
import { hasPermission } from "../../../../permissions";
import PermissionDenied from "components/PermissionDenied";
import { AgentType } from "constants/auth.types";
import {
  AgentMemberRoleEnum,
  AgentMemberRoleLabels,
} from "constants/auth.enums";
import { AgentMemberRoleType } from "constants/auth.types";
import {
  FieldTypeEnum,
  FormSubmitHandler,
} from "components/form/types";
import { FieldConfig } from "components/form/field-config";
import { PageHeader, PageSection, PageSubtitle, PageTitle } from "components/page/PageHeader";
import AccessIcon from "@mui/icons-material/VpnKey";
import { SectionTitle } from "components/CardFact";

const fields: FieldConfig[] = [
  {
    name: "first_name",
    type: FieldTypeEnum.INPUT,
    label: "First Name",
    placeholder: "Jane",
    required: true,
  },
  {
    name: "last_name",
    type: FieldTypeEnum.INPUT,
    label: "Last Name",
    placeholder: "Doe",
    required: true,
  },
  {
    name: "email",
    type: FieldTypeEnum.INPUT,
    label: "Email",
    placeholder: "Email",
    required: true,
  },
  {
    name: "role",
    type: FieldTypeEnum.RADIO,
    label: "Role",
    required: true,
    options: Object.values(AgentMemberRoleEnum).map((value) => ({
      value: value,
      name: AgentMemberRoleLabels[value],
    })),
  },
];

const AgentMemberRoleForm = ({ agent }: { agent: AgentType }) => {
  const [create, saving] = useFormCreate<AgentMemberRoleType>(
    "/agent_member_role/invite"
  );
  const navigate = useNavigate();

  const onSubmit: FormSubmitHandler = (params, setFormErrors) => {
    create({
      agent_id: agent.id,
      ...params,
    })
      .then((agent_member_role) => {
        navigate(`/agent/${agent.id}/settings/access?id=${agent_member_role.id}`);
      })
      .catch(setFormErrors);
  };

  return (
    <section>
      <PageHeader>
        <PageTitle
          text="Invite Staff Member"
          Icon={AccessIcon}
        />
        <PageSubtitle text="Invite a new member of staff to view/manage this Agent" />
      </PageHeader>

      <PageSection>
        <SectionTitle>
          User Details
        </SectionTitle>
        <Form
          fields={fields}
          onSubmit={onSubmit}
          saving={saving}
          submitIcon={<SendIcon />}
          submitText="Invite & Grant Access"
          initialValues={{ role: AgentMemberRoleEnum.VIEWER }}
        />

      </PageSection>
    </section>
  );
};

const CreateAgentMemberRole = ({ agent }: { agent: AgentType }) => {
  if (!hasPermission(agent, AgentPermissionsEnum.INVITE_MEMBER)) {
    return <PermissionDenied />;
  }

  return <AgentMemberRoleForm agent={agent} />;
};

export default CreateAgentMemberRole;
