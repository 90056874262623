import { useCallback, useRef, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

// Search state is always stringly typed with assumed multiple values per key
export type SearchState = Record<string, string[]>;

const DEFAULT_PAGE_SIZE = 10;
const defaultPaginationState = {
  page_size: [`${DEFAULT_PAGE_SIZE}`],
  offset: ["0"]
}

export const useSearchState = (defaultState: SearchState = {}) : [SearchState, (stateUpdates: SearchState) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const stateRef = useRef<SearchState>({});

  const state: SearchState = useMemo(() => {
    const state : SearchState = {...defaultPaginationState, ...defaultState};
    const seenKeys = new Set<string>();
    for (const [key, value] of searchParams.entries()) {
      if (seenKeys.has(key)) {
        // Don't extend default values,a lways replace
        if (value !== "") state[key].push(value);
      } else {
        if (value !== "") {
          state[key] = [value];
        } else {
          state[key] = [];
        }
      }
    }
    return state;

    // Avoid accidental infinite render when object passed in
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]); 

  stateRef.current = state
  const updateSearchState = useCallback((stateUpdates: SearchState) => {
    setSearchParams({
      ...stateRef.current,
      offset: ["0"],  // Any search change should reset page back to zero
      ...stateUpdates
    }) 
  }, [setSearchParams])

  return [state, updateSearchState]
};
