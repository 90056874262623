
export enum MessageDeliveryStatusEnum {
  DRAFT = 'draft',
  SENDING = 'sending',
  SENT = 'sent',
  SENT_MANUAL = 'sent_manual',
  ERROR = 'error',
}


export const MessageDeliveryStatusLabels: Record<MessageDeliveryStatusEnum, string> = {
  [MessageDeliveryStatusEnum.DRAFT]: 'Not Sent',
  [MessageDeliveryStatusEnum.SENDING]: 'Sending',
  [MessageDeliveryStatusEnum.SENT]: 'Sent',
  [MessageDeliveryStatusEnum.SENT_MANUAL]: 'Sent (manual)',
  [MessageDeliveryStatusEnum.ERROR]: 'Failed to Send',
}

export enum MessageTemplatePermissionsEnum {
  EDIT = 'message_template:edit',
  DELETE = 'message_template:delete'
}

export enum ReplacementContextEnum {
  SUBJECT = 'subject',
  BODY = 'body'
}