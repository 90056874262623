import {
  AgentMemberRoleAccessType,
  AgentType,
} from "constants/auth.types";
import { hasPermission } from "../../../../permissions";
import { AgentPermissionsEnum } from "constants/permissions.enums";
import PermissionDenied from "components/PermissionDenied";
import TabularList from "components/tabular-list/TabularList";
import {
  BulkAction,
  ColumnType,
} from "components/tabular-list/types";
import { LocaleDatetime } from "components/locale";
import { AgentMemberRoleLabels } from "constants/auth.enums";
import ButtonLink from "components/links/ButtonLink";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../../../api";
import BulkActionDialog from "components/bulk-action-dialog/BulkActionDialog";
import { createBulkActionHandler } from "components/bulk-action-dialog/handlers";
import useAdvancedSearch, { bulkActionCompleted } from "components/tabular-list/context";
import { personName } from "utils/names";
import { useSearchState } from "components/tabular-list/search-state";
import { PageHeader, PageSection, PageSubtitle, PageTitle } from "components/page/PageHeader";
import AccessIcon from "@mui/icons-material/VpnKey";
import { ActionableSectionTitle, Paragraph } from "components/CardFact";
import { useSettingsBreadcrumbs } from "./agent-settings-breadcrumbs";

const columns: ColumnType[] = [
  {
    id: "email",
    numeric: false,
    label: "E-mail",
    ordering: "email",
    render: ({ row }) => row.account_member.user.email,
  },
  {
    id: "Name",
    numeric: false,
    label: "Name",
    ordering: "name",
    render: ({ row }) => personName(row.account_member),
  },
  {
    id: "role",
    numeric: false,
    label: "Role",
    ordering: "role",
    render: ({ row }: { row: AgentMemberRoleAccessType }) =>
      row.role ? AgentMemberRoleLabels[row.role] : "(Revoked)",
  },
  {
    id: "created_at",
    numeric: false,
    label: "Granted",
    ordering: "created_at",
    render: ({ row }) => <LocaleDatetime value={row.created_at} />,
  },
];

const actions: BulkAction[] = [
  {
    name: "revoke",
    label: "Revoke",
    icon: <DeleteIcon />,
    visible: true,
  },
];

const AgentMemberRolesTable = ({ agent }: { agent: AgentType }) => {
  const [searchState, updateSearchState] = useSearchState();
  const [state, dispatch] = useAdvancedSearch(
    `agent_member_role/agent/${agent.id}/access`,
    searchState
  );

  const canInvite = hasPermission(agent, AgentPermissionsEnum.INVITE_MEMBER);

  const { actionSelection } = state;

  return (
    <>
      <PageSection>
        <ActionableSectionTitle
          actions={
            <ButtonLink variant="contained" disabled={!canInvite} to={`/agent/${agent.id}/settings/access/invite`}>
              Invite User
            </ButtonLink>
          }
        >
          Staff Access
        </ActionableSectionTitle>
        <Paragraph>
          Need help keeping on top of your tasks? Invite staff to help you share the workload.
        </Paragraph>
      </PageSection>


      <TabularList
        dispatch={dispatch}
        store={state}
        heading=""
        columns={columns}
        actions={actions}
        searchState={searchState}
        onUpdateSearch={updateSearchState}
      />

      {actionSelection?.action?.name === "revoke" && (
        <BulkActionDialog
          actionSelection={actionSelection}
          onClose={(actionedIds) => dispatch(bulkActionCompleted(actionedIds))}
          onConfirm={revokeHandler}
        />
      )}
    </>
  );
};

const AgentMemberRoles = ({ agent }: { agent: AgentType }) => {
  const trail = useSettingsBreadcrumbs(agent.id);
  if (!hasPermission(agent, AgentPermissionsEnum.BROWSE_MEMBERS))
    return <PermissionDenied />;
  return (
    <>
      <PageHeader breadcrumbTrail={trail}>
        <PageTitle
          text="Staff Access"
          Icon={AccessIcon}
        />
        <PageSubtitle text="Control who can access this Agent and what they can do." />
      </PageHeader>
      <AgentMemberRolesTable agent={agent} />
    </>);
};

/* Bulk Action Handlers */
const revokeHandler = createBulkActionHandler((item: string | number) => {
  return axios
    .delete(`agent_member_role/${item}`)
    .then(() => "Access Revoked.")
    .catch(() => {
      throw Error("Unable to revoke access.");
    });
});

export default AgentMemberRoles;
