import {
  OutlinedInput,
  InputLabel,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { usePostUpload } from "hooks/api-hooks";
import { FieldProps, FieldConfigI, FieldTypeEnum } from "./types";
import { Cancel, UploadFile, Check } from "@mui/icons-material";
import { withFormControl } from "./FormComponent";
import { useRef, useEffect } from "react";
import { UploadedFile } from "constants/media.types";


export const InteractiveFileInput = ({
  error,
  name,
  label,
  onChange,
  onCancel,
  uploading,
  value,
  accept,
}: {
  error: boolean;
  name: string;
  label: string;
  onChange: (e: any) => void;
  onCancel: (e: any) => void;
  value?: any;
  accept?: string;
  uploading: boolean;
}) => {
  const elementRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Reset file field on cancel/reset to clear the 'filename' text
    if (!value && elementRef.current) {
      elementRef.current.value = '';
    }
  }, [value]);

  useEffect(() => {
    // Reset file field on error to clear the 'filename' text
    if (error && elementRef.current) {
      elementRef.current.value = '';
    }
  }, [error]);

  return (
    <>
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <OutlinedInput
        error={error}
        notched
        id={name}
        type="file"
        inputProps={{
          ref: elementRef,
          disabled: uploading,
          accept,
        }}
        startAdornment={
          <InputAdornment position="start">
            {uploading ? (
              <IconButton tabIndex={-1}>
                <CircularProgress size={20} />
              </IconButton>
            ) : (
              <UploadFile />
            )}
          </InputAdornment>
        }
        endAdornment={
          uploading ? (
            <InputAdornment position="start">
              <IconButton tabIndex={-1} onClick={onCancel}>
                <Cancel color="error" />
              </IconButton>
            </InputAdornment>
          ) : value ? (
            <InputAdornment position="start">
              <IconButton tabIndex={-1} onClick={onCancel}>
                <Check color="success" />
              </IconButton>
            </InputAdornment>
          ) : null
        }
        onChange={onChange}
        label={label}
      />
    </>
  );
};

const ExistingFileField = ({
  name,
  label,
  fileName,
  onClear,
  error
}: {
  name: string;
  label: string;
  fileName: string;
  error: boolean;
  onClear: () => void;
}) => {
  return (
    <>
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <OutlinedInput
        label={label}
        error={error}
        notched
        id={name}
        type="input"
        inputProps={{
          value: fileName,
          disabled: true,
        }}
        endAdornment={
          <InputAdornment position="start">
            <IconButton tabIndex={-1} onClick={onClear}>
              <Cancel color="error" />
            </IconButton>
          </InputAdornment>
        }
      />
    </>
  );
};

export interface FileUploadFieldConfig extends FieldConfigI {
  type: FieldTypeEnum.FILE;
  accept: string;
  endpoint: string;
  maxSizeMB: number;
}

interface FileUploadFieldProps extends FieldProps {
  value: UploadedFile;
  onChange: (arg0: Record<string, UploadedFile | null>) => void;
  config: FileUploadFieldConfig;
}
const FileUploadField = ({
  value,
  onChange,
  config,
  error,
  onError,
}: FileUploadFieldProps) => {
  const [upload, uploading, cancel] = usePostUpload(config.endpoint);

  const handleChange = (e: any) => {
    const file = e.target.files[0];

    // Ensure error/value state is reset as soon as user supplies a new file
    onError({ [config.name]: [] });
    onChange({ [config.name]: null });

    if (!file) return;

    if (file.size / 1e6 > config.maxSizeMB) {
      onError({
        [config.name]: [`Exceeds maxinum file size: ${config.maxSizeMB}MB`],
      });
      return;
    }

    upload(file)
      .then((uploadedFile) => {
        onChange({ [config.name]: uploadedFile });
      })
      .catch((e) => {
        onChange({ [config.name]: null });
        onError({ [config.name]: [e.toString()] });
      });
  };

  const handleCancel = () => {
    cancel();
    onChange({ [config.name]: null });
    onError({ [config.name]: ["Upload cancelled"] });
  };

  const handleClear = () => {
    onChange({ [config.name]: null });
  };

  if (value) return (
    <ExistingFileField
      error={error}
      onClear={handleClear}
      name={config.name}
      label={config.label}
      fileName={value.original_filename}
    />
  )
  return (
    <InteractiveFileInput
      error={error}
      accept={config.accept}
      onCancel={handleCancel}
      onChange={handleChange}
      uploading={uploading}
      name={config.name}
      label={config.label}
    />
  );
};

export default withFormControl(FileUploadField);
