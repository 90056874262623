import { AgentType } from "constants/auth.types";
import TabularList from "components/tabular-list/TabularList";
import {
  BulkAction,
  ColumnType,
} from "components/tabular-list/types";
import TextLink from "components/TextLink";
import useAdvancedSearch from "components/tabular-list/context";
import { DeliveryStats, EmailRecipientType, SentMessageType } from "constants/msg.types";
import { LocaleDate } from "components/locale";
import { useSearchState } from "components/tabular-list/search-state";

const DeliveryStatus = ({ stats }: { stats: DeliveryStats }) => {
  const { total, sent } = stats;
  let value: string = 'Drafted'

  if (total) {
    if (total > sent) {
      value = 'Sent'
    } else {
      value = `${sent}/${total} Sent`
    }
  }

  return <>{value}</>
}

const LatestRecipient = ({ recipient, agentId }: { recipient: EmailRecipientType, agentId: number }) => {
  const { contact_id, email} = recipient;

  if(contact_id){
    return <TextLink to={`/agent/${agentId}/contacts/${contact_id}`}>{email}</TextLink>
  }

  return <>{email}</>
}


const columns: ColumnType[] = [
  {
    id: "Name",
    label: "Name",
    render: ({ row }: { row: SentMessageType }) => (
      <TextLink to={`../${row.id}`}>{row.subject}
      </TextLink>
    )
  },
  {
    id: "date",
    ordering: 'created_at',
    label: "Drafted On",
    render: ({ row }: { row: SentMessageType }) => <LocaleDate value={row.created_at} />,
  },
  {
    id: "status",
    label: "Status",
    render: ({ row }: { row: SentMessageType }) => <DeliveryStatus stats={row.delivery_stats} />,
  },
  {
    id: "latest_recipient",
    label: "Latest Recipient ",
    render: ({ row }: { row: SentMessageType }) => row.latest_recipient ? <LatestRecipient agentId={row.agent_id} recipient={row.latest_recipient} /> : '-',
  },
];

const actions: BulkAction[] = [];

const SentMessagesList = ({agent}: { agent: AgentType }) => {

  const [searchState, updateSearchState] = useSearchState();
  const [state, dispatch] = useAdvancedSearch("message/sent", searchState);

  return (
      <TabularList
        dispatch={dispatch}
        store={state}
        heading="Sent Messages"
        columns={columns}
        actions={actions}
        searchState={searchState}
        onUpdateSearch={updateSearchState}
      />
  )
}

export default SentMessagesList