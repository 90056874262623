import { Trail } from "components/BreadcrumbTrail";

export const useEmailTemplateBreadcrumbs = (agentId: number, emailTemplateId?: number) : Trail=> {
  return [
    { to: `/agent/${agentId}/settings`, label: "Settings" },
    { to: `/agent/${agentId}/settings/email-signatures`, label: "All Templates" },
    emailTemplateId
      ? {
        to: `/agent/${agentId}/settings/email-signatures/${emailTemplateId}`,
        label: "Template",
      }
      : null,
  ];

}
