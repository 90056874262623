import axios from "axios";
import qs from "qs";

const api  = axios.create({
  baseURL: `${window.location.protocol}//${window.location.host}/api/v1/`,
  withCredentials: true,
  validateStatus: function (status: number) {
    return status >= 200 && status < 400; // default
  },
  paramsSerializer: (params: Record<string, any>) => qs.stringify(params, {arrayFormat: 'repeat'})
})

export default api;