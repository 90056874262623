import Dialog from "@mui/material/Dialog";
import { BulkActionSelection } from "../tabular-list/types";
import { useRef, useState } from "react";
import { ActionResult } from "./handlers";
import ConfirmDialog from "./ConfirmDialog";
import InProgressDialog from "./InProgressDialog";
import DoneDialog from "./DoneDialog";

type BulkActionDialogProps = {
  actionSelection: BulkActionSelection;
  onClose: (actionedIds: Array<number | string>) => void;
  onConfirm: (
    actionSelection: BulkActionSelection,
    formData: any,
    setProgress: (progress: number) => void,
    cancelRef: React.MutableRefObject<boolean>
  ) => Promise<ActionResult[]>;
};
const BulkActionDialog = ({
  actionSelection,
  onClose,
  onConfirm,
}: BulkActionDialogProps) => {
  const [progress, setProgress] = useState<number | null>(null);
  const [results, setResults] = useState<ActionResult[] | null>(null);
  const cancelRef = useRef<boolean>(false);

  const handleConfirm = () => {
    setProgress(0);
    onConfirm(actionSelection, {}, setProgress, cancelRef).then(setResults);
  };

  const isDone = () => results !== null;
  const isInProgress = () => progress !== null && !isDone();

  const handleClose = () => {
    const actionedIds = results
      ? actionSelection.selected.filter((item, idx) => results[idx]?.success)
      : [];
    onClose(actionedIds);
  };

  const renderContents = () => {
    if (isDone())
      return <DoneDialog onClose={handleClose} results={results || []} />;
    if (isInProgress())
      return (
        <InProgressDialog
          progress={progress || 0}
          onCancel={() => {
            cancelRef.current = true;
          }}
        />
      );
    return <ConfirmDialog onClose={handleClose} onConfirm={handleConfirm} />;
  };

  return (
    <Dialog open onClose={isInProgress() ? undefined : handleClose}>
      {renderContents()}
    </Dialog>
  );
};

export default BulkActionDialog;
