import { Descendant } from "slate"
import { CustomElement, ElementTypesEnum, VariableElement } from "../types"
import Link from "./Link"
import Variable from "./Variable"


export const Element = ({
  readOnly,
  attributes,
  children,
  element,
  onResolve,
}: {
  readOnly: boolean,
  attributes: any,
  children: Descendant[],
  element: CustomElement,
  onResolve?: (arg0: VariableElement) => void
}) => {
  const style = { textAlign: element.align }
  switch (element.type) {
    case ElementTypesEnum.BLOCK_QUOTE:
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case ElementTypesEnum.BULLETED:
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case ElementTypesEnum.HEADING_ONE:
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case ElementTypesEnum.HEADING_TWO:
      return (
        <h4 style={style} {...attributes}>
          {children}
        </h4>
      )
    case ElementTypesEnum.LIST_ITEM:
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case ElementTypesEnum.NUMBERED:
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    case ElementTypesEnum.VARIABLE_PLACEHOLDER:
      return <Variable
        attributes={attributes}
        children={children}
        id={element.id}
        alias={element.alias}
        onResolve={onResolve}
      />
    case ElementTypesEnum.LINK:
      return <Link readOnly={readOnly} attributes={attributes} children={children} href={element.href} />
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}






export default Element;