import {
  Link as RouterLink,
  useSearchParams,
} from "react-router-dom";
import Link from "@mui/material/Link";
import InputIcon from "@mui/icons-material/Input";
import useAuth from "../../auth";
import { Form } from "components/form/Form";
import { FieldTypeEnum, FormSubmitHandler } from "components/form/types";
import { FieldConfig } from "components/form/field-config";
import { Grid } from "@mui/material";
import { formErrorsFromResponse } from "components/form/errors";
import { PageSection } from "components/page/PageHeader";
import { Paragraph, SectionTitle } from "components/CardFact";

const fields: FieldConfig[] = [
  {
    name: "email",
    type: FieldTypeEnum.INPUT,
    label: "Email",
    placeholder: "email",
    required: true,
  },
  {
    name: "password",
    type: FieldTypeEnum.PASSWORD,
    label: "Password",
    required: true,
  },
];

const PasswordLoginForm = ({ email }: { email: string }) => {
  const { login, isLoading } = useAuth();

  const handleSubmit: FormSubmitHandler = (formData, setFormErrors) => {
    login(formData).catch(e => {
      setFormErrors(formErrorsFromResponse(e.response));
    });
  };


  return (
    <Grid container justifyContent="center" width={"100%"}>
      <Grid item xs={12} sm={10}>
        <PageSection>
          <SectionTitle>Login with Password</SectionTitle>
          <Form
            fields={fields}
            onSubmit={handleSubmit}
            saving={isLoading}
            submitIcon={<InputIcon />}
            submitText="Login"
            initialValues={{ email }}
          />
          <Paragraph>
            Don't have an account?{" "}
            <Link component={RouterLink} to="/buy">
              Sign up here.
            </Link>
          </Paragraph>
          <Paragraph>
            Forgot your password?{" "}
            <Link component={RouterLink} to="/password-recovery">
              Recover your password here.
            </Link>
          </Paragraph>
        </PageSection>
      </Grid>
    </Grid>
  );
};

const Login = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") || "";

  return (
    <>
      <PasswordLoginForm email={email} />
    </>
  );
};

export default Login;
