import { useCallback, useRef, useState } from "react";

export type MergeState<TState> = (values: Partial<TState>) => void;
export type GetState<TState> = () => Readonly<TState>;

/** A thunk action that can be dispatched by the {@link useThunkReducer}. */
type ThunkAction<TState> = (
  mergeState: MergeState<TState>,
  getState: GetState<TState>,
) => any;


export type Dispatch<TState> = <Func extends ThunkAction<TState>>(action: Func) => ReturnType<Func>;


/**
 * Asynchronous action reducer inspired by the popular [Redux Thunk Middleware](https://github.com/reduxjs/redux-thunk).
 *
 * @param initialState
 */
export const useThunkReducer = function <TState>(initialState: TState): [TState, Dispatch<TState>] {
  const [state, setState] = useState<TState>(initialState);

  const stateRef = useRef<TState>(state);


  const dispatch = useCallback((action: ThunkAction<TState>) => {
    const mergeState : MergeState<TState> = (values) => {
      const nextState : TState = {...stateRef.current, ...values};
      stateRef.current = nextState;
      setState(nextState as TState)
    }

    return action(mergeState, () => stateRef.current);

  }, []);

  return [state, dispatch];
};

export default useThunkReducer;