import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MoreVert } from "@mui/icons-material";
import { Button } from '@mui/material';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { Link } from 'react-router-dom';

export type ActionItem = {
  id: string,
  onClick: string | (() => void),
  text: string,
  variant?: 'danger' | 'default',
  disabled?: boolean,
  hidden?: boolean,
}


const ActionMenu = ({
  actions,
  popupId = 'actions'
}: {
  actions: ActionItem[],
  popupId?: string
}) => {

  const popupState = usePopupState({ variant: "popover", popupId });

  const renderAction = (action: ActionItem) => {


    if(action.hidden) return null;


    const props = {
      disabled: action.disabled || false,
      key: action.id,
      sx: { color: action.variant === 'danger' ? 'error.main' : 'text.primary' }
    }

    const handleClick = () => {
      (action.onClick as () => void)();
    }

    if (typeof (action.onClick) === 'string') {
      return <MenuItem {...props} component={Link} to={action.onClick}>
        {action.text}
      </MenuItem>
    }
    return <MenuItem {...props} onClick={handleClick}>
      {action.text}
    </MenuItem>


  }

  return (
    <>
      <Button variant="outlined" endIcon={<MoreVert />} color="primary" {...bindTrigger(popupState)}>
        Actions
      </Button >
      <Menu {...bindMenu(popupState)}>
        {actions.map(renderAction)}
      </Menu>
    </>

  )
}


export default ActionMenu;