import { alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import { BulkAction } from "./types";

type EnhancedTableToolbarProps = {
  numSelected: number;
  heading: string;
  loading: boolean;
  actions: BulkAction[];
  onAction: (action: BulkAction) => void;
};
export default function EnhancedTableToolbar({
  numSelected,
  heading,
  loading,
  actions,
  onAction,
}: EnhancedTableToolbarProps) {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flexGrow: 0, display: "flex" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          // sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {heading}
          {loading && <CircularProgress size={20} sx={{ ml: 1 }} />}
        </Typography>
      )}

      {numSelected > 0 && (
        <ActionTray actions={actions} onAction={onAction} />
      )}
    </Toolbar>
  );
}

const mediumAndLarger = { display: { xs: "none", md: "block" } };
const smallOnly = { display: { xs: "block", md: "none" } };

const ActionItem = ({
  action,
  onClick,
}: {
  action: BulkAction;
  onClick: () => void;
}) => {
  return (
    <Tooltip title={action.label}>
      <>
        <Button sx={mediumAndLarger} onClick={onClick}>
          {action.label}
        </Button>
        <IconButton sx={smallOnly} onClick={onClick}>
          {action.icon}
        </IconButton>
      </>
    </Tooltip>
  );
};

const ActionTray = ({
  actions,
  onAction,
}: {
  actions: BulkAction[];
  onAction: (action: BulkAction) => void;
}) => {
  const renderActions = () => {
    const visibleActions = actions.filter((a) => a.visible);

    if (visibleActions.length)
      return actions.map((action) => (
        <ActionItem
          key={action.name}
          action={action}
          onClick={() => onAction(action)}
        />
      ));
    return "No actions available";
  };

  return (
    <Box sx={{ flexGrow: 1, display: "flex", ml: 2 }}>{renderActions()}</Box>
  );
};
