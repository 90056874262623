import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import InputIcon from "@mui/icons-material/Input";
import { Form } from "components/form/Form";
import { useFormCreate } from "hooks/api-hooks";
import {
  FieldTypeEnum,
  FormSubmitHandler,
} from "components/form/types";
import { FieldConfig } from "components/form/field-config";
import useNotify from "notify";
import { Paragraph, SectionTitle } from "components/CardFact";
import { PageSection } from "components/page/PageHeader";
import { Grid} from "@mui/material";

const fields: FieldConfig[] = [
  {
    name: "new_password",
    type: FieldTypeEnum.PASSWORD,
    label: "New Password",
    help: 'Must be between 8 and 64 characters and contain lowercase, uppercase and numeric characters.',
    required: true,
  },
  {
    name: "confirm_password",
    type: FieldTypeEnum.PASSWORD,
    label: "New Password (Confirm)",
    help: 'Re-enter your password to make sure we get it right.',
    required: true,
  },
];



const PasswordResetForm = ({
  token,
  email,
  onSave,
}: {
  token: string;
  email: string;
  onSave: (email: string) => void;
}) => {
  const [create, saving] = useFormCreate<{ email: string }>("/reset-password");

  const handleSubmit: FormSubmitHandler = ({ new_password, confirm_password }, setFormErrors) => {
    if (new_password !== confirm_password) {
      setFormErrors({ confirm_password: ['Passwords must match'] });
    } else {

      create({
        new_password,
        token,
      })
        .then(({ email }) => onSave(email))
        .catch(setFormErrors);
    }
  };


  return (
    <Grid container justifyContent="center" width={"100%"}>
      <Grid item xs={12} sm={10}>
        <PageSection>
          <SectionTitle>Reset Password</SectionTitle>
          <Paragraph>
            Set a new password for account with email address:{" "}
            <strong>{email}</strong>
          </Paragraph>
          <Form
            fields={fields}
            initialValues={{}}
            onSubmit={handleSubmit}
            saving={saving}
            submitIcon={<InputIcon />}
            submitText="Login"
          />
        </PageSection>
      </Grid>
    </Grid>
  );
};

const PasswordReset = () => {
  const [searchParams] = useSearchParams();
  const { notify } = useNotify();
  const navigate = useNavigate();
  const email = searchParams.get("email") || "";
  const token = searchParams.get("token") || "";

  const handleSave = (email: string) => {
    notify({ severity: 'success', msg: 'Password updated! Please log in to continue' });
    navigate(`/login?${createSearchParams({ email })}`);
  };


  return <PasswordResetForm token={token} email={email} onSave={handleSave} />;
};

export default PasswordReset;
