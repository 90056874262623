import { AuthClaimsType } from "../auth";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ClickableMenuItem from "./ClickableMenuItem";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import AgentsIcon from "@mui/icons-material/Payments";
import SyncIcon from "@mui/icons-material/Sync";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

const AccountMenu = ({ auth }: { auth: AuthClaimsType }) => {
  if (auth.account === null) return null;

  return (
    <List>
      <ListItem>
        <ListItemText
          primaryTypographyProps={{ variant: "button" }}
          primary={auth.account.name}
          secondary="Account"
        />
      </ListItem>
      <Divider />
      <ClickableMenuItem
        label="Dashboard"
        to={`/account/${auth.account_id}/dashboard`}
        icon={<HomeIcon />}
      />
      <ClickableMenuItem
        label="Agents"
        to={`/account/${auth.account_id}/agents`}
        icon={<AgentsIcon />}
      />
      <ClickableMenuItem
        label="All Deals"
        to={`/account/${auth.account_id}/deals`}
        icon={<MonetizationOnIcon />}
      />
      <ClickableMenuItem
        label="Change Account"
        to={`/auth/scope?next=/user/accounts`}
        icon={<SyncIcon />}
      />
      <Divider />
      <ListItem>
        <ListItemText
          sx={{ wordWrap: "break-word" }}
          primaryTypographyProps={{ variant: "button" }}
          primary={auth.email}
          secondary="Signed in as"
        />
      </ListItem>
      <Divider />
      <ClickableMenuItem label="Logout" to={`/logout`} icon={<LogoutIcon />} />
    </List>
  );
};

export default AccountMenu;
