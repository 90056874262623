import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import GroupIcon from "@mui/icons-material/Group";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { UnstyledLink } from "components/links/ButtonLink";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { AuthClaimsType } from "../auth";

const appBarSx = {
  display: { xs: "block", sm: "none" },
  top: "auto",
  bottom: 0,
};
const toolbarSx = { justifyContent: "space-between" };
const buttonSx = { mr: 2 };

const MobileAppBar = ({
  auth,
  onDrawToggle,
}: {
  auth: AuthClaimsType;
  onDrawToggle: () => void;
}) => {

  return (
    <AppBar position="fixed" sx={appBarSx}>
      <Toolbar sx={toolbarSx}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onDrawToggle}
          sx={buttonSx}
        >
          <MenuIcon />
        </IconButton>

        {auth.agent_id && (
          <>
            <IconButton
              color="inherit"
              aria-label="contacts"
              edge="start"
              sx={buttonSx}
              component={Link}
              to={`/agent/${auth.agent_id}/contacts`}
            >
              <GroupIcon />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="deals"
              edge="start"
              sx={buttonSx}
              component={Link}
              to={`/agent/${auth.agent_id}/deals`}
            >
              <MonetizationOnIcon />
            </IconButton>
          </>
        )}

        <Typography variant="h6" component={UnstyledLink} to={`/user/home`}>
          AgentVA
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default MobileAppBar;
