import { TextField } from "@mui/material";
import { withFormControl } from "./FormComponent";
import { FieldProps, FieldConfigI, FieldTypeEnum } from "./types";

export interface PasswordFieldConfig extends FieldConfigI {
  type: FieldTypeEnum.PASSWORD;
}

interface PasswordFieldProps extends FieldProps {
  value: string;
  onChange: (arg0: Record<string, string>) => void;
  config: PasswordFieldConfig;
}

const PasswordField = ({ config, value, onChange }: PasswordFieldProps) => {
  return (
    <TextField
      name={config.name}
      label={config.label}
      type="password"
      variant="outlined"
      autoComplete="current-password"
      placeholder="password"
      value={value || ""}
      onChange={(e) => onChange({ [config.name]: e.target.value })}
    />
  );
};

export default withFormControl(PasswordField);
