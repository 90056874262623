import { FormControl, FormHelperText } from "@mui/material";
import { FunctionComponent } from "react";
import { FieldConfig } from "./field-config";
import { FormErrors } from "./types";

export const withFormControl = (WrappedComponent: FunctionComponent<any>) => {
  return ({
    fieldConfig,
    formData,
    formErrors,
    onChange,
    onError,
  }: {
    fieldConfig: FieldConfig;
    formData: Record<string, any>;
    formErrors: FormErrors;
    onChange: (arg0: Record<string, any>) => void;
    onError: (arg0: Record<string, string[]>) => void;
  }) => {
    const errors = formErrors ? formErrors[fieldConfig.name] : [];
    const value = formData[fieldConfig.name];

    const hasErrors = errors && errors.length > 0;
    const helperText = hasErrors ? errors.join(". ") : fieldConfig.help;

    return (
      <FormControl
        fullWidth
        margin="normal"
        variant="outlined"
        error={hasErrors}
      >
        <WrappedComponent
          value={value}
          error={hasErrors}
          onChange={onChange}
          onError={onError}
          config={fieldConfig}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  };
};
