import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import DrawerNavigation from "./DrawerNavigation";
import PublicLayout from "./PublicLayout";
import MobileAppBar from "./MobileAppBar";
import { NotificationSnackbar } from "../notify";
import { AuthClaimsType } from "../auth";

const drawerWidth = 240;

const drawerBoxSx = { width: { sm: drawerWidth }, flexShrink: { sm: 0 } };
const temporaryDrawerSx = {
  display: { xs: "block", sm: "none" },
  "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
};
const permanentDrawerSx = {
  display: { xs: "none", sm: "block" },
  "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
};
const mainSx = { p: 1, width: "100%" };

const ResponsiveDrawerLayout = ({
  children,
  auth,
}: {
  children: React.ReactNode;
  auth: AuthClaimsType;
}) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  if (!auth) return <PublicLayout>{children}</PublicLayout>;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const nav = <DrawerNavigation auth={auth} />;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <MobileAppBar auth={auth} onDrawToggle={handleDrawerToggle} />
      <Box
        component="nav"
        sx={drawerBoxSx}
        aria-label="mailbox folders"
        onClick={() => setMobileOpen(false)}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={temporaryDrawerSx}
        >
          {nav}
        </Drawer>
        <Drawer variant="permanent" sx={permanentDrawerSx} open>
          {nav}
        </Drawer>
      </Box>
      <Box component="main" sx={mainSx}>
        {children}
        <br />
        <br />
        <br />
      </Box>
      <NotificationSnackbar />
    </Box>
  );
};

export default ResponsiveDrawerLayout;
