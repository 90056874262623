let swReg: ServiceWorkerRegistration | null = null;

const VAPID_PUBLIC_KEY = "BFdjkr4ycAC_xWBASwp2homywOwVHSJSsLdmakuVPO-a9yke6iCd6qc9Nn3lgjI35zmobM2kcqIkVzfPbSTVJ78";


export function register() {
  if ("serviceWorker" in navigator) {
    console.log("service worker");
    window.addEventListener("load", () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      navigator.serviceWorker.register(swUrl).then((registration) => {
        swReg = registration;
        const installingWorker = swReg.installing;
        if (installingWorker == null) {
          return;
        }

        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            console.log("Service worker installed");
          }
        };
      });
    });
  }
}

function urlB64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const getPushSubscription = (): Promise<null | PushSubscription> => {
  return new Promise((resolve, reject) => {
    if (!swReg) reject();
    else {
      swReg.pushManager.getSubscription()
        .then((sub: any) => resolve(sub)).catch(() => null);
    }
  })
}

export const subscribePush = (): Promise<PushSubscription> => {
  return new Promise((resolve, reject) => {
    if (!swReg) reject();
    else {
      swReg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlB64ToUint8Array(VAPID_PUBLIC_KEY),
      }).then(sub => resolve(sub)
      )
    }
  })
}


export const unsusbscribePush = (sub: PushSubscription) => {
  return sub.unsubscribe();
}