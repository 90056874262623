import {
  createContext,
  useState,
  useMemo,
  useContext,
  useEffect,
  useCallback,
} from "react";
import axios from "./api";
import {
  AccountMemberRoleEnum,
  AgentMemberRoleEnum,
} from "constants/auth.enums";
import { AgentType, AccountType } from "constants/auth.types";

export type AuthClaimsType = {
  user_id: number;
  account_id: number | null;
  agent_id: number | null;
  account_member_id: number | null;
  account_role: AccountMemberRoleEnum | null;
  agent_role: AgentMemberRoleEnum | null;
  email: string;
  is_superuser: boolean;
  agent: AgentType | null;
  account: AccountType | null;
};

type AuthResponseType = {
  token?: string;
  claims: AuthClaimsType;
};

export type AuthContextType = {
  auth: AuthClaimsType | null | false;
  isLoading: boolean;
  login: (arg0: any) => Promise<void>;
  logout: () => Promise<any>;
  getAuth: ({
    account_id,
    agent_id,
  }: {
    account_id: number | null;
    agent_id: number | null;
  }) => void;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [auth, setAuth] = useState<AuthClaimsType | null | false>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const clearAuth = () => {
    setAuth(false);
    delete axios.defaults.headers.common["Authorization"];
  };

  const onAuth = ({ token, claims }: AuthResponseType) => {
    if (!claims) throw Error("Cannot initialize auth without token and claims");
    if (token) {
      // Setting headers must happen before updating state to avoid race conditions
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    setAuth(claims);
  };

  const initAuth = useCallback(() => {
    setLoading(true);
    return axios
      .get<AuthResponseType>("login/me")
      .then((r) => {
        onAuth(r.data);
      })
      .catch(() => {
        clearAuth();
      })
      .finally(() => setLoading(false));
  }, []);

  const getAuth = useCallback(({ account_id, agent_id } = {}) => {
    return axios
      .post<AuthResponseType>("login/scope", { account_id, agent_id })
      .then((r) => {
        onAuth(r.data);
        return r.data.claims;
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    axios.interceptors.response.use(
      (r) => r,
      (error) => {
        if (error.response && error.response.status === 401) clearAuth();
        return Promise.reject(error);
      }
    );
    initAuth();
  }, [initAuth]);

  const login = useCallback((data) => {
    return axios
      .post<AuthResponseType>("login/access-token", data)
      .then((r) => {
        onAuth(r.data);
      })
      .catch((err) => {
        clearAuth();
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const logout = useCallback(() => {
    return axios.post("logout").finally(() => {
      clearAuth();
    });
  }, []);

  const memoedValue = useMemo(
    () => ({
      auth,
      isLoading,
      login,
      logout,
      getAuth,
    }),
    [auth, isLoading, login, logout, getAuth]
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export default useAuth;
