import { useState } from "react";
import useNotify from "../../notify";

type ButtonRenderer = (props: {
  onClick: () => void;
  loading: boolean;
  disabled?: boolean;
}) => JSX.Element;

type ConfirmDialogRenderer = (props: {
  onSuccess: () => void;
  onCancel: () => void;
}) => JSX.Element;

type ApiActionButtonProps<T> = {
  renderButton: ButtonRenderer;
  loading: boolean;
  onClick: () => Promise<T>;
  onSuccess?: (result: T) => void;
  successMsg?: string;
  errorMsg?: string;
  renderConfirmDialog?: ConfirmDialogRenderer;
  disabled?: boolean
};

function ApiActionButton<T>({
  renderButton,
  loading,
  onClick,
  // TODO: These messages should really be returned by the onClick prop
  successMsg = "Success!",
  errorMsg = "Oops. Something went wrong!",
  onSuccess,
  renderConfirmDialog,
  disabled=false,
}: ApiActionButtonProps<T>) {
  const [confirming, setConfirming] = useState<boolean>(false);
  const { notify } = useNotify();

  const handleConfirm = () => {
    setConfirming(false);
    onClick()
      .then((result) => {
        notify({ msg: successMsg, severity: "success" });
        if (onSuccess) onSuccess(result);
      })
      .catch(() => notify({ msg: errorMsg, severity: "error" }));
  };

  const handleClick = () => {
    if (loading) return;
    if (renderConfirmDialog) {
      setConfirming(true);
    } else {
      handleConfirm();
    }
  };

  return (
    <>
      {renderButton({
        onClick: handleClick,
        loading,
        disabled
      })}
      {confirming &&
        renderConfirmDialog &&
        renderConfirmDialog({
          onSuccess: handleConfirm,
          onCancel: () => setConfirming(false),
        })}
    </>
  );
}


export default ApiActionButton;
