import { FormLabel, FormControlLabel, RadioGroup, Radio } from "@mui/material";
import { ValueFieldProps } from "./types";

const stringToBoolean = (str: string): boolean | null => {
  if (str === "true") return true;
  if (str === "false") return false;
  return null;
};

const booleanToString = (value: boolean | null): string => {
  if (value === true) return "true";
  if (value === false) return "false";
  return "";
};

const BoolValueField = ({ value, onChange, name }: ValueFieldProps) => {
  const label = "Value";
  const { bool_value = null } = value;
  return (
    <>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <RadioGroup
        row
        aria-label={label}
        name={name}
        value={booleanToString(bool_value)}
        onChange={(e: any) =>
          onChange({ bool_value: stringToBoolean(e.target.value) })
        }
      >
        <FormControlLabel
          key={"true"}
          value={"true"}
          control={<Radio />}
          label={"Yes"}
        />
        <FormControlLabel
          key={"false"}
          value={"false"}
          control={<Radio />}
          label={"No"}
        />
        <FormControlLabel
          key={"na"}
          value={""}
          control={<Radio />}
          label={"N/A"}
        />
      </RadioGroup>
    </>
  );
};

export default BoolValueField;
