
export type ColumnType<RowT = any> = {
  id: string;
  numeric?: boolean;
  label: string;
  ordering?: string;
  render?: (arg0: { col: ColumnType, row: RowT }) => JSX.Element | string;
};

export enum SelectionEnum {
  NONE = 0,
  SOME = 1,
  ALL = 2,
}

export enum SortDirectionEnum {
  ASC = "asc",
  DESC = "desc",
}

export type Ordering = [SortDirectionEnum | null, string | null];

export type FilterState = Record<string, string[]>;

export const NO_ORDERING: Ordering = [null, null];

export type BulkAction = {
  name: string,
  label: string,
  visible: boolean,
  icon: JSX.Element,
};

export type BulkActionSelection = {
  action: BulkAction,
  selected: Array<number | string>;
}

export type ResultItemId = number | string;


export type ReducerState = {
  searching: boolean,
  dirty: number,
  results: any[],
  selected: ResultItemId[],
  count: number,
  actionSelection: BulkActionSelection | null
}

export type Dispatchers = {
  getPage: (filters: any, page: number, pageSize: number, ordering: any) => Promise<void>;
  selectAll: () => void;
  clearSelection: () => void;
  toggleSelected: (id: ResultItemId) => void;
  bulkAction: (action: BulkAction) => void;
  bulkActionCompleted: (actionedIds: ResultItemId[]) => void;
  refresh: () => void;
}
