import {BrowserRouter, Route, Routes} from "react-router-dom";
import PublicRoutes from "./public/PublicRoutes";
import UserRoutes from "./private/user/UserRoutes";
import AccountRoutes from "./private/account/AccountRoutes";
import AgentRoutes from "./private/agent/AgentRoutes";
import Layout from "../layout/Layout";
import Logout from "./Logout";
import ChangeAuthScope from './private/ChangeAuthScope';
import useAuth from "../auth";
import WelcomeRoute from "./WelcomeRoute";


const AppRoutes = () => {

  const {auth} = useAuth();

  if(auth === null) return null;

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path='auth/scope' element={<ChangeAuthScope />} />
          <Route path="logout" element={<Logout />} />
          <Route path="user/*" element={<UserRoutes />} />
          <Route path="account/:accountId/*" element={<AccountRoutes />} />
          <Route path="agent/:agentId/*" element={<AgentRoutes />} />
          <Route path="welcome" element={<WelcomeRoute />} />
          <Route path="*" element={<PublicRoutes/>} />
        </Routes>
      </Layout>
    </BrowserRouter>
  )
}

export default AppRoutes;
