import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import HelpIcon from "@mui/icons-material/HelpOutline"

const ExpandableHelp = ({title, children}:{title: string, children: any}) => {
 return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <HelpIcon sx={{ mr: 1 }} />
        <Typography variant="body1" >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
 )
}

export default ExpandableHelp