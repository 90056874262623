import { useMemo } from "react";

type UrlPart = string|number;

export class AgentRouting {

  agentId : number

  constructor(agentId: number) {
    this.agentId  = agentId;
  }

  url = (...parts: UrlPart[]) : string => `/agent/${this.agentId}/${parts.join("/")}`

  dealsUrl = (...parts: UrlPart[]) => this.url('deals', ...parts);

  dealsDashboard = () : string => this.dealsUrl();

  dealTemplates = () : string  => this.dealsUrl('templates');

  dealTemplate = (dealTemplateId: number) => this.dealsUrl('templates', dealTemplateId)

  editDealTemplateStructure = (dealTemplateId: number) => this.dealsUrl('templates', dealTemplateId, 'structure')

  newDeal = () => this.dealsUrl('new', 'template');

  deal = (dealId: number) => this.dealsUrl(dealId);
  dealStep = (dealId: number, dealStepId: number) => this.dealsUrl(dealId, 'step', dealStepId)
  dealPhase = (dealId: number, dealPhaseId: number) => this.dealsUrl(dealId, 'phase', dealPhaseId)
  unphasedSteps = (dealId: number) => this.dealsUrl(dealId, 'steps')
  createStep = (dealId: number, dealPhaseId?: number) => this.dealsUrl(dealId, 'step', 'new') + `?deal_phase_id=${dealPhaseId||''}`

  // Contacts
  contactsUrl = (...parts: UrlPart[]) => this.url('contacts', ...parts);
  contactsDashboard = () : string => this.contactsUrl();
  newContact = () : string => this.contactsUrl('new')
  contactLeadTab = (contactId: number) : string => this.contactsUrl(contactId, 'lead')

  // Leads
  leadsUrl = (...parts: UrlPart[]) => this.url('leads', ...parts);
  leadsDashboard = () : string => this.leadsUrl();
  newLead = () : string => this.leadsUrl('new')
  leadReminders = () : string => this.leadsUrl('reminders')

  // Messages
  messagesUrl = (...parts: UrlPart[]) => this.url('messages', ...parts);
  messagesDashboard = () : string => this.messagesUrl();
}


export const useAgentRouting = (agentId: number) : AgentRouting => {
  return useMemo(() => new AgentRouting(agentId), [agentId]);
}