import React from "react";
import {Typography} from "@mui/material";

const Error = () => {

  return (
    <>
      <Typography variant="h2">Oops! Something went wrong.</Typography>
      <Typography variant="body">
        We try our best but sometimes things go wrong. We track these errors 
        but if you need to reach out for help, please include the following 
        information: TODO.
      </Typography>
    </>
  )
}

export default Error;
