import React from "react";
import { BulkActionSelection } from "../tabular-list/types";


export type ActionResult = {
  success: boolean;
  msg: string;
};

export const createBulkActionHandler =
  (
    actionFunc: (item: string | number, formData: any) => Promise<string>
  ) =>
  async (
    actionSelection: BulkActionSelection,
    formData: any,
    setProgress: (progress: number) => void,
    cancelRef: React.MutableRefObject<boolean>,
  ) => {
    let progress = 1;
    const progressPerResult = 100 / actionSelection.selected.length;
    const results: ActionResult[] = [];

    for (const item of actionSelection.selected) {
      progress += progressPerResult;
      setProgress(progress);
      if (cancelRef.current) {
          results.push({ success: false, msg: 'Skipped' });
      } else {
        try {
          const msg = await actionFunc(item, formData);
          results.push({ success: true, msg });
        } catch (err: any) {
          results.push({ success: false, msg: err?.message });
        }
      }

    }
    return results;
  };