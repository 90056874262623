/**
 * A Slate plugin to add soft breaks on return.
 */

import { Editor } from "slate";

export const softBreakHandler = (editor: Editor) => (event: any) => {
  if (event.key !== "Enter") return;
  if (event.shiftKey === false) return;
  event.preventDefault();
  editor.insertText("\n");
};
