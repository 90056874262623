import React from "react";
import {
  Routes,
  Route,
  useParams,
  useLocation,
  Navigate,
} from "react-router-dom";
import PageNotFound from "components/errors/PageNotFound";
import useAuth from "../../../auth";
import AgentDashboard from "./AgentDashboard";
import AgentSettings from "./settings/AgentSettings";
import Profile from "./profile/Profile";
import SettingsRoutes from "./settings/SettingsRoutes";
import MessagesRoutes from "./messages/MessagesRoutes";

const LazyContactRoutes = React.lazy(() => import("./contacts/Routes"));
const LazyDealRoutes = React.lazy(() => import("./deals/routes/Routes"));
const LazyLeadRoutes = React.lazy(() => import("./leads/Routes"));

const AgentRoutes = () => {
  const { auth } = useAuth();
  const params = useParams();
  const location = useLocation();
  const agentId = parseInt(params.agentId || "", 10);

  if (!agentId) return <PageNotFound />;

  const { agent, agent_id } = auth || {};

  if (!auth || agent_id !== agentId || !agent) {
    const nextLocation = `${location.pathname}${location.search}`;
    return (
      <Navigate
        to={`/auth/scope?agent=${agentId}&next=${encodeURIComponent(
          nextLocation
        )}`}
        state={{ replace: true }}
      />
    );
  }

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="leads/*" element={<LazyLeadRoutes agent={agent} />} />

        <Route path="contacts/*" element={<LazyContactRoutes agent={agent} />} />

        <Route path="deals/*" element={<LazyDealRoutes agent={agent} />} />

        <Route path="messages/*" element={<MessagesRoutes agent={agent} />} />

        <Route path="dashboard" element={<AgentDashboard agent={agent} />} />
        <Route path="settings" element={<AgentSettings agent={agent} />} />
        <Route path="profile/*" element={<Profile agent={agent} />} />
        <Route path="settings/*" element={<SettingsRoutes agent={agent} />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </React.Suspense>
  );
};

export default AgentRoutes;
