import { AgentType } from "constants/auth.types";
import { AgentPermissionsEnum } from "constants/permissions.enums";
import { hasPermission } from "permissions";
import { Route, Routes, useParams } from "react-router-dom";
import PageNotFound from "components/errors/PageNotFound";
import MessageTemplates from "./MessageTemplates";
import MessageTemplateRoute from "./template/MessageTemplate";
import EditMessageTemplateRoute from "./edit/EditMessageTemplate";
import NewTemplateRoute from "./new/NewTemplate";


const TemplateRoutes = ({ agent }: { agent: AgentType }) => {
  const params = useParams();
  const messageTemplateId = parseInt(params.messageTemplateId as string, 10);

  if (!messageTemplateId) return <PageNotFound />;

  return (
    <Routes>
      <Route
        path=""
        element={<MessageTemplateRoute agent={agent} messageTemplateId={messageTemplateId} />}
      />
      <Route
        path="edit"
        element={<EditMessageTemplateRoute agent={agent} messageTemplateId={messageTemplateId} />}
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

const MessageTemplatesRoutes = ({agent}: {agent: AgentType}) => {

  if (!hasPermission(agent, AgentPermissionsEnum.BROWSE_MESSAGE_TEMPLATES))
    return <PageNotFound />;

  return (
    <Routes>
      <Route
        path=""
        element={<MessageTemplates agent={agent} />} 
      />
      <Route
        path="new"
        element={<NewTemplateRoute agent={agent} />} 
      />
      <Route
        path=":messageTemplateId/*"
        element={<TemplateRoutes agent={agent}/>}
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );

}

export default MessageTemplatesRoutes;