import { FieldTypeEnum, FormErrors } from "./types";
import { FieldConfig } from "./field-config";
import SearchableSelectField from "./SearchableSelect";
import InputField from "./InputField";
import TextAreaField from "./TextAreaField";
import PasswordField from "./PasswordField";
import SelectField from "./SelectField";
import CheckboxField from "./CheckboxField";
import RadioField from "./RadioField";
import TemplateField from "./TemplateField";
import FileUploadField from "./FileUpload";
import VariableTypeField from "./variable-type/VariableTypeField";
import { FormDispatch, updateFieldErrors, updateFieldValues } from "./reducer";
import { useCallback } from "react";
import DateField from "./DateField";
import TimeField from "./TimeField";
import EditorField from "./EditorField";

const fieldTypeMap: Record<FieldTypeEnum, any> = {
  [FieldTypeEnum.INPUT]: InputField,
  [FieldTypeEnum.PASSWORD]: PasswordField,
  [FieldTypeEnum.TEXTAREA]: TextAreaField,
  [FieldTypeEnum.SELECT]: SelectField,
  [FieldTypeEnum.CHECKBOX]: CheckboxField,
  [FieldTypeEnum.RADIO]: RadioField,
  [FieldTypeEnum.SEARCHABLE_SELECT]: SearchableSelectField,
  [FieldTypeEnum.TEMPLATE]: TemplateField,
  [FieldTypeEnum.FILE]: FileUploadField,
  [FieldTypeEnum.VARIABLE_TYPE]: VariableTypeField,
  [FieldTypeEnum.DATE]: DateField,
  [FieldTypeEnum.TIME]: TimeField,
  [FieldTypeEnum.EDITOR]: EditorField
};


type FormFieldsProps = {
  data: Record<string, any>;
  fields: FieldConfig[];
  errors: Record<string, string[]>;
  dispatch: FormDispatch;
};
export const FormFields = ({
  data,
  errors,
  dispatch,
  fields,
}: FormFieldsProps) => {


  const handleChange = useCallback((values: FormData) => dispatch(updateFieldValues(values)), [dispatch]);
  const handleError = useCallback((errors: FormErrors) => dispatch(updateFieldErrors(errors)), [dispatch]);


  const renderField = (f: FieldConfig) => {
    const FieldComponent = fieldTypeMap[f.type];
    if(f.hideIf && f.hideIf(data)) return null;
    
    return (
      <FieldComponent
        key={f.name}
        formData={data}
        formErrors={errors}
        fieldConfig={f}
        onChange={handleChange}
        onError={handleError}
      />
    );
  };

  return <>{fields.map(renderField)}</>;
};

export default FormFields;
