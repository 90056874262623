import ButtonLink from "components/links/ButtonLink";
import TabularList from "components/tabular-list/TabularList";
import useAdvancedSearch from "components/tabular-list/context";
import { useSearchState } from "components/tabular-list/search-state";
import { AgentType } from "constants/auth.types";
import AgentIcon from "@mui/icons-material/Payments";
import { PageHeader, PageSubtitle, PageTitle } from "components/page/PageHeader";

const columns = [
  {
    id: 'name', 
    numeric: false, 
    label: 'Name', 
    ordering: 'name'
  },
  {
    id: 'actions', 
    numeric: false, 
    label: 'Actions', 
    render: ({row}:{row: AgentType}) => (<ButtonLink to={`/auth/scope?account=${row.account_id}&agent=${row.id}`}>Login</ButtonLink>)
  },
]

const Agents = () => {

  const [searchState, updateSearchState] = useSearchState();
  const [state, dispatch] = useAdvancedSearch('agent', searchState);

  return (
    <>
      <PageHeader>
        <PageTitle
          text="Agents"
          Icon={AgentIcon}
        />
        <PageSubtitle text="Browse all the agents within your AgentVA account" />
      </PageHeader>

      <TabularList 
        heading="Agents"  
        columns={columns}
        store={state}
        dispatch={dispatch}
        searchState={searchState}
        onUpdateSearch={updateSearchState}
      />
    </>
  )
};

export default Agents;