import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

export type TrailItem = {
  to?: string;
  label: string;
};

export type Trail = (TrailItem | null)[];


const BreadcrumbTrail = ({ trail }: { trail: Trail }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {(trail.filter(v => !!v) as TrailItem[]).map((item, idx) =>
        item.to ? (
          <Link
            key={idx}
            underline="hover"
            color="inherit"
            to={item.to}
            component={RouterLink}
          >
            {item.label}
          </Link>
        ) : (
          <Typography key={idx} color="text.primary">
            {item.label}
          </Typography>
        )
      )}
    </Breadcrumbs>
  );
};

export default BreadcrumbTrail;
