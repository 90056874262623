
import {
  Routes,
  Route,
} from "react-router-dom";
import PageNotFound from "components/errors/PageNotFound";
import { AgentType } from "constants/auth.types";
import MessageDashboard from "./dashboard/MessageDashboard";
import Sent from "./sent/Sent";
import MessageTemplatesRoutes from "./templates/Routes";

const MessagesRoutes = ({ agent }: { agent: AgentType }) => {

  return (
    <Routes>
      <Route path="sent" element={<Sent agent={agent} />} />
      <Route path="templates/*" element={<MessageTemplatesRoutes agent={agent}/>} />
      <Route path="" element={<MessageDashboard agent={agent} />} />
      <Route path="*" element={<PageNotFound />}/>
    </Routes>
  );
};

export default MessagesRoutes;