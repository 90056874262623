import {
  FormControlLabel,
  FormGroup,
  Switch,
  Button,
  Alert,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ApiEntityType } from "constants/base.types";
import { useDestroyOne, useFormCreate } from "hooks/api-hooks";
import useNotify from "../../../../notify";
import {
  getPushSubscription,
  subscribePush,
  unsusbscribePush,
} from "../../../../serviceWorkerRegistration";
import { Paragraph, SectionTitle } from "components/CardFact";

const TestSubscription = ({
  subscription,
}: {
  subscription: PushSubscription;
}) => {
  const [test, testing] = useFormCreate<boolean>("notification_push_sub/test");
  const { notify } = useNotify();

  const handleClick = () => {
    test({ endpoint: subscription.endpoint })
      .then((success) => {
        if (success) {
          notify({ severity: "success", msg: "Test notification sent" });
        } else {
          notify({ severity: "error", msg: "Test notification rejected" });
        }
      })
      .catch(() => {
        notify({
          severity: "error",
          msg: "Unable to send test notification. Please re-enable push notifications on this device and try again.",
        });
      });
  };

  return (
    <Button variant="outlined" disabled={testing} onClick={handleClick}>
      {testing ? "Testing..." : "Test"}
    </Button>
  );
};

const PushNotifications = () => {
  const [subscription, setSubscription] = useState<null | PushSubscription>(
    null
  );
  const [subscribing, setSubscribing] = useState<boolean>(true);

  const [saveSubscripition, saving] = useFormCreate<ApiEntityType>(
    `notification_push_sub`
  );
  const [deleteSubscription, deleting] = useDestroyOne<number>(
    `notification_push_sub`
  );

  const { notify } = useNotify();

  useEffect(() => {
    getPushSubscription()
      .then((sub) => {
        setSubscription(sub);
        // Ensure server always has a copy
        if (sub) saveSubscripition(sub);
      })
      .finally(() => setSubscribing(false));
  }, [saveSubscripition]);

  const handleTogglePush = () => {
    setSubscribing(true);
    if (subscription) {
      unsusbscribePush(subscription)
        .then(() => {
          deleteSubscription({ endpoint: subscription.endpoint });
          setSubscription(null);
        })
        .catch(() =>
          notify({
            severity: "error",
            msg: "Unable to unsubscribe. Please reload the page and try again.",
          })
        )
        .finally(() => setSubscribing(false));
    } else {
      subscribePush()
        .then((sub) => {
          setSubscription(sub);
          return saveSubscripition(sub).then(() => {
            notify({
              severity: "success",
              msg: "Successfully enabled push notifications on this device.",
            });
          });
        })
        .catch(() => {
          notify({
            severity: "error",
            msg: "Failed to enable push notifications on this device. Please check your browser settings and re-try. ",
          });
        })
        .finally(() => setSubscribing(false));
    }
  };

  return (
    <>
      <SectionTitle>
        Push Notifications
      </SectionTitle>
      <Paragraph>
        Push Notifications allow you to receive real-time notifications on this
        device from Agent VA. The first time you enable this feature your
        browser will ask you to confirm if you want to enable Push
        Notifications. Click 'Yes'.{" "}
      </Paragraph>

      <FormGroup>
        <FormControlLabel
          disabled={subscribing}
          label={
            subscribing || saving || deleting
              ? "Updating..."
              : subscription
                ? "Push Notifications ON"
                : "Push Notifications OFF"
          }
          control={
            <Switch
              checked={!!subscription}
              onChange={handleTogglePush}
              inputProps={{
                "aria-label": subscription
                  ? "Disable Push Notifications"
                  : "Enable Push Notifications",
              }}
            />
          }
        />
      </FormGroup>
      <Alert severity="info">
        If you dismiss or reject your browser's request to enable push
        notifications, you will not be able to enable notifications on this
        device until you clear the setting from your browser.
      </Alert>
      {subscription && <TestSubscription subscription={subscription} />}
    </>
  );
};

export default PushNotifications;
