import {
  Editor as SlateEditor,
  Transforms,
  Element as SlateElement,
  Path,
  Text,
  Range,
  Point,
  Location,
  Selection,
  Node,
  Descendant,
} from "slate";
import {
  MarksEnum,
  ElementTypesEnum,
  ElementOrAllignmentType,
  CustomElement,
  AlignmentTypesEnum,
  CustomEditor,
  BlockElementTypes,
  CustomText,
} from "./types";
import { LinkElement } from "./types";
import { ReactEditor } from "slate-react";

const LIST_TYPES: ElementOrAllignmentType[] = [
  ElementTypesEnum.BULLETED,
  ElementTypesEnum.NUMBERED,
];

export const isInline = (element: CustomElement) =>
  element.type === ElementTypesEnum.VARIABLE_PLACEHOLDER ||
  element.type === ElementTypesEnum.LINK;
export const isVoid = (element: CustomElement) =>
  element.type === ElementTypesEnum.VARIABLE_PLACEHOLDER;

export const toggleBlock = (
  editor: CustomEditor,
  format: BlockElementTypes
) => {
  // Check what the current state is we are toggling from
  const isActive = isBlockActive(editor, format, "type");

  // Strip away any UL/OL wrappers in the selection
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !SlateEditor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type),
    split: true,
  });

  // Selected nodes will become list items if formatting to a list
  const isList = LIST_TYPES.includes(format);
  const newProperties: Partial<CustomElement> = {
    type: isActive
      ? ElementTypesEnum.PARAGRAPH
      : isList
      ? ElementTypesEnum.LIST_ITEM
      : format,
  };
  Transforms.setNodes<CustomElement>(editor, newProperties);

  // Only if formatting to a list, wrap with selected nodes with appropriate UL/OL wrapper
  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const toggleAlignment = (
  editor: CustomEditor,
  format: AlignmentTypesEnum
) => {
  const isActive = isBlockActive(editor, format, "align");

  const newProperties: Partial<CustomElement> = {
    align: isActive ? undefined : (format as AlignmentTypesEnum),
  };
  Transforms.setNodes<CustomElement>(editor, newProperties);
};

export const toggleMark = (editor: CustomEditor, format: MarksEnum) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    SlateEditor.removeMark(editor, format);
  } else {
    SlateEditor.addMark(editor, format, true);
  }
};

export const isBlockActive = (
  editor: CustomEditor,
  format: ElementOrAllignmentType,
  blockType: "type" | "align"
) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    SlateEditor.nodes(editor, {
      at: SlateEditor.unhangRange(editor, selection),
      match: (n) =>
        !SlateEditor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

export const isMarkActive = (editor: CustomEditor, format: MarksEnum) => {
  const marks: Omit<CustomText, "text"> | null = SlateEditor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const insertPlaceholder = (editor: CustomEditor, alias: string) => {
  const { selection } = editor;

  const placeholderNodes: (CustomElement | CustomText)[] = [
    {
      type: ElementTypesEnum.VARIABLE_PLACEHOLDER,
      id: Date.now().toString(),
      alias,
      children: [{ text: "" }],
    },
    // auto-append blank space after placeholder
    { text: " " },
  ];

  let at: Location | undefined = undefined;

  if (!!selection) {
    Transforms.collapse(editor, { edge: "end" });
    const [parent, parentPath] = SlateEditor.parent(editor, selection.focus);
    if (
      SlateElement.isElement(parent) &&
      parent.type === ElementTypesEnum.LINK
    ) {
      at = Path.next(parentPath);
    }
  }
  Transforms.insertNodes(editor, placeholderNodes, { at });

  // Advance curstor out of void node
  Transforms.move(editor);
};

export const replacePlaceholder = (
  editor: CustomEditor,
  id: string,
  nodes: Descendant[]
) => {
  const matcher = (node: Node) =>
    SlateElement.isElement(node) &&
    ElementTypesEnum.VARIABLE_PLACEHOLDER === node.type &&
    node.id === id;

  // Find the node to replace by its (hopefully) unique ID
  const placeholderNodeEntry = SlateEditor.nodes(editor, {
    at: [],
    match: matcher,
  }).next().value;

  if (!placeholderNodeEntry) {
    console.error("No placeholder found with id");
    return;
  }
  const path = placeholderNodeEntry[1];
  const [parentNode, parentPath] = SlateEditor.parent(editor, path);

  if (
    SlateElement.isElement(parentNode) &&
    SlateEditor.isVoid(editor, parentNode)
  ) {
    // Not sure if this can happen, but if it does we shouln't try to insert into the void
    console.error(
      "Unexpected placeholder positioning. Placeholder child of void element."
    );
  } else {
    // Add text node at path previously occupied by placeholder
    Transforms.insertNodes(editor, nodes, { at: Path.next(path) });
    // Remove the placeholder. We can't be sure what its path is anymore since
    // nodes jump around during normalization so simplest just to search
    // for it within the parent node
    Transforms.removeNodes(editor, { at: parentPath, match: matcher });
  }
};

export const insertLink = (editor: CustomEditor, url: string) => {
  if (!url) return;

  const { selection } = editor;
  const link: LinkElement = {
    type: ElementTypesEnum.LINK,
    href: url,
    children: [{ text: "Link" }],
  };

  if (!!selection) {
    // Remove the links in the current selection
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        SlateElement.isElement(n) && n.type === ElementTypesEnum.LINK,
    });

    const textSelection = selectOnlyTextNodes(editor);

    if (textSelection) {
      const [parentNode, parentPath] = SlateEditor.parent(
        editor,
        selection.focus?.path
      );

      if (SlateElement.isElement(parentNode) && editor.isVoid(parentNode)) {
        Transforms.insertNodes(editor, link, {
          at: Path.next(parentPath),
          select: true,
        });
      } else if (Range.isCollapsed(textSelection)) {
        Transforms.insertNodes(editor, [{ text: "" }, link, { text: "" }], {
          select: true,
        });
      } else {
        Transforms.wrapNodes(editor, link, {
          split: true,
        });
        Transforms.collapse(editor, { edge: "end" });
      }
    }
  } else {
    Transforms.insertNodes(editor, {
      type: ElementTypesEnum.PARAGRAPH,
      children: [{ text: "" }, link, { text: " " }],
    });
  }
};

const selectOnlyTextNodes = (editor: CustomEditor): Selection | null => {
  const { selection } = editor;

  if (!selection) return null;

  // Shrink the selection so that only text nodes are included within it.
  let start: Point | null = null;
  let end: Point | null = null;
  for (const [n, path] of SlateEditor.nodes(editor)) {
    if (Text.isText(n)) {
      if (start === null) {
        start = {
          path,
          offset: Path.equals(selection.anchor.path, path)
            ? selection.anchor.offset
            : 0,
        };
      }
      end = {
        path,
        offset: Path.equals(selection.focus.path, path)
          ? selection.focus.offset
          : (n as any).text.length,
      };
    } else {
      if (start && end) break;
    }
  }

  const safeSelection: Selection =
    start && end
      ? { anchor: start, focus: end }
      : { anchor: selection.focus, focus: selection.focus };
  Transforms.select(editor, safeSelection);

  return safeSelection;
};

export const removeSelectedLink = (editor: CustomEditor, id: string) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => SlateElement.isElement(n) && n.type === ElementTypesEnum.LINK && n.href === id,
  });
  ReactEditor.focus(editor);
};

export const insertInlineNodes = (
  editor: CustomEditor,
  nodes: Descendant[]
) => {
  if (!nodes.length) return;

  Transforms.insertNodes(editor, nodes, {
    select: true,
  });
  ReactEditor.focus(editor);
};

export const updateLinkHref = (editor: CustomEditor, id: string, href: string) => {
  Transforms.setNodes<CustomElement>(editor, {href}, {
    match: (n) =>
      !SlateEditor.isEditor(n) &&
      SlateElement.isElement(n) &&
      ElementTypesEnum.LINK === n.type &&
      n.href === id // update later to be unique ID
  });
  ReactEditor.focus(editor);
};
