
import { Grid, Card, CardContent, Stack, Divider, Box, Typography, styled, CardActions, } from "@mui/material";
import { AgentRouting } from "utils/routing";
import ButtonLink from "components/links/ButtonLink";
import { DealGrowthStats } from "./reducer";
import { TrendArrow, TrendDetails, compactNumber } from "./stats";


const StyledTable = styled("table")(({ theme }) => ({
  margin: theme.spacing(2),
  width: "100%"
}));


const ActiveDealsTile = ({ agentRouting, loading, stats }: { agentRouting: AgentRouting, loading: boolean, stats: DealGrowthStats | null }) => {

  const { 
    yearly_trend = { amount: 0, percent: 0 }, 
    monthly_trend = { amount: 0, percent: 0 }, 
    active_total = 0, 
    closed_total= 0 
  } = stats || {}


  return (
    <Grid item xs={12} lg={6}>
      <Card elevation={2}>
        <CardContent>
          <Typography component="div" variant="h5">Deals in Flight</Typography>
          <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
            <Box sx={{ p: 2 }}>
              <Stack direction="column" alignItems="center">
                <Typography variant="h3">
                  {loading ? '-' : compactNumber(active_total)}
                </Typography>
                <Typography component="div" variant="caption" textAlign="center">Active deals</Typography>
              </Stack>
            </Box>
            <StyledTable>
              <colgroup>
                <col style={{ width: "40%" }} />
                <col style={{ width: "15%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <td>Monthly Trend</td>
                  <td><TrendArrow diff={monthly_trend.percent} /></td>
                  <td>
                    <TrendDetails loading={loading} trend={monthly_trend} />
                  </td>
                </tr>
                <tr>
                  <td>Yearly Trend</td>
                  <td><TrendArrow diff={yearly_trend.percent} /></td>
                  <td>
                    <TrendDetails loading={loading} trend={yearly_trend} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>Total Deals Closed</td>
                  <td>{compactNumber(closed_total)}</td>
                </tr>
              </tbody>
            </StyledTable>
          </Stack>
        </CardContent>
        <CardActions>
          <ButtonLink to={agentRouting.dealsDashboard()}>View</ButtonLink>
          <ButtonLink to={agentRouting.newDeal()}>Add</ButtonLink>
        </CardActions>
      </Card>
    </Grid>
  )
}

export default ActiveDealsTile