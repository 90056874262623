import { FieldConfigI, FieldTypeEnum, FormErrors } from "../types";
import { DealAttributeTypeEnum } from "constants/deal.enums";
import { VariableTypeValue } from "./types";
import TypeSelectField from "./TypeSelectField";
import {
  AddressValueField,
  MLSListingValueField,
  TextValueField,
} from "./string-value-fields";
import BoolValueField from "./bool-value-fields";
import { FormControl, FormHelperText } from "@mui/material";
import React from "react";
import { MonetaryAmountField } from "./int-value-fields";
import { ContractDateField } from "./date-value-fields";
import { AppointmentField } from "./datetime-value-fields";

export interface VariableTypeFieldConfig extends FieldConfigI {
  type: FieldTypeEnum.VARIABLE_TYPE;
}

const valueFieldByAttributeType: Record<
  DealAttributeTypeEnum,
  React.FunctionComponent<any>
> = {
  [DealAttributeTypeEnum.MONETARY_AMOUNT]: MonetaryAmountField,
  [DealAttributeTypeEnum.CONTRACT_DATE]: ContractDateField,
  [DealAttributeTypeEnum.APPOINTMENT]: AppointmentField,
  [DealAttributeTypeEnum.TEXT]: TextValueField,
  [DealAttributeTypeEnum.ADDRESS]: AddressValueField,
  [DealAttributeTypeEnum.YES_NO]: BoolValueField,
  [DealAttributeTypeEnum.MLS_LISTING_NO]: MLSListingValueField,
};

const getFieldErrors = (
  formErrors: FormErrors,
  fieldConfig: VariableTypeFieldConfig
) => {
  return (fieldConfig.sources || []).reduce((errors, source): string[] => {
    return [...errors, ...(formErrors[source] || [])];
  }, [] as string[]);
};

const VariableTypeField = ({
  fieldConfig,
  formData,
  formErrors,
  onChange,
  onError,
}: {
  fieldConfig: VariableTypeFieldConfig;
  formData: Record<string, any>;
  formErrors: FormErrors;
  onChange: (arg0: Record<string, any>) => void;
  onError: (arg0: Record<string, string[]>) => void;
}) => {
  const value = formData[fieldConfig.name] as VariableTypeValue|undefined;
  const errors = getFieldErrors(formErrors, fieldConfig);
  const hasErrors = errors && errors.length > 0;
  const helperText = hasErrors ? errors.join(". ") : fieldConfig.help;

  const { attribute_type } = value || {};

  const handleValueChange = ({
    string_value = null,
    date_value = null,
    datetime_value = null,
    int_value = null,
    bool_value = null,
    ...rest
  }: Partial<VariableTypeValue>) => {
    onChange({
      [fieldConfig.name]: {
        ...value,
        string_value,
        date_value,
        datetime_value,
        int_value,
        bool_value,
        ...rest,
      },
    });
  };

  const handleTypeChange = (newType: DealAttributeTypeEnum) => {
    // Force clear all values on type change
    handleValueChange({ attribute_type: newType });
  };

  const renderValueInput = () => {

    if(!attribute_type) return null;

    const props = {
      label: "Value",
      name: `${fieldConfig.name}-value`,
      onChange: handleValueChange,
      value,
    };

    const ValueFieldComponent = valueFieldByAttributeType[attribute_type];

    return <ValueFieldComponent {...props} />;
  };

  return (
    <>
      <TypeSelectField
        value={attribute_type}
        name={`${fieldConfig.name}-type`}
        onChange={handleTypeChange}
      />

      <FormControl
        fullWidth
        margin="normal"
        variant="outlined"
        error={hasErrors}
      >
        {renderValueInput()}

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </>
  );
};

export default VariableTypeField;
