import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ActionResult } from "./handlers";

const DoneDialog = ({
  onClose,
  results,
}: {
  onClose: () => void;
  results: ActionResult[];
}) => {
  const total = results.length;

  const errors = results.filter((result) => result.success !== true);
  const successCount = total - errors.length;

  return (
    <>
      <DialogTitle>Bulk Action Complete</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          {successCount} of {total} action(s) commpleted succesfully.
        </Typography>

        {errors.length > 0 && (
          <>
            <Typography variant="subtitle2" sx={{color: 'error.main'}}>Errors</Typography>
            {errors.map((err, idx) => (
              <Typography variant="body2" key={idx}>
                {err.msg}
              </Typography>
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} variant="text">
          OK
        </Button>
      </DialogActions>
    </>
  );
};

export default DoneDialog;
