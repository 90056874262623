

export enum DealStatusEnum {
  ACTIVE = 'active',
  COMPLETE = 'complete',
  INACTIVE = 'inactive',
};

export enum DealTypeEnum {
  BUY = 'buy',
  SELL = 'sell',
};

export const DealTypeEnumLabels: Record<DealTypeEnum, string> = {
  [DealTypeEnum.BUY]: 'Buy',
  [DealTypeEnum.SELL]: 'Sell',
}

export enum DealStepStatusEnum {
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  SKIPPED = 'skipped',
  BLOCKED = 'blocked',
}


export const DealStatusLabels: Record<DealStatusEnum, string> = {
  [DealStatusEnum.ACTIVE]: 'Active',
  [DealStatusEnum.COMPLETE]: 'Complete',
  [DealStatusEnum.INACTIVE]: 'Inactive',
};

export const DealTypeLabels: Record<DealTypeEnum, string> = {
  [DealTypeEnum.BUY]: 'Buy',
  [DealTypeEnum.SELL]: 'Sell',
};

export const DealStepStatusLabels: Record<DealStepStatusEnum, string> = {
  [DealStepStatusEnum.NEW]: 'Not Started',
  [DealStepStatusEnum.IN_PROGRESS]: 'In Progress',
  [DealStepStatusEnum.COMPLETED]: 'Done',
  [DealStepStatusEnum.SKIPPED]: 'Skipped',
  [DealStepStatusEnum.BLOCKED]: 'Blocked',
};

export const DEAL_STEP_DONE_STATUSES = [
  DealStepStatusEnum.COMPLETED,
  DealStepStatusEnum.SKIPPED,
];

export enum DealAttributeTypeEnum {
  MONETARY_AMOUNT = 'monetary_amount',
  ADDRESS = 'address',
  CONTRACT_DATE = 'contract_date',
  MLS_LISTING_NO = 'mls_listing_no',
  TEXT = 'text',
  YES_NO = 'yes_no',
  APPOINTMENT = 'appointment',
}

export const DealAttributeTypeLabels: Record<DealAttributeTypeEnum, string> = {
  [DealAttributeTypeEnum.MONETARY_AMOUNT]: 'Monetary Amount',
  [DealAttributeTypeEnum.ADDRESS]: 'Address',
  [DealAttributeTypeEnum.CONTRACT_DATE]: 'Contract Date',
  [DealAttributeTypeEnum.MLS_LISTING_NO]: 'MLS #',
  [DealAttributeTypeEnum.TEXT]: 'Text',
  [DealAttributeTypeEnum.YES_NO]: 'Yes/No',
  [DealAttributeTypeEnum.APPOINTMENT]: 'Appointment',
};