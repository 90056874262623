import SaveIcon from "@mui/icons-material/Save";
import PageNotFound from "components/errors/PageNotFound";
import { FieldTypeEnum, FormSubmitHandler } from "components/form/types";
import { FieldConfig } from "components/form/field-config";
import { EmailTemplateType, MessageTemplateType } from "constants/msg.types";
import { useFormUpdate, useGetOnce } from "hooks/api-hooks";
import { ApiEntityType } from "constants/base.types";
import { Form } from "components/form/Form";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { docToStr, strToDoc } from "components/editor/rich-text";
import { CustomEditor } from "components/editor/types";
import InsertPlaceholderModal from "./InsertPlaceholderModal";

import { PageHeader, PageSection, PageSubtitle, PageTitle } from "components/page/PageHeader";
import EmailIcon from "@mui/icons-material/EmailOutlined"
import { useMessageBreadcrumbs } from "../../message-breadcrumbs";
import { AgentType } from "constants/auth.types";
import { SectionTitle } from "components/CardFact";

const useFields = (onInsert: any): FieldConfig[] => {
  const [emailTemplates] = useGetOnce<EmailTemplateType[]>(`/deal_message/email_templates`);

  return useMemo(() => [
    {
      name: "label",
      type: FieldTypeEnum.INPUT,
      label: "Name",
      placeholder: "e.g. Welcome Email",
      required: true,
    },
    {
      name: "subject",
      type: FieldTypeEnum.TEXTAREA,
      label: "Subject",
      required: true,
      rows: 2,
    },
    {
      name: "body",
      type: FieldTypeEnum.EDITOR,
      label: "Message",
      placeholder: "Type your message here.",
      help: "Looking for inspiration? Check out the AgentVA Template library for common templates.",
      rows: 25,
      onInsert,
      required: true,
    },
    {
      name: "email_template_id",
      type: FieldTypeEnum.SELECT,
      label: "Email Signature",
      placeholder: "Use Default Email Signature",
      required: false,
      emptyLabel: 'Default Email Signature',
      options: (emailTemplates || []).map(t => ({ value: t.id, name: t.name })),
      help: "This is just the default. You can change the signature later, prior to sending."
    },
  ], [emailTemplates, onInsert]);
}


const MessageTemplateForm = ({ template, onFinish }: { template: MessageTemplateType, onFinish: () => void }) => {


  const [inserting, setInserting] = useState<CustomEditor | null>(null);


  const fields = useFields(setInserting);

  const [save, saving] = useFormUpdate<ApiEntityType>(`message_template/${template.id}`);

  const onSubmit: FormSubmitHandler = ({ body, ...values }, setFormErrors) => {
    save({
      ...values,
      body: docToStr(body)
    }).then(() => onFinish()).catch(setFormErrors)
  }

  return (
    <>
      <SectionTitle>Message Details</SectionTitle>
      <Form
        fields={fields}
        onSubmit={onSubmit}
        saving={saving}
        submitIcon={<SaveIcon />}
        submitText="Save"
        initialValues={{
          subject: template.subject,
          body: strToDoc(template.body),
          label: template.label,
        }}
      />
      <InsertPlaceholderModal editor={inserting} onClose={() => setInserting(null)} />
    </>
  )

}



const EditMessageTemplateRoute = ({ agent, messageTemplateId }: { agent: AgentType, messageTemplateId: number }) => {

  const [template, loading, error] = useGetOnce<MessageTemplateType>(`message_template/${messageTemplateId}`)

  const navigate = useNavigate();
  const handleFinish = () => navigate(`..`);

  const trail = useMessageBreadcrumbs(agent.id, messageTemplateId);

  if (error) return <PageNotFound />

  return (
    <>
      <PageHeader breadcrumbTrail={trail}>
        <PageTitle
          Icon={EmailIcon}
          defaultText="Edit Message Template"
          text={template?.label}
        />
        <PageSubtitle text="Customise the look and feel of this email template" />
      </PageHeader>

      <PageSection loading={loading}>
        {template && <MessageTemplateForm template={template} onFinish={handleFinish} />}
      </PageSection>
    </>
  )
}

export default EditMessageTemplateRoute;