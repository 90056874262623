import { Descendant } from "slate"
import { ElementTypesEnum, VariableElement } from "../types"


const placeholderStyles = {
  border: "1px solid red",
  color: "darkred",
  backgroundColor: "lightred",
  paddingLeft: "2px",
  paddingRight: "2px",
  marginRight: "2px",
  marginLeft: "2px",
  borderRadius: "5px",
  cursor: "pointer"
}

const Variable = ({
  attributes,
  alias,
  id,
  children,
  onResolve
}: {
  attributes: any,
  alias: string,
  id: string,
  children: Descendant[],
  onResolve?: (arg0: VariableElement) => void
}) => {
  const handleClick = () => {
    if (onResolve) {
      onResolve({ type: ElementTypesEnum.VARIABLE_PLACEHOLDER, id, alias, children: [] })
    }
  }
  return (
    <span {...attributes} contentEditable={false} onClick={handleClick}>
      {children}
      <span style={placeholderStyles}>{alias || "unknown"}</span>
    </span>
  )
}

export default Variable;