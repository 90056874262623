import { Grid, Card, CardContent, Stack, Divider, Box, Typography, styled, CardActions, } from "@mui/material";
import { AgentRouting } from "utils/routing";
import ButtonLink from "components/links/ButtonLink";
import { ContactGrowthStats } from "./reducer";
import { compactNumber, TrendArrow, TrendDetails } from "./stats";

const StyledTable = styled("table")(({ theme }) => ({
  margin: theme.spacing(2),
  width: "100%"
}));

const NewContactsTile = ({ agentRouting, loading, stats }: { agentRouting: AgentRouting, loading: boolean, stats: ContactGrowthStats | null }) => {

  const { weekly_trend = { amount: 0, percent: 0 }, monthly_trend = { amount: 0, percent: 0 }, total = 0 } = stats || {}

  return (
    <Grid item xs={12} lg={6}>
      <Card elevation={2}>
        <CardContent>
          <Typography component="div" variant="h5">Client Growth</Typography>
          <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
            <Box sx={{ p: 2 }}>
              <Stack direction="column" alignItems="center">
                <Typography variant="h3">
                  {loading ? '-' : compactNumber(weekly_trend.amount)}
                </Typography>
                <Typography component="div" variant="caption" textAlign="center">Contacts added this week</Typography>
              </Stack>
            </Box>
            <StyledTable>
              <colgroup>
                <col style={{ width: "40%" }} />
                <col style={{ width: "15%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <td>Weekly Trend</td>
                  <td><TrendArrow diff={weekly_trend.percent} /></td>
                  <td>
                    <TrendDetails loading={loading} trend={weekly_trend} />
                  </td>
                </tr>
                <tr>
                  <td>Monthly Trend</td>
                  <td><TrendArrow diff={monthly_trend.percent} /></td>
                  <td>
                    <TrendDetails loading={loading} trend={monthly_trend} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>Total</td>
                  <td>{compactNumber(total)}</td>
                </tr>
              </tbody>
            </StyledTable>
          </Stack>
        </CardContent>
        <CardActions>
          <ButtonLink to={agentRouting.contactsDashboard()}>View</ButtonLink>
          <ButtonLink to={agentRouting.newContact()}>Add</ButtonLink>
        </CardActions>
      </Card>
    </Grid>
  )
}

export default NewContactsTile 