import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Link, useParams } from "react-router-dom";


export enum ProfileTabEnum {
  CALENDAR = 'calendar',
  NOTIFICATIONS = 'notifications',
  PROFILE = 'profile',
}

function a11yProps(value: ProfileTabEnum) {
  return {
    id: `profile-tab-${value}`,
    "aria-controls": `profile-tabpanel-${value}`,
    value,
    to: `../${value}`,
  };
}

export default function ProfileTabs({ tab }: { tab?: ProfileTabEnum }) {
  const params = useParams();
  const value = tab ? tab : params.tab;
  return (
    <Box sx={{ mb: 2, borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        allowScrollButtonsMobile
        scrollButtons
        variant="scrollable"
        value={value}
        aria-label="profile tabs"
      >
        <Tab
          component={Link}
          label="Profile"
          {...a11yProps(ProfileTabEnum.PROFILE)}
        />
        <Tab
          component={Link}
          label="Notifications"
          {...a11yProps(ProfileTabEnum.NOTIFICATIONS)}
        />
        <Tab
          component={Link}
          label="calendar"
          {...a11yProps(ProfileTabEnum.CALENDAR)}
        />
      </Tabs>
    </Box>
  );
}
