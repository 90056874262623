import React, { useCallback, useState } from 'react'
import { Editable, withReact, Slate, ReactEditor } from 'slate-react'
import {
  Descendant,
  createEditor,
} from 'slate'
import Element from "./elements/Element"
import Leaf from "./Leaf"
import Toolbar from './Toolbar';
import { softBreakHandler } from './soft-break';
import { isInline, isVoid } from './transforms';
import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { CustomEditor, RichTextDoc, VariableElement } from './types'

const editorStyle = {minHeight: '5em'};

const MyEditor = ({
  onChange,
  value,
  inputRef,
  onInsert,
  onResolve,
}: {
  onChange: (arg0: Descendant[]) => void,
  value: RichTextDoc,
  inputRef: React.MutableRefObject<HTMLElement>,
  onInsert?: (editor: CustomEditor) => void,
  onResolve?: (editor: CustomEditor, variable: VariableElement) => void
}) => {
  const {etag} = value;
  const [editor] = useState(() => withReact(createEditor()));

  const handleResolve = useCallback((variable: VariableElement) => {
    if(onResolve){
      onResolve(editor, variable);
    }
  },[onResolve]) //eslint-disable-line react-hooks/exhaustive-deps

  const renderElement = useCallback(props => <Element onResolve={handleResolve} readOnly={false} {...props} />, [handleResolve])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])

  editor.isInline = isInline;
  editor.isVoid = isVoid;

  useEffect(() => {
    // MUI needs a reference to the underlying input element
    inputRef.current = ReactEditor.toDOMNode(editor, editor);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // External change, force a re-load
    editor.children = value.children;
    editor.onChange()
  }, [etag]) //eslint-disable-line react-hooks/exhaustive-deps


  const handleInsert = onInsert ? () => {
    onInsert(editor);
  }  : undefined;

  return (
    <Slate editor={editor} value={value.children} onChange={onChange}>
      <Grid container padding={2}>
        <Grid item xs={12}>
          <Toolbar onInsert={handleInsert} />
        </Grid>
        <Grid item xs={12}>

          <Editable
            style={editorStyle}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={softBreakHandler(editor)}
            spellCheck
            autoFocus
          />
        </Grid>

      </Grid>
    </Slate>
  )
}


export default MyEditor