import { ReactNode } from "react";
import useAuth from "../auth";
import PublicLayout from "./PublicLayout";
import ResponsiveDrawerLayout from "./ResponsiveDrawerLayout";

const Layout = ({ children }: { children: ReactNode }) => {
  const { auth } = useAuth();

  if (auth) {
    return <ResponsiveDrawerLayout auth={auth} children={children} />;
  } else {
    return <PublicLayout children={children} />;
  }
};

export default Layout;
