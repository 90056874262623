import { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";


export type ControlledDialogProps = {
  open: boolean,
  onCancel: () => void,
  onSuccess: () => void,
  loading: boolean,
}


export const useConfirmation = <T,>(onConfirmed: () => Promise<T>, onSuccess?: (arg0: T) => void): [() => void, ControlledDialogProps] => {
  const [confirming, setConfirming] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleConfirm = () => {
    setLoading(true);
    onConfirmed().then((value) => {
      setConfirming(false);
      setLoading(false);
      if(onSuccess) onSuccess(value);
    }).catch((e) => {
      setLoading(false);
    })
  }

  const handleClose = () => {
    setConfirming(false);
    setLoading(false);
  }

  return [
    () => setConfirming(true),
    {
      open: confirming,
      loading: loading,
      onCancel: handleClose,
      onSuccess: handleConfirm
    }
  ]
}



export const ConfirmDialog = ({
  open = true,
  danger = false,
  confirmText = "Are you sure you want to continue?",
  okText = "Yes",
  onSuccess,
  onCancel,
  loading = false,
}: ControlledDialogProps & {
  danger?: boolean;
  confirmText?: string;
  okText?: string;
}) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent>
        <DialogContentText>{confirmText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          disabled={loading}
          color={danger ? "error" : "primary"}
          onClick={onSuccess}
          autoFocus
        >
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};



export const ConfirmDangerDialogue = (props: any) => (
  <ConfirmDialog
    danger
    confirmText="Are you sure you want to delete this item?"
    okText="Delete"
    {...props}
  />
);

export default ConfirmDialog;
