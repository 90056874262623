import Clear from "@mui/icons-material/Clear";
import {
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { withFormControl } from "./FormComponent";
import {
  NULL_SELECTION,
  SelectOption,
  FieldProps,
  FieldTypeEnum,
  FieldConfigI,
} from "./types";

export interface SelectFieldConfig extends FieldConfigI {
  type: FieldTypeEnum.SELECT;
  options: SelectOption[];
  emptyLabel?: string;
}

interface SelectFieldProps extends FieldProps {
  value: string;
  placeholder: string;
  onChange: (arg0: Record<string, string | string[]>) => void;
  config: SelectFieldConfig;
}
export const SelectField = ({ value, onChange, config }: SelectFieldProps) => {
  const missing =
    value && !config.options.find((opt) => `${opt.value}` === `${value}`);

  const handleChange = (e: any) => {
    onChange({
      [config.name]: e.target.value === NULL_SELECTION ? null : e.target.value,
    });
  }

  return (
    <>
      <InputLabel id={`label-${config.name}`}>{config.label}</InputLabel>
      <Select
        name={config.name}
        label={config.label}
        value={value || (config.emptyLabel ? NULL_SELECTION : "")}
        multiple={false}
        required={config.required}
        disabled={config.disabled}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={() => onChange({ [config.name]: "" })}>
              <Clear />
            </IconButton>
          </InputAdornment>
        }
      >
        {config.emptyLabel && (
          <MenuItem sx={{ color: "text.secondary" }} value={NULL_SELECTION}>
            {config.emptyLabel}
          </MenuItem>
        )}
        {missing && <MenuItem value={value}>({value})</MenuItem>}
        {config.options.map((opt) => (
          <MenuItem
            key={opt.key || opt.value}
            value={`${opt.value}`}
            disabled={opt.disabled}
          >
            {opt.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default withFormControl(SelectField);
