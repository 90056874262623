import { Link, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";


const WelcomeRoute = () => {
  const [searchParams] = useSearchParams();

  const failure = searchParams.has('failure');

  if (failure) {
    return (
      <section>
        <Typography variant="h5" gutterBottom>
          Welcome to AgentVA!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Oh no! It looks like something went wrong setting up you subscription. 
          If this was unexpected you can contact us at <Link href="mailto:support@agentva.ca">support@agentva.ca</Link> for assistance.
        </Typography>
      </section>
    )
  }
  return (
    <section>
      <Typography variant="h5" gutterBottom>
        Welcome to AgentVA!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Thank you for choosing AgentVA. We are setting up your account now.
      </Typography>

      <Typography variant="h6" gutterBottom>Next Steps</Typography>
      <Typography variant="body1">
        You should receive an email with instructions on how to set a password for your account.
        <br />
        If you don't receive this email within a few minutes you can contact us at <Link href="mailto:support@agentva.ca">support@agentva.ca</Link>.
      </Typography>

    </section>

  )
}

export default WelcomeRoute;