import { FC } from "react";
import { Link } from "react-router-dom"
import { styled } from "@mui/system";
import Button, { ButtonProps } from "@mui/material/Button";

type LinkProps = { to: string, download?: boolean };

const ButtonLink: FC<ButtonProps & LinkProps> = ({ to, children, startIcon, endIcon, disabled, variant, download = undefined }) => {
  return (
    <Button to={to} component={Link} startIcon={startIcon} endIcon={endIcon} disabled={disabled} variant={variant} download={download}>{children}</Button>
  )
};


export const UnstyledLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'inherit',
});






export default ButtonLink;