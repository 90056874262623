import { Descendant } from "slate";
import { ElementTypesEnum, RichTextDoc } from "./types";

const emptyChildren = (): Descendant[] => {
  return [{ type: ElementTypesEnum.PARAGRAPH, children: [{ text: "" }] }];
};

export const strToDoc = (value: string, etag?: string | null): RichTextDoc => {
  etag = etag || "__initial__";
  if (value) {
    try {
      let children = JSON.parse(value);

      return {
        etag,
        children,
      };
    } catch {
      return {
        etag,
        children: [
          { type: ElementTypesEnum.PARAGRAPH, children: [{ text: value }] },
        ],
      };
    }
  }
  return { etag, children: emptyChildren() };
};

export const docToStr = (doc: RichTextDoc): string => {
  return JSON.stringify(doc.children);
};
