import React from "react";
import {
  SectionTitle,
  FactLabel,
  FactTextValue,
} from "components/CardFact";
import { useGetOnce } from "hooks/api-hooks";
import { LicenseType, AccountType } from "constants/auth.types";
import { LicenseTypeLabels } from "constants/auth.enums";
import { PageSection} from "components/page/PageHeader";

const LicenseInfo = ({ account }: { account: AccountType | null }) => {
  const [license, loading] = useGetOnce<LicenseType>(`account_license/current`);

  return (
    <PageSection loading={loading}>
      <SectionTitle>License Info</SectionTitle>
      {license ? (
        <React.Fragment>
          <FactLabel>License Type</FactLabel>
          <FactTextValue emphasis>
            {license.license_type ? LicenseTypeLabels[license.license_type] : 'No License Found'}
          </FactTextValue>

          <FactLabel>In Trial</FactLabel>
          <FactTextValue>
            {license.trialing ? 'Yes' : 'No'}
          </FactTextValue>


          <FactLabel>Agent Limit</FactLabel>
          <FactTextValue>
            {license.max_agents === null ? "Unlimited" : license.max_agents}
          </FactTextValue>
        </React.Fragment>
      ) : (
        "No license found"
      )}
    </PageSection>
  );
};

export default LicenseInfo;
