import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const ConfirmDialog = ({
  onClose,
  onConfirm,
}: {
  onClose: () => void;
  onConfirm: (formData?: any) => void;
}) => (
  <>
    <DialogTitle>Confirm Bulk Action</DialogTitle>
    <DialogContent>
      <Typography variant="body1">
        Are you sure you want to perform this action?
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={onClose} variant="text">
        Cancel
      </Button>
      <Button autoFocus onClick={onConfirm} variant="outlined">
        Confirm
      </Button>
    </DialogActions>
  </>
);

export default ConfirmDialog;