import { Routes, Route, Navigate} from "react-router-dom";
import Buy from "./Buy";
import Login from "./Login";
import PageNotFound from "components/errors/PageNotFound";
import PasswordReset from "./PasswordReset";
import Register from "./Register";
import ContactSales from "./ContactSales";
import PasswordRecovery from "./PasswordRecovery";
import useAuth from "auth";
import Unsubscribe from "./Unsubscribe";

function PublicRoutes() {
  const { auth } = useAuth();

  if (auth) {
    // Not available while logged in. This will also trigger re-direct to login after initial login
    return <Navigate to="/user/home" />;
  }

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/reset-password" element={<PasswordReset />} />
      <Route path="/sales/contact" element={<ContactSales />} />
      <Route path="/buy" element={<Buy />} />
      <Route path="password-recovery" element={<PasswordRecovery />} />
      <Route path="/unsubscribe" element={<Unsubscribe />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default PublicRoutes;
