import React from "react";
import {
  ActionableSectionTitle,
  FactLabel,
  FactTextValue,
} from "components/CardFact";
import { useGetOnce } from "hooks/api-hooks";
import {
  AccountSubscriptionType,
  AccountType,
} from "constants/auth.types";
import { PageSection } from "components/page/PageHeader";
import { LocaleDatetime } from "components/locale";
import { ManageBilling } from "components/billing/ManageBilling";


const SubscriptionInfo = ({ account }: { account: AccountType | null }) => {
  const [accountSubscription, loading] = useGetOnce<AccountSubscriptionType>(
    `account_subscription/current`
  );

  return (
    <PageSection loading={loading || !account}>
      <ActionableSectionTitle
        actions={
          <ManageBilling
            key="manage"
            account={account}
            accountSubscription={accountSubscription}
          />
        }
      >Subscription Info</ActionableSectionTitle>

      {accountSubscription ? (
        <React.Fragment>
          <FactLabel>Active From</FactLabel>
          <FactTextValue>
            <LocaleDatetime value={accountSubscription.created_at} />
          </FactTextValue>

          <FactLabel>Last Updated</FactLabel>
          <FactTextValue>
            <LocaleDatetime value={accountSubscription.updated_at} />
          </FactTextValue>

          <FactLabel>Subscription Status</FactLabel>
          <FactTextValue>
            {accountSubscription.status}
          </FactTextValue>

        </React.Fragment>
      ) : (
        "No subscription found"
      )}
    </PageSection>
  );
};

export default SubscriptionInfo;
