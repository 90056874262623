import { AgentType } from "constants/auth.types";
import DealCalendars from "./DealCalendars";
import PushNotifications from "./PushNotifications";
import { PageHeader, PageSection, PageSubtitle, PageTitle } from "components/page/PageHeader";
import ProfileIcon from "@mui/icons-material/Person"
import ProfileTabs, { ProfileTabEnum } from "./ProfileTabs";
import { Route, Routes } from "react-router-dom";
import EditProfile from "./EditProfile";


const Profile = ({ agent }: { agent: AgentType }) => {
  return (
    <>
      <PageHeader >
        <PageTitle
          Icon={ProfileIcon}
          text="My Profile"
        />
        <PageSubtitle text="Personalize AgentVA to suit your work-flow." />
      </PageHeader>

      <PageSection>
        <Routes>
          <Route path=":tab" element={<ProfileTabs />} />
          <Route path="*" element={<ProfileTabs tab={ProfileTabEnum.PROFILE} />} />
        </Routes>

        <Routes>
          <Route path="" element={<EditProfile />} />
          <Route path={ProfileTabEnum.PROFILE} element={<EditProfile />} />
          <Route path={ProfileTabEnum.NOTIFICATIONS} element={<PushNotifications />} />
          <Route path={ProfileTabEnum.CALENDAR} element={<DealCalendars agent={agent} />} />
        </Routes>
      </PageSection>

    </>
  );
};

export default Profile;
