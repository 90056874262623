import { Checkbox, FormControlLabel } from "@mui/material";
import { withFormControl } from "./FormComponent";
import { FieldProps, FieldTypeEnum, FieldConfigI } from "./types";

export interface CheckboxFieldConfig extends FieldConfigI {
  type: FieldTypeEnum.CHECKBOX;
}

interface CheckboxFieldProps extends FieldProps {
  value: boolean;
  onChange: (arg0: Record<string, boolean>) => void;
  config: CheckboxFieldConfig;
}

const CheckboxField = ({ value, onChange, config }: CheckboxFieldProps) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          name={config.name}
          checked={!!value}
          onChange={(e) => onChange({ [config.name]: e.target.checked })}
        />
      }
      label={config.label}
    />
  );
};

export default withFormControl(CheckboxField);
