import { FormData, FormErrors } from "./types";
import { FieldConfig } from "./field-config";
import useThunkReducer, {Dispatch, MergeState, GetState} from "hooks/thunk-reducer";


export type FormState = {
  data: FormData;
  errors: FormErrors;
  dirty: boolean;
};

const getInitialState = (initialValues:FormData): FormState => {
  return {
    data: initialValues,
    errors: {},
    dirty: false,
  };
};


const getInitialFieldValue = (f: FieldConfig, initialValues: FormData): any => {
  const sources = f.sources === undefined ? [f.name] : f.sources;
  const isMultiValue = sources.length > 1;

  if (isMultiValue) {
    return sources.reduce((v, source) => {
      v[source] = initialValues ? initialValues[source] : undefined;
      return v;
    }, f.default || {});
  } else {
    const [source] = sources;
    if (initialValues && initialValues[source] !== undefined)
      return initialValues[source];
    return f.default;
  }
};

const applyDefaults = (
  formData: FormData,
  fields: FieldConfig[],
  initialValues: FormData
) : FormData => {
  return fields.reduce((obj, f) => {
    if (formData[f.name] === undefined)
      obj[f.name] = getInitialFieldValue(f, initialValues);
    return obj;
  }, formData);
};


export type FormDispatch = Dispatch<FormState>;

type FormAction<R = void> = (
  mergeState: MergeState<FormState>,
  getState: GetState<FormState>
) => R;


export const useFormReducer = (
  fields: FieldConfig[],
  initialValues: FormData = {},
  validator?: (formData: FormData, fields: FieldConfig[]) => boolean
): [
  state: FormState,
  isValid: boolean,
  dispatch: FormDispatch
] => {

  const [state, dispatch] = useThunkReducer(getInitialState(applyDefaults({}, fields, initialValues)));


  const isValid = allRequiredFieldsPresent(state.data, fields) &&
    (validator ? validator(state.data, fields) : true);

  return [ state, isValid, dispatch ];
};

export const allRequiredFieldsPresent = (
  formData: FormData,
  fields: FieldConfig[]
) : boolean => {
  const missing = fields.some((f) => f.required && !formData[f.name]);
  return !missing;
};


export const resetForm = (values: FormData): FormAction => (mergeState, getState) =>
    mergeState({ 
        data: { ...getState().data, ...values },
        dirty: false,
        errors: {},
    });

export const updateFieldValues = (values: FormData): FormAction => (mergeState, getState) => {
  mergeState({ 
      dirty: true,
      data: { ...getState().data, ...values },
  });
}

export const updateFieldErrors = (errors: FormErrors): FormAction => (mergeState, getState) => {
  mergeState({ 
        errors: errors === null ? {} : { ...getState().errors, ...errors },
  });
}

export const clearErrors = (): FormAction => (mergeState) => {
  mergeState({ 
        errors: {},
  });
}