import { Box, Chip, Stack, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { withFormControl } from "./FormComponent";
import { FieldProps, FieldTypeEnum, FieldConfigI } from "./types";
import { Input } from "@mui/icons-material";

export type TemplateFieldInsertionType = {
  insert: (insertion: string) => void;
}


export interface TemplateFieldConfig extends FieldConfigI {
  type: FieldTypeEnum.TEMPLATE;
  rows: number;
  onOpenDialog: (insertion: TemplateFieldInsertionType) => void;
  markdown: boolean;
}

const spliceTag = (
  tag: string,
  text: string,
  at: number
): [value: string, cursor: number] => {
  const prefix = text.slice(0, at);
  const suffix = text.slice(at);
  const newValue = `${prefix} ${tag} ${suffix}`;
  const offset = at + tag.length + 2;
  return [newValue, offset];
};

interface TemplateFieldProps extends FieldProps {
  value: string;
  onChange: (arg0: Record<string, string>) => void;
  config: TemplateFieldConfig;
}
const TemplateField = ({ value, onChange, config }: TemplateFieldProps) => {

  const [selection, setSelection] = useState<[number | null]>([null]);
  const inputEl = useRef<HTMLInputElement>(null);

  const {name, onOpenDialog} = config;
  const handleContextMenu = useCallback((e: any) => {
    e.preventDefault();

    const insert = (insertion: string | null) => {
      if (insertion && inputEl.current) {
        const currentValue = inputEl.current.value || '';
        const cursorPos = inputEl.current.selectionStart || currentValue.length || 0;
        const [newValue, offset] = spliceTag(insertion, currentValue, cursorPos);
        onChange({ [name]: newValue });
        setSelection([offset]);
      }
    }

    onOpenDialog({ insert });
  }, [name, onOpenDialog, onChange]);


  useEffect(() => {
    const { current } = inputEl;
    if (current) {
      current.addEventListener("contextmenu", handleContextMenu);
      return () =>
        current?.removeEventListener("contextmenu", handleContextMenu);
    }
  }, [config, onChange, handleContextMenu]);

  useEffect(() => {
    if (selection[0] && inputEl.current) {
      inputEl.current.setSelectionRange(selection[0], selection[0], "forward");
      inputEl.current.focus();
    }
  }, [selection]);

  const tray = useMemo(() => <ContextMenuTray markdown={config.markdown} onClick={handleContextMenu}/>, [config.markdown, handleContextMenu])

  return (
    <>
      <TextField
        id={config.name}
        inputRef={inputEl}
        multiline
        name={config.name}
        label={config.label}
        value={value}
        onChange={(e) => onChange({ [config.name]: e.target.value })}
        rows={config.rows || 5}
        placeholder={config.placeholder}
      />
      {tray}
    </>
  );
};

export const ContextMenuTray = ({ onClick, markdown }: { onClick: (arg0: any) => void, markdown: boolean }) => {
  return (
    <Box sx={{ bgcolor: grey[200], mt: 0.5, padding: 0.5, borderWidth: '1px 1px 1px 1px', borderColor: grey[300], borderStyle: 'solid' }}>
      <Stack direction={{xs: "column", md: "row"}} spacing={0.5} justifyContent="space-between" alignItems="center">
        <Chip size="small" icon={<Input />} onClick={onClick} label="Insert Message Variable" />
        <Typography textAlign="center" variant="caption" color="text.disabled">
          {markdown ? (
            <span>MarkDown: * Bulllet | 1. List | <i>*italic*</i> | <b>**bold**</b> | # Heading | [Link&nbsp;Text](URL)</span>
          ) : <span>This field does not support MarkDown formatting</span>}
        </Typography>
      </Stack>
    </Box >
  )
}

export default withFormControl(TemplateField);
