import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { useState } from "react";

const InProgressDialog = ({
  progress,
  onCancel,
}: {
  progress: number;
  onCancel: () => void;
}) => {
  const [cancelled, setCancelled] = useState<boolean>(false);

  const handleCancel = () => {
    setCancelled(true);
    onCancel();
  };
  return (
    <>
      <DialogTitle>Bulk Action In Progress...</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {cancelled
            ? "Cancelling bulk action. Please wait..."
            : "Bulk action is in progress. Click Cancel to abort"}
        </Typography>
        <LinearProgress variant="determinate" value={progress || 0} />
      </DialogContent>
      <DialogActions>
        <Button disabled={cancelled} autoFocus onClick={handleCancel}>
          Cancel
        </Button>
      </DialogActions>
    </>
  );
};

export default InProgressDialog;
