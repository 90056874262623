import { useGetOne} from "hooks/api-hooks";
import {
  AccountSubscriptionType,
  AccountType,
} from "constants/auth.types";
import { AccountPermissionsEnum } from "constants/permissions.enums";
import { Button } from "@mui/material";
import ExternalLinkIcon from "@mui/icons-material/Launch"
import { hasPermission } from "permissions";

export const ManageBilling = ({
  account,
  accountSubscription,
}: {
  account: AccountType | null;
  accountSubscription: AccountSubscriptionType | null;
}) => {
  const [result, get, loading] = useGetOne<{ url: string }>("/stripe/portal");

  if (
    !account ||
    !hasPermission(account, AccountPermissionsEnum.MANAGE_SUBSCRIPTION) ||
    !accountSubscription
  )
    return null;

  const handleClick = () =>
    get({account_id: account.id}).then((data) => {
      if (data) {
        window.location.assign(data.url);
      }
    });

  return (
    <Button startIcon={<ExternalLinkIcon/>} variant="contained" disabled={loading} onClick={handleClick}>
      Manage Billing
    </Button>
  );
};