import { TimePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { withFormControl } from "./FormComponent";
import { FieldConfigI, FieldProps, FieldTypeEnum } from "./types";



export interface TimeFieldConfig extends FieldConfigI {
  type: FieldTypeEnum.TIME;
}

interface TimeFieldProps extends FieldProps {
  value: Dayjs | null;
  placeholder: string;
  onChange: (arg0: Record<string, Dayjs|null>) => void;
  config: TimeFieldConfig;
}

const TimeField = ({ config, value, onChange, error }: TimeFieldProps) => {
  return (
    <TimePicker
      label={config.label}
      value={value ? dayjs(value) : null}
      onChange={(d) => onChange({ [config.name]: d ? d : null })}
      renderInput={(params) => (
        <TextField
          error={error}
          variant="outlined"
          sx={{ width: "100%" }}
          {...params}
        />
      )}
    />
  );
};

export default withFormControl(TimeField);
