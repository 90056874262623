import React, { useState } from 'react';
import { ReactEditor, useSlate, useSlateStatic } from 'slate-react';
import { SvgIconProps } from '@mui/material';
import { Stack, Grid } from '@mui/material'
import BoldIcon from '@mui/icons-material/FormatBold';
import ItalicIcon from '@mui/icons-material/FormatItalic';
import UnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import H1Icon from '@mui/icons-material/LooksOne';
import H2Icon from '@mui/icons-material/LooksTwo';
import CenterIcon from '@mui/icons-material/FormatAlignCenter';
import LeftIcon from '@mui/icons-material/FormatAlignLeft';
import RightIcon from '@mui/icons-material/FormatAlignRight';
import JustifyIcon from '@mui/icons-material/FormatAlignJustify';
import QuoteIcon from '@mui/icons-material/FormatQuote';
import BulletedIcon from '@mui/icons-material/FormatListBulleted';
import NumberedIcon from '@mui/icons-material/FormatListNumbered';
import VariableIcon from '@mui/icons-material/Star';
import LinkifyIcon from '@mui/icons-material/Link';
import CodeIcon from '@mui/icons-material/Code';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import { MarksEnum, ElementTypesEnum, AlignmentTypesEnum, BlockElementTypes } from './types';
import { ComponentType } from 'react';
import { isBlockActive, isMarkActive, toggleMark, toggleBlock, toggleAlignment, insertLink } from './transforms';
import { EditLinkDialog } from './elements/Link';


const BlockButton = ({ format, Icon }: { format: BlockElementTypes, Icon: ComponentType<SvgIconProps> }) => {
  const editor = useSlate()
  return (
    <Icon
      sx={{cursor: 'pointer'}}
      color={isBlockActive(
        editor,
        format,
        'type'
      ) ? 'primary' : 'disabled'}
      onClick={event => {
        event.preventDefault();
        toggleBlock(editor, format);
        ReactEditor.focus(editor);
      }}
    />
  )
}

export const AlignmentButton = ({ format, Icon }: { format: AlignmentTypesEnum, Icon: ComponentType<SvgIconProps> }) => {
  const editor = useSlate()
  return (
    <Icon
      sx={{cursor: 'pointer'}}
      color={isBlockActive(
        editor,
        format,
        'align'
      ) ? 'primary' : 'disabled'}
      onClick={event => {
        event.preventDefault()
        toggleAlignment(editor, format)
        ReactEditor.focus(editor);
      }}
    />
  )
}

const MarkButton = ({ format, Icon }: { format: MarksEnum, Icon: ComponentType<SvgIconProps> }) => {
  const editor = useSlate()
  return (
    <Icon
      sx={{cursor: 'pointer'}}
      color={isMarkActive(editor, format) ? 'primary' : 'disabled'}
      onClick={event => {
        event.preventDefault();
        toggleMark(editor, format);
        ReactEditor.focus(editor);
      }}
    />
  )
}


const InsertNodesButton = ({onInsert}: {onInsert: () => void}) => {
  return (
    <VariableIcon
      sx={{cursor: 'pointer'}}
      color='secondary'
      onClick={onInsert}
    />
  )
}

const LinkifyButton = () => {
  const editor = useSlateStatic()
  const [open, setOpen] = useState<boolean>(false);


  const handleAddLink = (href: string) => {
    if (href) {
      insertLink(editor, href);
      ReactEditor.focus(editor);
    }
    handleClose();
  }

  const handleOpen = (e: React.MouseEvent) => { e.preventDefault(); setOpen(true); };
  const handleClose = () => setOpen(false);

  return (
    <>
      <LinkifyIcon
        sx={{cursor: 'pointer'}}
        color='primary'
        onClick={handleOpen}
      />
      {open && <EditLinkDialog
        initialValue=""
        open={open}
        onClose={handleClose}
        onChange={handleAddLink}
      />}
    </>
  )
}

const DebugButton = () => {
  const editor = useSlateStatic()
  const handleClick = () => {

    let element = document.createElement('a');
    element.href= window.URL.createObjectURL(new Blob([JSON.stringify(editor.children)], {type: 'application/json'}));
    element.download = 'debug.json';
    document.body.appendChild(element);
    element.click();
    window.URL.revokeObjectURL(element.href);
  }
  return (
    <InfoIcon onClick={handleClick} />
  )

}


const Toolbar = ({ onInsert }: { onInsert?: () => void }) => {
  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={5} sm={4} md={2}>
        <Stack direction="row" sx={{ flexBasis: "100%" }}>
          <MarkButton format={MarksEnum.BOLD} Icon={BoldIcon} />
          <MarkButton format={MarksEnum.ITALIC} Icon={ItalicIcon} />
          <MarkButton format={MarksEnum.UNDERLINE} Icon={UnderlinedIcon} />
          <MarkButton format={MarksEnum.CODE} Icon={CodeIcon} />
        </Stack>
      </Grid>
      <Grid item xs={4} sm={4} md={2}>
        <Stack direction="row" sx={{ flexBasis: "100%" }}>
          <BlockButton format={ElementTypesEnum.HEADING_ONE} Icon={H1Icon} />
          <BlockButton format={ElementTypesEnum.HEADING_TWO} Icon={H2Icon} />
          <BlockButton format={ElementTypesEnum.BLOCK_QUOTE} Icon={QuoteIcon} />
        </Stack>
      </Grid>
      <Grid item xs={3} sm={1} md={1}>
        <Stack direction="row" sx={{ flexBasis: "100%" }}>
          <BlockButton format={ElementTypesEnum.NUMBERED} Icon={NumberedIcon} />
          <BlockButton format={ElementTypesEnum.BULLETED} Icon={BulletedIcon} />
        </Stack>
      </Grid>
      <Grid item xs={5} sm={4} md={2}>
        <Stack direction="row" sx={{ flexBasis: "100%" }}>
          <AlignmentButton format={AlignmentTypesEnum.LEFT} Icon={LeftIcon} />
          <AlignmentButton format={AlignmentTypesEnum.CENTER} Icon={CenterIcon} />
          <AlignmentButton format={AlignmentTypesEnum.JUSTIFY} Icon={JustifyIcon} />
          <AlignmentButton format={AlignmentTypesEnum.RIGHT} Icon={RightIcon} />
        </Stack>
      </Grid>
      <Grid item xs={3} sm={3} md={2}>
        <Stack direction="row" sx={{ flexBasis: "100%" }}>
          <LinkifyButton />
          {onInsert && <InsertNodesButton onInsert={onInsert} />}
        </Stack>
      </Grid>
      <Grid item xs={3} sm={2} md={1}>
        <Stack direction="row" sx={{ flexBasis: "100%" }}>
          <DebugButton />
        </Stack>
      </Grid>
    </Grid>
  )
};

export default Toolbar;