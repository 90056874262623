import React from "react";
import { withFormControl } from "./FormComponent";
import { FieldConfigI, FieldProps, FieldTypeEnum } from "./types";
import MyEditor from "components/editor/MyEditor";
import { InputLabel, OutlinedInput} from "@mui/material";
import { Descendant } from "slate";
import { CustomEditor, RichTextDoc, VariableElement } from "components/editor/types";

export interface EditorFieldConfig extends FieldConfigI {
  type: FieldTypeEnum.EDITOR;
  onInsert?: (editor: CustomEditor) => void;
  onResolve?: (editor: CustomEditor, variable: VariableElement) => void;
}

interface EditorFieldProps extends FieldProps {
  value: RichTextDoc;
  placeholder: string;
  onChange: (arg0: Record<string, RichTextDoc>) => void;
  config: EditorFieldConfig;
}

const EditorField = ({ config, onChange, value }: EditorFieldProps) => {
  return (
    <>
      <InputLabel htmlFor={config.name} >{config.label}</InputLabel>
      <OutlinedInput
        id={config.name}
        label={config.label}
        components={{
            Input: EditorInput
        }}
        inputProps={{
          value,
          onInsert: config.onInsert,
          onResolve: config.onResolve,
          onChange: children => onChange({ [config.name]: {...value, children: (children as unknown as Descendant[])}})
        }}
      />
    </>
  )
};

type EditorInputProps = {
  value: RichTextDoc;
  onChange: (arg0: Descendant[]) => void;
  onInsert?: (editor: CustomEditor) => void;
  onResolve?: (editor: CustomEditor, variable: VariableElement) => void;
}

const EditorInput = React.forwardRef<HTMLElement, EditorInputProps>(
  (props, ref: any) => {
    return <MyEditor
      inputRef={ref}
      {...props}
    />
  }
)




export default withFormControl(EditorField);
