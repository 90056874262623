import { TextField } from "@mui/material";
import { withFormControl } from "./FormComponent";
import { FieldProps, FieldTypeEnum, FieldConfigI } from "./types";

export interface TextAreaFieldConfig extends FieldConfigI {
  type: FieldTypeEnum.TEXTAREA;
  rows: number;
  maxLength?: number;
}

interface TextAreaFieldProps extends FieldProps {
  value: string;
  onChange: (arg0: Record<string, string>) => void;
  config: TextAreaFieldConfig;
}
const TextAreaField = ({ value, onChange, config }: TextAreaFieldProps) => {
  return (
    <TextField
      multiline
      name={config.name}
      label={config.label}
      value={value}
      onChange={(e) => onChange({ [config.name]: e.target.value })}
      rows={config.rows || 5}
      inputProps={{required: config.required, maxLength: config.maxLength}}
      placeholder={config.placeholder}
    />
  );
};

export default withFormControl(TextAreaField);
