import { Button, Chip} from "@mui/material";
import useAdvancedSearch from "components/tabular-list/context";
import TabularList from "components/tabular-list/TabularList";
import { AgentType } from "constants/auth.types";
import { EmailTemplateType } from "constants/msg.types";
import { AgentPermissionsEnum } from "constants/permissions.enums";
import { hasPermission } from "../../../../../permissions";
import { useFormCreate } from "hooks/api-hooks";
import { ApiEntityType } from "constants/base.types";
import { useNavigate } from "react-router-dom";
import TextLink from "components/TextLink";
import { LocaleDatetime } from "components/locale";
import { useSearchState } from "components/tabular-list/search-state";
import EmailIcon from "@mui/icons-material/EmailOutlined"
import { PageHeader, PageSection, PageSubtitle, PageTitle } from "components/page/PageHeader";
import { useEmailTemplateBreadcrumbs } from "./EmailTemplateBreadcrumbs";
import { ActionableSectionTitle, Paragraph } from "components/CardFact";

const columns = [
  {
    id: "name",
    numeric: false,
    label: "Name",
    ordering: "name",
    render: ({ row }: { row: EmailTemplateType }) => (
      <TextLink to={`./${row.id}`}>{row.name}</TextLink>
    ),
  },
  {
    id: "default",
    numeric: false,
    label: "Default",
    render: ({ row }: { row: EmailTemplateType }) =>
      row.default ? (
        <Chip label="Default" color="primary" variant="outlined" size="small" />
      ) : (
        <></>
      ),
  },
  {
    id: "updated_at",
    numeric: false,
    label: "Last Updated",
    ordering: "updated_at",
    render: ({ row }: { row: EmailTemplateType }) => (
      <LocaleDatetime value={row.updated_at} />
    ),
  },
];

const NewTemplateButton = ({
  agent,
  onCreate,
}: {
  agent: AgentType;
  onCreate: (arg0: number) => void;
}) => {
  const canCreate = hasPermission(
    agent,
    AgentPermissionsEnum.CREATE_EMAIL_TEMPLATE
  );

  const [create, saving] = useFormCreate<ApiEntityType>("email_template");
  const handleCreate = () => {
    create({ name: "New Template", agent_id: agent.id }).then((emailTemplate) =>
      onCreate(emailTemplate.id)
    );
  };

  return (
    <Button
      variant="contained"
      disabled={!canCreate || saving}
      onClick={handleCreate}
    >
      New Signature
    </Button>
  );
};

const EmailTemplates = ({ agent }: { agent: AgentType }) => {
  const navigate = useNavigate();
  const [searchState, updateSearchState] = useSearchState();
  const [state, dispatch] = useAdvancedSearch("email_template", searchState);
  const trail = useEmailTemplateBreadcrumbs(agent.id);

  return (
    <>
      <PageHeader breadcrumbTrail={trail}>
        <PageTitle
          text="Email Signatures"
          Icon={EmailIcon}
        />
        <PageSubtitle text="Customise your email branding" />
      </PageHeader>

      <PageSection>
        <ActionableSectionTitle
          actions={
            <NewTemplateButton
              agent={agent}
              onCreate={(emailTemplateId) => navigate(`./${emailTemplateId}`)}
            />
          }
        >
          Email Signatures
        </ActionableSectionTitle>
        <Paragraph>
          Email signatures allow you to customise the look and feel of emails sent to contacts.
          You may create multiple signatures. These will be available to choose from them whenever
          you send messages to contacts. You may also specify a signature to use as the default.
        </Paragraph>
      </PageSection>

      <TabularList
        heading="Your Signatures"
        columns={columns}
        store={state}
        dispatch={dispatch}
        searchState={searchState}
        onUpdateSearch={updateSearchState}
      />
    </>
  );
};

export default EmailTemplates;
