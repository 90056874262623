import { Descendant } from "slate"
import { removeSelectedLink, updateLinkHref } from "../transforms"
import { useSlate } from "slate-react"
import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions} from "@mui/material"
import React, { useState } from "react"

const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    style={{
      fontSize: 0
    }}
  >
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)

type LinkElementProps = {
  attributes: any,
  children: Descendant[],
  href: string
}

const EditableLink = ({
  attributes, href, children
}: LinkElementProps) => {

  const editor = useSlate()

  const [open, setOpen] = useState<boolean>(false);
  const handleClick = (event: any) => {
    setOpen(true);
  }
  const handleClose = () => setOpen(false);
  const handleChange = (newHref: string) => {
    if (!newHref) {
      removeSelectedLink(editor, href);
    } else {
      updateLinkHref(editor, href, newHref);
      handleClose();
    }
  }

  return (
    <>
      <a {...attributes} onClick={handleClick} href="#editor" style={{ color: 'LinkText', cursor: 'pointer' }}>
        <InlineChromiumBugfix />
        {children}
        <InlineChromiumBugfix />
      </a>
      <EditLinkDialog
        initialValue={href}
        onChange={handleChange}
        open={open}
        onClose={handleClose}
      />
    </>
  )
};

export const EditLinkDialog = ({
  initialValue,
  onChange,
  open,
  onClose
}: {
  initialValue: string,
  onChange: (arg0: string) => void,
  open: boolean,
  onClose: () => void,
}) => {
  const [href, setHref] = useState<string>(initialValue);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHref(e.target.value);
  }

  const isNewLink = !initialValue;

  const handleSubmit = (e: React.FormEvent) => { e.preventDefault(); onChange(href); }
  const handleReset = (e: React.FormEvent) => { e.preventDefault(); onChange('') }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{isNewLink ? "Add Link" : "Edit Link"}</DialogTitle>
      <form>
        <DialogContent>
          <br />
          <TextField
            placeholder="https://"
            label="Link to (URL):"
            value={href}
            onChange={handleChange}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="reset" variant="outlined" color="error" onClick={handleReset}>{isNewLink ? "Cancel" : "Remove"}</Button>
          <Button type="submit" variant="contained" onClick={handleSubmit}>{isNewLink ? "Link" : "Update"}</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}


const ReadOnlyLink = ({
  attributes,
  children,
  href
}: LinkElementProps) => {

  return (
    <a {...attributes} href={href} target="_blank" rel="noreferrer">
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  )
}

const Link = ({
  readOnly,
  ...props
}:
  LinkElementProps & { readOnly: boolean }
) => {
  const LinkComponent = readOnly ? ReadOnlyLink : EditableLink;
  return <LinkComponent {...props} />
};

export default Link;