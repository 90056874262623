import { ReactEditor } from 'slate-react'
import {
  BaseEditor, Descendant,
} from 'slate'
export type CustomEditor = BaseEditor & ReactEditor



export enum ElementTypesEnum {
  PARAGRAPH = 'p',
  NUMBERED = 'ol',
  BULLETED = 'ul',
  HEADING_ONE = 'h1',
  HEADING_TWO = 'h2',
  BLOCK_QUOTE = 'blockquote',
  LIST_ITEM = 'li',
  VARIABLE_PLACEHOLDER = 'var',
  LINK = 'a'
}

export type BlockElementTypes = 
    ElementTypesEnum.PARAGRAPH | 
    ElementTypesEnum.NUMBERED | 
    ElementTypesEnum.BULLETED | 
    ElementTypesEnum.BLOCK_QUOTE | 
    ElementTypesEnum.HEADING_ONE | 
    ElementTypesEnum.HEADING_TWO |
    ElementTypesEnum.LIST_ITEM;

export enum AlignmentTypesEnum {
  LEFT = 'left',
  RIGHT = 'right',
  JUSTIFY = 'justify',
  CENTER = 'center'
}

export type ElementOrAllignmentType = ElementTypesEnum | AlignmentTypesEnum;

export type VariableElement = {
  type: ElementTypesEnum.VARIABLE_PLACEHOLDER,
  align?: AlignmentTypesEnum, // TODO: this doesn't belong here
  alias: string,
  id: string,
  children: CustomText[]
}

export type BlockElement = {
  type: BlockElementTypes
  align?: AlignmentTypesEnum,
  children: Descendant[]
}

export type LinkElement = {
  type: ElementTypesEnum.LINK,
  align?: AlignmentTypesEnum, // TODO: this doesn't belong here
  href: string,
  children: Descendant[]
}

export type CustomElement = BlockElement | VariableElement | LinkElement;


export enum MarksEnum {
  ITALIC = 'italic',
  BOLD = 'bold',
  UNDERLINE = 'underline',
  CODE = 'code'
}

export type CustomText = {
  text: string;
  italic?: true,
  bold?: true,
  underline?: true,
  code?: true
}


declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor
    Element: CustomElement
    Text: CustomText
  }
}


export type RichTextDoc = {
  etag: string,
  children: Descendant[]
}