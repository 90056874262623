import { AuthClaimsType } from "../auth";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ClickableMenuItem from "./ClickableMenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountsIcon from "@mui/icons-material/AccountBalance";

const UserMenu = ({ auth }: { auth: AuthClaimsType }) => {
  if (auth.email === null) return null;

  return (
    <List>
      <ListItem>
        <ListItemText
          sx={{ wordWrap: "break-word" }}
          primaryTypographyProps={{ variant: "button" }}
          primary={auth.email}
          secondary="Signed in as"
        />
      </ListItem>
      <Divider />
      <ClickableMenuItem
        label="Accounts"
        to={`/user/accounts`}
        icon={<AccountsIcon />}
      />
      <ClickableMenuItem label="Logout" to={`/logout`} icon={<LogoutIcon />} />
    </List>
  );
};

export default UserMenu;
