import Alert from "@mui/material/Alert";
import Save from "@mui/icons-material/Save";
import { Button, Stack} from "@mui/material";
import { FieldConfig } from "./field-config";
import { FormData, FormErrors, FormSubmitHandler } from "./types";
import FormFields from "./FormFields";
import { clearErrors, resetForm, updateFieldErrors, useFormReducer } from "./reducer";
import { ActiveButton } from "components/buttons/LoadingButton";

const Errors = ({ errors }: { errors: string[] }) => {
  if (errors && errors.length > 0) {
    return (
      <Alert style={{ marginTop: "1em" }} severity="error">
        {errors.join(". ")}
      </Alert>
    );
  }
  return null;
};


export const NonFieldErrors = ({ errors }: { errors: FormErrors }) => {

  const { details = [], non_field_errors = [] } = errors;

  if (details.length || non_field_errors.length) {
    return <Errors errors={[...details, ...non_field_errors]} />
  }

  return null;
}

export type FormActionsProps = {
  formData: FormData;
  saving: boolean;
  isValid: boolean;
};

type FormProps = {
  initialValues: FormData;
  fields: FieldConfig[];
  onSubmit: FormSubmitHandler;
  onCancel?: () => void;
  disabled?: boolean;
  saving: boolean;
  submitText?: string;
  submitIcon?: JSX.Element;
  validator?: (formData: FormData, fields: FieldConfig[]) => boolean;
  renderActions?: (props: FormActionsProps) => JSX.Element;
};
export const Form = ({
  initialValues = {},
  fields,
  onSubmit,
  onCancel,
  saving,
  disabled = false,
  submitText = "Save",
  submitIcon = <Save />,
  validator,
  renderActions,
}: FormProps) => {

  const [state, isValid, dispatch] = useFormReducer(fields, initialValues, validator);
  const { data, errors } = state;

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(clearErrors())
    const result = onSubmit(data, (errors: FormErrors) => dispatch(updateFieldErrors(errors)));
    if (result) {
      result.then((formStateUpdates) => {
        formStateUpdates && dispatch(resetForm(formStateUpdates));
      }).catch(() => null);
    }
  };


  return (
    <form onSubmit={handleSubmit}>
      <FormFields
        data={data}
        errors={errors}
        dispatch={dispatch}
        fields={fields}
      />

      <NonFieldErrors errors={errors} />

      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        {onCancel && <Button
          variant="outlined"
          color="secondary"
          size="large"
          type="button"
          disabled={saving}
          onClick={onCancel}
          sx={{ mr: 2 }}
        >Cancel</Button>}
        {renderActions &&
          renderActions({
            saving,
            isValid: !!isValid,
            formData: data,
          })}

        <ActiveButton
          buttonProps={{
            variant: "contained",
            disabled: !isValid || saving || disabled,
            type: "submit"
          }}
          submitIcon={submitIcon}
          active={saving}
          activeText="Saving..."
        >{submitText}</ActiveButton>
      </Stack>
    </form>
  );
};
