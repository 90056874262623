import { TextField } from "@mui/material";
import { ValueFieldProps } from "./types";

const StringValueField = ({
  onChange,
  value,
  name,
  label,
}: {
  onChange: (arg0: string) => void;
  value: string;
  label: string;
  name: string;
}) => {
  return (
    <TextField
      color="secondary"
      variant="outlined"
      label={label}
      name={name}
      value={value}
      autoComplete="off"
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export const AddressValueField = ({
  value,
  onChange,
  name,
}: ValueFieldProps) => {
  const { string_value = null } = value;

  return (
    <StringValueField
      label="Address"
      name={name}
      value={string_value || ""}
      onChange={(newValue) => onChange({ string_value: newValue })}
    />
  );
};

export const MLSListingValueField = ({
  value,
  onChange,
  name,
}: ValueFieldProps) => {
  const { string_value = null } = value;

  return (
    <StringValueField
      label="Listing No"
      name={name}
      value={string_value || ""}
      onChange={(newValue) => onChange({ string_value: newValue })}
    />
  );
};

export const TextValueField = ({ value, onChange, name }: ValueFieldProps) => {
  const { string_value = null } = value;

  return (
    <StringValueField
      label="Custom Text"
      name={name}
      value={string_value || ""}
      onChange={(newValue) => onChange({ string_value: newValue })}
    />
  );
};
