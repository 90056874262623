
import { Routes, Route } from "react-router-dom";
import { AgentType } from "constants/auth.types";
import PageNotFound from "components/errors/PageNotFound";
import AgentMemberRoles from "./AgentMemberRoles";
import CreateAgentMemberRole from "./CreateAgentMemberRole";
import EmailTemplateRoutes from "./email-template/EmailTemplateRoutes";
import EmailTemplates from "./email-template/EmailTemplates";

const SettingsRoutes = ({ agent }: { agent: AgentType }) => {
  return (
    <Routes>
      <Route
        path="access"
        element={<AgentMemberRoles agent={agent} />}
      />
      <Route
        path="access/invite"
        element={<CreateAgentMemberRole agent={agent} />}
      />
      <Route path="email-signatures" element={<EmailTemplates agent={agent}/>} />
      <Route path="email-signatures/:emailTemplateId/*" element={<EmailTemplateRoutes agent={agent} />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default SettingsRoutes;
