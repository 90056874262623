import { LinearProgress } from "@mui/material";
import { SectionTitle } from "components/CardFact";
import { Form } from "components/form/Form";
import { FieldConfig } from "components/form/field-config";
import { FieldTypeEnum, FormSubmitHandler } from "components/form/types";
import { AccountMemberType } from "constants/auth.types";
import { ApiEntityType } from "constants/base.types";
import { useFormUpdate, useGetOne } from "hooks/api-hooks";
import { useEffect } from "react";

const fields: FieldConfig[] = [
  {
    name: "first_name",
    type: FieldTypeEnum.INPUT,
    label: "First Name",
    placeholder: "",
    required: false,
  },
  {
    name: "last_name",
    type: FieldTypeEnum.INPUT,
    label: "Last Name",
    placeholder: "",
    required: true,
  },
];


const EditProfileForm = ({ accountMember }: { accountMember: AccountMemberType }) => {


  const [save, saving] = useFormUpdate<ApiEntityType>(`account_member/${accountMember.id}`);

  const handleSubmit: FormSubmitHandler = (params, setFormErrors) => {
    save(params).catch(setFormErrors)
  }

  const { first_name = '', last_name = '' } = accountMember;

  return (
    <Form
      fields={fields}
      onSubmit={handleSubmit}
      saving={saving}
      submitText="Update"
      initialValues={{ first_name, last_name }}
    />
  )
}


const EditProfile = () => {
  const [me, getMe, loading] = useGetOne<AccountMemberType>(`account_member/me`)
  useEffect(() => {
    getMe();
  }, [getMe])

  return <>
    <SectionTitle>
      Profile Info
    </SectionTitle>
    {loading && <LinearProgress />}
    {me ? <EditProfileForm accountMember={me} /> : <LinearProgress />}
  </>
}

export default EditProfile;